<template>
  <section>
    <div class="row mx-0 mt-3">
      <div class='row m-0' style='width: 100%;'>
         <div class="col-6">
           <div class='row m-0' style='width: 100%;'>
              <div class="col-3 skeleton-box">
              </div>
              <div class="col-9">
              </div>
            </div>
         </div>
         <div class="col-6">
         </div>
       </div>
    </div>
    <div class="row mx-0 mb-3 mt-3">
      <div class='row m-0' style='width: 100%;'>
         <div class="col-7">
         </div>
         <div class="col-5">
           <div class='row m-0' style='width: 100%;'>
              <div class="col-6 skeleton-box">
              </div>
              <div class="col-6  ">
              </div>
            </div>
         </div>
       </div>
    </div>
      <div class="" style='overflow:hidden;'>
        <div class='row m-0' style='width: 100%;'>
            <div class="col-12 col-md-7 p-0 m-0 ">
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">

                    </div>
                    <div class='col-3 p-0 m-0' >
                        <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                    </div>
                    <div class='col-12 m-0 skeleton-box' style='width: 100%; height: 200px'>
                    </div>
                </div>
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">
                      <span class="skeleton-box w-100" style="height: 30px"></span>
                    </div>
                    <div class='col-3 p-0 m-0' >
                    </div>
                </div>
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">

                    </div>
                    <div class='col-3 p-0 m-0' >
                        <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                    </div>
                    <div class='col-12 m-0 skeleton-box' style='width: 100%; height: 200px'>
                    </div>
                </div>
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">
                      <span class="skeleton-box w-100" style="height: 30px"></span>
                    </div>
                    <div class='col-3 p-0 m-0' >
                    </div>
                </div>
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">
                      <span class="skeleton-box w-100" style="height: 30px"></span>
                    </div>
                    <div class='col-3 p-0 m-0' >
                    </div>
                </div>
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">
                      <span class="skeleton-box w-100" style="height: 30px"></span>
                    </div>
                    <div class='col-3 p-0 m-0' >
                    </div>
                </div>
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">

                    </div>
                    <div class='col-3 p-0 m-0' >
                        <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                    </div>
                    <div class='col-12 m-0 skeleton-box' style='width: 100%; height: 200px'>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 p-0 m-0">
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">

                    </div>
                    <div class='col-3 p-0 m-0' >
                        <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                    </div>
                    <div class='col-12 m-0 skeleton-box' style='width: 100%; height: 200px'>
                    </div>
                </div>
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">

                    </div>
                    <div class='col-3 p-0 m-0' >
                        <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                    </div>
                    <div class='col-12 m-0 skeleton-box' style='width: 100%; height: 200px'>
                    </div>
                </div>
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">

                    </div>
                    <div class='col-3 p-0 m-0' >
                        <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                    </div>
                    <div class='col-12 m-0 skeleton-box' style='width: 100%; height: 200px'>
                    </div>
                </div>
                <div class="row m-1 shadowDiv mb-3">
                    <div class="col-9 p-0 m-0">

                    </div>
                    <div class='col-3 p-0 m-0' >
                        <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                    </div>
                    <div class='col-12 m-0 skeleton-box' style='width: 100%; height: 200px'>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'CheckOutMainSkeleton'
}
</script>

<style scoped>
    .shadowDiv {
        margin: 0px;
        background-color: white;
        padding: 10px;
        border-radius: 7px;
        height: auto;
    }
    .shadowDiv:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
</style>