<template>
    <!-- <ImageData > -->
    <section class="px-2 py-0">
      <!-- <div v-for="(e,index) in listData" class="row" :key="index"> -->
      <div class="row" v-show="!$store.getters['auth/getISTB']">
        <div class="col-12 cardcart">
          <div class="row">
            <div class="col-12">
              <img src="@/assets/img/the1s.png" class="mr-1" style="height:35px;" >
              <span class="font-kitti-medium" >หมายเลข The 1 ใบเสนอราคา : {{ T1C }}
                <!-- <span id="theonepoint" class="font-kitti-medium" >{{ getQTCUSTINFO.T1C }}</span> -->
              </span> 
            </div>
            <div class="col-12 mb-2 mt-2" v-if="QTCHANGET1">
              <span>เบอร์โทรศัพท์มือถือ<input  v-model="MOBILE" type="text" class="form-control" name="MOBILEE" ref="MOBILEE" placeholder="Mobile" maxlength="10"></span>
              <!-- <input v-model="T1C" class="w-100" placeholder="กรุณากรอกหมายเลขโทรศัพท์" type="text" maxlength="16" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"> -->
              <a :class="`font-color-${qtBu} pointer`" style="font-size: 1em; text-decoration: underline;" @click="getTheOneNumber()" target="_blank">เช็คหมายเลข The 1</a>
            </div>
            <!-- <div class="col-12 mb-2">
              <button :class="`btn-visible-twd w-100 bg-${qtBu}`"  @click="showList"> <strong style="font-family: 'kittithadamedium65regular';">ค้นหาหมายเลข The 1</strong></button>
            </div> -->
          </div>
        </div>
      </div>
      <div v-for="(e,index) in listData" class="row" :key="index">
        <div class="col-12 cardcart" >
        
          <div class="row">
            <div class="col-12 col-md-3  pr-0">
                <ImageBySKU class="w-100 img-border" :sku="e.SKC" />
            </div>
            <div class="col-12 col-md-9">
                
                <p class="nameoneline font-900 font-weight-bold  m-0">{{e.PRN}}</p>
                <span class="font-gray font-kitti-thin">รหัสสินค้า: {{e.SKC}}</span>
                
                <span class="font-gray font-kitti-thin text-right float-right"> ราคา: {{e.UPC}} บาท</span>
                <br v-show="!$store.getters['auth/getISTB']" v-if="(getQTPROFILE.indexOf('0077') > -1 ) && e.SHOWCOST=='Y' && e.PRPRICE == 0">
                <span v-show="!$store.getters['auth/getISTB']" v-if="(getQTPROFILE.indexOf('0077') > -1 ) && e.SHOWCOST=='Y' && e.PRPRICE == 0" class="font-gray font-weight-bold float-right"> ราคาทุนไม่รวมvat: {{e.COST}} บาท</span>
                <br>
                
                <span class="font-weight-bold font-light-gray">{{e.QNT}} {{e.UNIT}}</span>
                
                <span class="font-weight-bold text-right  float-right"> รวม: {{priceBath(e.AMT)}} บาท</span>
                
                <span v-show="!$store.getters['auth/getISTB']" class="font-weight-bold" v-if="e.PORQ == 'P'"><br>PO : {{e.VENST}}</span>
                <span v-show="!$store.getters['auth/getISTB']" class="font-weight-bold" v-if="e.PORQ == 'R'">RQ : {{e.VENST}}</span>
                
                <span v-show="!$store.getters['auth/getISTB']" class="font-weight-bold"><br>รหัสพนักงาน : {{e.SLPRSN}}</span>
            </div>
          </div>
          
        </div>
      </div>
      <div  v-if="isLoading" class="row">
          <div v-for="index in 4" :key="index" class="col-12 fillpag " style="padding: 5px 5px;">
            <div class="shadowDiv" style='overflow:hidden;'>
              <div class="row">
                <div class="col-12 col-md-3">
                    <span class="skeleton-box w-100" style="height:80px;"></span>
                </div>
                <div class="col-12 col-md-9">
                  <h3 class="blog-post__headline">
                    <span class="skeleton-box" style="width:55%;"></span>
                  </h3>
                  <p class="m-0">
                    <span class="skeleton-box" style="width:80%;"></span>
                    <span class="skeleton-box" style="width:90%;"></span>
                    <span class="skeleton-box" style="width:83%;"></span>
                  </p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ">
          <div class="row titlemoney">
            <p class="h5 font-kitti-bold m-0">รายละเอียดการชำระเงิน</p>
          </div>
        </div>
       
        <div class="col-12 cardcart">
          
            <table  class="table w-100 m-0">
              <!-- <tr v-if="getQTPROFILE.indexOf('0077') > -1 && sumCOSTData && !$store.getters['auth/getISTB']" class="info hidecostvfix">
                <td class="checkout-info-key" colspan="4" align="center">ราคาทุนไม่รวมvat</td>
                <td class="numeric numericPad checkout-info-value">{{priceBath(sumCOSTData)}}</td>
              </tr> -->
              <!-- <tr v-if="getQTPROFILE.indexOf('0077') > -1 && SumGPData && !$store.getters['auth/getISTB']"  class="info hidecostvfix">
                <td class="checkout-info-key" colspan="4" align="center">GP</td>
                <td class="checkout-info-value">{{SumGPData}}%</td>
              </tr> -->
              <tr class="info">
                <td class="checkout-info-key" colspan="4" align="center">ราคารวม</td>
                <td class="numeric numericPad checkout-info-value">{{priceBath(sumTOTData)}}</td>
              </tr>
              <tr class="active" v-if="!$store.getters['auth/getISTB']">
                <td class="checkout-info-key" colspan="4" align="center">ส่วนลดตามรายการ</td>
                <td  class="numeric checkout-info-value">{{priceBath(sumDisData)}}</td>
              </tr>
              <tr class="active" v-if="!$store.getters['auth/getISTB']">
                <td class="checkout-info-key" colspan="4" align="center">ส่วนลด MKT <span v-if="getNameMKT" >{{getNameMKT}}</span></td>
                <td  class="numeric checkout-info-value">{{priceBath(getDisMKT)}}</td>
              </tr>
              <tr class="active" v-if="!$store.getters['auth/getISTB']">
                <td class="checkout-info-key" colspan="4" align="center">ส่วนลดพนักงาน</td>
                <td  class="numeric checkout-info-value">{{priceBath(DisStaff)}}</td>
              </tr>
              <tr class="success">
                <td colspan="4" align="center" class='checkout-success-key'>
                  <p class='checkout-success-card'>ราคารวมสุทธิ</p>
                </td>
                <td class='checkout-success-value'>
                  <p  class="numeric checkout-success-card">{{priceBath(sumNET)}}</p>
                </td>
              </tr>
            </table>
        </div>
      </div>
      <PlppCheckOut v-if="!$store.getters['auth/getISTB']" ref="PlppCheckOutRef" @sumDisReturn="sumDisReturn" />
      
    </section>

</template>


<script>
import * as serviceAPI  from '../../services/API.service';
import * as serviceMain  from '../../services/main.service';
import PlppCheckOut from '@/components/PlppCheckOut.vue';
import ImageBySKU from '@/components/ImageBySKU.vue';
export default {
  name: 'Orderinfo',
  components: {
    ImageBySKU,
    PlppCheckOut,
  },
  data: function () {
    return {
        listData:[], 
        listPORQ:[],  
        isLoading: true,
        sumTOTData:0,
        sumDisData:0,
        sumCOSTData:0,
        SumGPData:0,
        DisStaff:0,
        DisMKT:0,
        T1C:'',
        MOBILE:'',
      }
  },
  mounted() {
    this.showList();
    this.T1C = this.getQTCUSTINFO.T1C || ''
  },
  methods: {
    async showList(M){
      try {
        let JsnStaff = ''
        if(this.getQTCUSTINFO.ISSTAFF == 'Y'){
          JsnStaff = `{"employeeBUShortCode": "${this.getQTCUSTINFO.EMPLOYEEBUSHORTCODE}", "employeeID": "${this.getQTCUSTINFO.EMPLOYEEID}", "isStaff": "${this.getQTCUSTINFO.ISSTAFF}"}`
        }
        let data={
          EMAIL:this.getQTCUSTINFO.EMAIL , 
          MBL:this.getQTCUSTINFO.MOBILE , 
          REF:this.getRef,
          memberCode: this.getDisMemberCode || '0',
          JsnStaff: JsnStaff,
          T1C: this.T1C
        }
        // console.log(data);
        let getAPI = await serviceAPI.call_API('post',`checkout/orderinfo`,data,'auth');
        console.log('OINFO');
        this.listData = getAPI.data.dbitems;
        this.$emit('setOrderItems', this.listData)
        this.$store.dispatch('service/setCallOrderInfo')
        this.isLoading = false
        this.sumTOT()
        this.sumGP()
        if(this.getIs10101 || this.getDisMemberCode){
          // if(M == '101') 
          this.$store.dispatch('service/setReloadIs10101')
          // this.$store.dispatch('service/setReloadMem')
        }
        if(this.getDisMKT){
          this.$store.dispatch('service/setReloadMKT')
        }
        this.$store.dispatch('service/setReloadPlpp')
        this.getDiscMKTStaff()
        // else{
        //   this.$store.dispatch('service/setReloadPlpp')
        // }
        // this.calPLPP()
        this.T1C = this.listData.T1CQT || ''
        if(M == 'T1C'){
          serviceMain.showSuccessAlertWithTime(this,`บันทึกหมายเลข The 1 (${this.T1C}) เรียบร้อยแล้ว`,3000)
        }
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async getDiscMKTStaff() {
        try {
            let data = {
                REF:this.getRef,
                STCODE:this.getStore ,
            }
            let getAPI = await serviceAPI.call_API('post', `checkout/getDiscMKTStaffResult`, data, 'auth');
            // console.log(getAPI);
            this.DisStaff = getAPI.data.dbitems[0].Staffdisc;
            this.DisMKT = getAPI.data.dbitems[0].MKTdisc;
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
    },
    async getTheOneNumber(){
      try {
        if(this.MOBILE.length != 10){
          return
        }
        let data = {
          MOBILE: this.MOBILE
        };
        let getAPI = await serviceAPI.call_API('post', 'user/findTheOne', data,'auth');
        let getData = getAPI.data.dbitems[0];
        if(!getData ||getData.T1CNumber=='NOOK'){
          throw new Error('ไม่พบหมายเลข The 1')
        }
        this.$swal.fire({
          html:
            `<h2>T1C : ${getData.T1CNumber}</h2><br>
            <h2>ชื่อผู้ใช้ : ${getData.MemberFirstNameThai} ${getData.MemberLastNameThai}</h2>`,
          showCancelButton: false,
          showDenyButton: true,
          confirmButtonColor: this.getBuColor,
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: "ตกลง",
          denyButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.value) {
            this.T1C = getData.T1CNumber;
            setTimeout(() => this.showList('T1C'), 200);
          }
        });
      } catch (error) {
        serviceMain.showErrorAlert(this, error.message)
      }
    },
    async sumCOST(){
      try {
        let getsumCOST = 0
        for(let e of this.listData){
          if(e.SHOWCOST=='Y'){
            getsumCOST = getsumCOST + e.COST
          }
          
        }
        this.sumCOSTData = getsumCOST;
      
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async sumTOT(){
      try {
        let getSumTOT = 0
        for(let e of this.listData){
          getSumTOT = getSumTOT + e.AMT
        }
        this.sumTOTData = getSumTOT;
      
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async sumGP(){
      try {
        let getsumCOST = 0
        for(let e of this.listData){
          if(e.SHOWCOST=='Y'){
            getsumCOST = getsumCOST + e.COST
          }
        }
        let getSumTOT = 0
        for(let e of this.listData){
          getSumTOT = getSumTOT + e.AMT
        }
        this.SumGPData = (1-(getsumCOST*1.07/getSumTOT)).toFixed(2);
      
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async sumDisReturn(val){
      this.sumDisData = val
    },
   
  
    priceBath(para_price) {
      return para_price.toLocaleString(undefined, {minimumFractionDigits: 2})
    },
    calculateDeliveryFlee(){
      let deliFee = this.listData.find(itm => itm.SKC == '60261303')
      this.DeliveryFee = deliFee ? deliFee.QNT : 0;
    }
  },
  computed: {
    getRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    getStore:function(){
      return this.$store.getters['service/getQTDFSTORE']
    },
    getQTCUSTINFO:function(){
      return this.$store.getters['customer/getQTCUSTINFO']
    },
    getQTPROFILE:function(){
      return this.$store.getters['auth/getQTPROFILE']
    },
    getDisMKT:function(){
      return this.$store.getters['service/getDisMKT']
    },
    getNameMKT:function(){
      return this.$store.getters['service/getNameMKT']
    },
    sumNET:function(){
      return this.sumTOTData - (this.sumDisData + this.getDisMKT + this.DisStaff)   
    },
    getReloadOrderInfo:function(){
      return this.$store.getters['service/getReloadOrderInfo']
    },
    getReloadOrderInfoF101:function(){
      return this.$store.getters['service/getReloadOrderInfoF101']
    },
    getIs10101:function(){
      return this.$store.getters['service/getIs10101']
    },
    getQTDLVTRTYPE:function(){
      return this.$store.getters['customer/getQTDLVTRTYPE'] || ''
    },
    getPickData:function(){
      return this.$store.getters['service/getQTCHKP']
    },
    getReloadFlee: function () {
      return this.$store.getters['service/getReloadFlee']
    },
    DeliveryFee: {
      get () {
        return this.$store.getters['service/getDeliveryFee']
      }, 
      set (value) {
        this.$store.dispatch('service/setDeliveryFee', value);
      }
    },
    ChangeAddress:function(){
      return this.$store.getters['service/getChangeAddress']
    },
    getDisMemberCode:function(){
      return this.$store.getters['service/getDisMemberCode']
    },
    qtBu: function (){
      return this.$store.getters['customer/getQTBU']
    },
    QTCHANGET1: function (){
      return this.$store.getters['auth/getQTCHANGET1']
    },
  },
  watch:{
    getStore:function(){
      this.showList()
    },
    getReloadOrderInfo:function(){
      this.showList()
    },
    getReloadOrderInfoF101:function(){
      this.showList('101')
    },
    // getQTDLVTRTYPE:function(){
    //   setTimeout(() => this.showList(), 500);
    // },
    getPickData:function(){
      // setTimeout(() => this.showList(), 500);
    },
    getReloadFlee: function() {
      this.calculateDeliveryFlee();
    },
    ChangeAddress:function(){
      setTimeout(() => this.showList(), 500);
    },

  }

}

</script>

<style scoped>
.nameoneline{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.img-border{
  border:2px rgb(235, 235, 235) solid;
  border-radius: 8px;
  padding: 2px
}
.cardcart {
  position: relative;
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 15px;
  margin: 0px 0px 10px 0px;
}
.shadowDiv {
  margin: 0px;
  background-color: white;
  padding: 10px;
  border-radius: 7px;
  height: auto;
}
.font-weight-bold{
  font-family: 'kittithadabold75regular';
}
.minus {
  white-space: nowrap;
  width: 20px;
  height: 24px;
  border: 1px gray solid;
  border-radius: 4px 0px 0px 4px;
  padding: 2px 4px 1px 4px;
  display: inline;
  vertical-align: middle;
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  z-index: 998;
}

.plus {
  width: 20px;
  height: 24px;
  border: 1px gray solid;
  border-radius: 0px 4px 4px 0px;
  padding: 2px 4px 1px 4px;
  display: inline;
  vertical-align: middle;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
}





.input-qty {
  height: 24px;
  font-weight: bold;
  width: 50px;
  text-align: center;
  font-size: 20px;
  border: 1px gray solid;
  
  vertical-align: middle;
  margin: 0;
}
.inputEdit{
  min-width: 90px;
}
.btn-qty{
  border: 1px gray solid;

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.close-circle{
  font-size: 15px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-align: center;
  letter-spacing: .025em;
  border-radius: 50%;
  background-color: #55246c;
  width: 26px;
  height: 26px;
  line-height: 26px;
  position: absolute;
  transition: all .2s ease-out;
  top: -10px;
  left: -10px;
  z-index: 2;
}

/* css priceloading */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;
}
.lds-ellipsis span {
  position: absolute;
  top: 12px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #55246c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis span:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis span:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* End css priceloading */


/* ส่วนลด start*/

.checkout-info-key {
  border: none;
  background-color: white;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.checkout-info-value {
    border: none; 
    background-color: white; 
    text-align: right;
    padding-right : 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.checkout-success-key {
    background-color: white;
    border: none;
    padding: 0px;
}

.checkout-success-value{
    border: none;
    background-color: white;
    padding: 0px;
    text-align: right;
}

.checkout-success-card{
    font-family: 'kittithadabold75regular';
    background-color: rgb(184, 184, 184);
}

.hiddenP{
  display:none;
}
/* ส่วนลด end*/
</style>



// ALC: 0
// ALOCATE: 0
// AMT: "96.00"
// ATBCODE: "  "
// AUTOPLPP: 0
// COST: "82.80"
// CTLID: 8894900
// CanEditUPC: "N"
// EDT: ""
// FREENOSTOCK: false
// OFflag: 0
// PCD: "0000041781616"
// PREORDER: 0
// PRN: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
// PRSTATUS: "A"
// QNT: 1
// QNTAVAIL: 79
// RECID: 4317
// REF: "QT2105000014"
// REM: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// SEQ: 1
// SERVICEFLAG: false
// SERVICEGROUP: " "
// SERVICENAME: ""
// SKCODE: 60180854
// STAT: "ยังไม่ยืนยัน\t"
// STATUS: " "
// STCODE: 920
// STOCKAVAIL: 79
// TOT: 96
// TSPGROUP: 2
// TTM: "2021-05-17T09:44:20.983Z"
// UPC: "96.00"
// WEIGHT: 1.25
// pckloc: 2
// remark: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// slugname: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
