<template>
  <section>
    <router-link to="/CartGrouping" :class='`btngroupitems bg-${qtBu}`'>จัดกลุ่มสินค้า</router-link>
  </section>
</template>

<script>
export default {
    name: "ButtonCartGrouping",
    computed: {
        qtBu: function (){
            return this.$store.getters['customer/getQTBU']
        }
    }
}
</script>

<style scoped>
    .btngroupitems{
        background-color: #55246c; 
        border: none;
        float:right;
        color: white;
        padding: 2px 12px;
        text-align: center;
        text-decoration: none;
        font-size: 1rem;
        cursor: pointer;
        border-radius:4px;
    }
</style>