<template>
  

  <div class="row">
    <!-- <div class="col-12 p-0">
      <form>
        <a :class="`btnpayment hoverable ${isUnusePickupNow ? 'disabled' : ''}`" style="  background-color: white; margin-top:30px; padding:0px 10px;  height:37px;">
          <label class="containerq" style=" font-size:26px;"> รับของทันที <span class="stctxtshow" style=" font-size:26px;"></span>
            <input  type="radio" class="varckradiobtn" v-model="pickDlvType" value="PickupNow">
            <span :class="`checkmark checkmark-${qtBu} checkmark-color-${qtBu}`" ></span>
          </label>
        </a>
        <a :class="`btnpayment hoverable ${isUnusePickup ? 'disabled' : ''}`" style="  background-color: white; padding:0px 10px;  height:37px;">
          <label class="containerq" style=" font-size:26px;"> รับที่ ไทวัสดุ สาขา {{getQTSTORENAME}}<span class="stctxtshow" style=" font-size:26px;"></span>
            <input  type="radio" class="varckradiobtn"  v-model="pickDlvType" value="Pickup">
            <span :class="`checkmark checkmark-${qtBu} checkmark-color-${qtBu}`" ></span>
          </label>
        </a>
        <a class='btnpayment hoverable' style=" background-color: white; padding:0px 10px; height:37px;">
          <label class="containerq" style=" font-size:26px;"> ส่งถึงบ้าน
            <input  type="radio" class="varckradiobtn"  v-model="pickDlvType" value="Delivery">
            <span :class="`checkmark checkmark-${qtBu} checkmark-color-${qtBu}`" ></span>
          </label>
        </a>
      </form>
    </div> -->

  </div>


</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';

export default {
  name: 'SelectPick',
  components: {
  },
  data: function () {
    return {
      listData:[],   
      storeUnusePickupNow: ['UL', 'UC'],
      storeUnusePickup: [],
      isUnusePickupNow: false,
      isUnusePickup: false,
      pickDlvType: ''
    }
  },
  mounted() {
    this.checkDisabledPickupAndPickupNow();
    this.setStartPick()
  },
  props: [],
  methods: {
    checkDisabledPickupAndPickupNow() {
      this.isUnusePickupNow = this.storeUnusePickupNow.includes(this.qtSalesource) || this.storeUnusePickupNow.includes(this.qtDfStore);
      this.isUnusePickup = this.storeUnusePickup.includes(this.qtDfStore);
    },
    setStartPick(){
      if((!this.isUnusePickup && this.pickData == 'Pickup') && (!this.isUnusePickupNow && this.pickData == "PickupNow")) {
        this.pickDlvType = this.pickData
        this.EditDlvZero()
        return
      } 
      
      this.pickDlvType = 'Delivery';
    },
    async EditDlvZero(){
      try {
            let data={
                REF: this.getRef, 
                TRAN_JSON:{
                  amount: (Math.round(0 * 100) / 100).toFixed(2),
                }
            }
            let getAPI = await serviceAPI.call_API('post',`checkout/SaveTransport`,data,'auth');
            this.$store.dispatch('service/setReloadOrderInfo')
            this.getQTPDLV = ''
         
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
  },
  computed: {
    getRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    getQTSTORENAME:function(){
      return this.$store.getters['service/getQTSTORENAME']
    },
    qtDfStore: function(){
      return this.$store.getters['service/getQTDFSTORE']
    },
    qtSalesource: function(){
      return this.$store.getters['auth/getQTSALESOURCE']
    },
    qtBu: function() {
      return this.$store.getters['customer/getQTBU']
    },
    pickData: {
      get () {
        this.$store.getters['service/getQTCHKP']
      },
      set (value) {
        this.$store.dispatch('service/setQTCHKP',value)
      }
    },
    QTDLVTRTYPE: {
      get () {
        return this.$store.getters['customer/getQTDLVTRTYPE'] || ''
      },
      set (value) {
        this.$store.dispatch('customer/setQTDLVTRTYPE',value)
      }
    },
    getQTPDLV: {
      get () {
        return this.$store.getters['service/getQTPDLV']
      },
      set (value) {
        this.$store.dispatch('service/setQTPDLV',value)
      }
    },
  },
  watch: {
    pickDlvType: function () {
      this.pickData = this.pickDlvType;
      if(['Pickup', "PickupNow"].includes(this.pickDlvType)) {
        this.EditDlvZero()
        this.QTDLVTRTYPE = ''
      }
    }
  }
}

</script>

<style scoped lang="scss">
$color-PWB: #55246c;
$color-bnb: #006e63;
$color-at1: #3866af;
$listBu: 'PWB' $color-PWB, 'bnb' $color-bnb, 'at1' $color-at1;

.btnpayment {
  background-color: rgb(241, 241, 241);
  width: 100%;
  border: none;
  color: white;
  padding: 2px 12px;
  text-align: center;
  text-decoration: none;
  display: block;
  color: black;
  margin: 0px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'kittithadamedium65regular';
  text-align: left;
}

.btnpayment:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.containerq {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.containerq input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


.checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: #999999;
}


.containerq:hover input~.checkmark {
    background-color: #ccc;
}


.containerq input:checked~.checkmark {
    background-color: white;
    border-style: solid;
    border-color: #55246c;
}


.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


.containerq input:checked~.checkmark:after {
    display: block;
}

a.disabled {
        pointer-events: none;
        color: #ccc;
    }

@mixin checkmark($color) {
  &:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $color;  
  }
}

@each $buItems in $listBu {
  .checkmark-#{nth($buItems, 1)} {
    @include checkmark(nth($buItems, 2))
  }
}
</style>
