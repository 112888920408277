<template>
<section>
  <div class="container">
    <CompareProd />
  </div>
</section>
</template>

<script>
  import CompareProd from '@/components/CompareProd.vue'
  export default {
    name: 'ProductDetail',
    components: {
      CompareProd,
    },
    data: function () {
      return {

      }
    },
    props: {
      slugName: {
        type: String,
        required: true
      }
    },
    mounted() {
      document.title = `${process.env.VUE_APP_TITLE} - Product Detail`
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
      document.head.appendChild(recaptchaScript)
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CRY3HG283C');
    },
    methods: {
    }
  }
</script>