<template>
    <section class='py-2'>
        <div class="row">
            <div class="col-12 cardWhite">
                <h3 style="font-family: 'kittithadabold75regular';">มารับสินค้าที่สาขา:</h3>
                <div
                    style="border-radius: 7px; background-color: white; height: auto; padding: 20px 20px; margin-bottom:20px;">
                    <p style="font-family: 'kittithadabold75regular';">หลักฐานการมารับสินค้าที่สาขา</p>
                    <p style="">- หลักฐานหน้าคำสั่งซื้อของลูกค้า</p>
                    <p style="">- บัตรประชาชน (ถ้าชื่อไม่ตรงกันลูกค้าจะต้องนำสำเนาหรือรูปถ่ายบัตรมารับสินค้า)</p>
                    <p style="">- กรณีเป็นบริษัท ต้องเอาใบมอบอำนาจการรับสินค้า หรือ ภพ20. มารับสินค้า</p>
                    <hr>
                    <p style="font-family: 'kittithadabold75regular';">เวลาในการรับสินค้า</p>
                    <p style="">- ลูกค้าสามารถรับสินค้าได้ที่ไทวัสดุสาขา <span id="policystore"></span> ทุกวัน
                        ตั้งแต่เวลา 09.00 - 19.00 น. </p>
                    <p style="">- ลูกค้าสามารถมารับสินค้าได้หลังจากชำระเงินแล้ว 2 ชั่วโมง</p>
                    <p style="">- กรณีลูกค้าชำระเงินหลัง 17.00 น. ลูกค้าจะสามารถรับสินค้าได้ในวันถัดไป</p>
                </div>

                
                <div v-show="getQTBOOKING && !$store.getters['auth/getISTB'] && false"><SelectStoreDLV ref="SelectStoreRef" :pick="'Y'" :trfrom ="StoreCode" @selectedStore="selectedStore" /></div>
                <div><PickupStore ref="PickupStore" :trfrom ="StoreCode" @selectedStore="selectedStore"/></div>
                <div class="form-group row">
                  <label style="padding-top: 10px; font-family: 'kittithadabold75regular'; color:black;" for="stctxt" class="col-sm-4 col-form-label"> รับสินค้าที่สาขา: </label>
                    <div class="input-group date col-sm-8">
                        <input type='text'  id="stctxt" disabled='true' placeholder="รับสินค้าที่สาขา" :value="!$store.getters['auth/getISTB'] ? getQTSTORENAME : $store.getters['customer/getEPCUSTINFO'].STNAME"  class="form-control">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <!-- <a @click="startSelectStore"> -->
                                    <i class="fas fa-map-marker-alt"></i>
                                <!-- </a> -->
                            </span>
                        </div>
                    </div>
                </div>  

                <div class="form-group row">
                  <label style="padding-top: 10px; font-family: 'kittithadabold75regular'; color:black;" for="stctxt" class="col-sm-4 col-form-label"> รับสินค้าที่สาขา(ค.ศ.): </label>
                    <div class='input-group date col-sm-8' id="dattxt2" >
                        <input placeholder="รับสินค้าได้ทุกวัน:" type='date' onkeydown="return false" id="dattxt" autocomplete="off" class="form-control" v-model="storeDatePick" :min="minDatePick">
                        <!-- <div class="input-group-append" id="dattxtbtn">
                            <span class="input-group-text"><a><i class="fas fa-calendar-alt"></i></a></span>
                        </div> -->
                    </div>
                </div>  

                <div class="form-group row" :key="storeDatePick">
                  <label style=" padding-top: 10px; font-family: 'kittithadabold75regular'; color:black;" for="tmetxt" class="col-sm-4 col-form-label">เวลารับสินค้า:</label>
                    <div class='input-group date col-sm-8' id="tmetxt2">
                        <input placeholder="เลือกเวลารับสินค้าของท่าน" autocomplete="off" type='text' onkeydown="return false" id="tmetxt" class="form-control timepicker" v-model="storeTimePick"/>
                        <div class="input-group-append" id="tmetxtbtn" >
                            <span class="input-group-text"><a @click="setFocusByID('tmetxt')"><i class="far fa-clock"></i></a></span>
                        </div>
                    </div>
                </div>  
                <p id="picktimeout" style="color:red ; text-align: right;">{{txtPickTimeout}}</p>
            </div>
        </div>
    </section>

</template>

<script>
    import * as serviceMain  from '@/services/main.service';
    import SelectStoreDLV from "@/components/CheckOut/SlotBooking/SelectStoreDLV.vue";
    import PickupStore from "@/components/CheckOut/SlotBooking/PickupStore.vue";
    export default {
        name: 'PickupatStore',
        components: {
            SelectStoreDLV,
            PickupStore
        },
        data: () => {
            return {
                txtPickTimeout: '',
                minDatePick:'',
                trsend:'',
                StoreData:'',
            }
        },
        mounted() {
            this.$store.dispatch('service/setTimePick','')
            this.$store.dispatch('service/setDatePick','')
            this.getMinDatePick()
            this.startTimePicker()
            this.trsend = this.StoreCode
        },
        methods: {
            async startTimePicker(){
                let _this = this;
                // let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                // let locationsTEMP=getQTSAVESTORE.split('|');
                // let getStoreData =  locationsTEMP.map(e => e.split(','));
                // console.log(getStoreData)
                // let currentStoreData = getStoreData.find(e => e[0] == this.trsend);
                this.StoreData = await this.$refs.SelectStoreRef.selectStoreData()
                let opentime = this.StoreData.opentime
                this.$emit("settrsendpickup", this.trsend);
                if(this.storeDatePick == this.minDatePick){
                    let datetime = new Date();
                    let H = datetime.getHours();
                    let m = datetime.getMinutes();
                    if(m>30){
                        H=H+3
                        m=0
                    }else{
                        H=H+2
                        m=30
                    }
                    let HH = H.toString()
                    let mm = m.toString()
                    HH = HH.length > 1 ? HH : '0'+HH;
                    mm = mm.length > 1 ? mm : '0'+mm;
                    opentime = `${HH}.${mm}`
                    this.storeTimePick = ''
                }
                // 0: "920"
                // 1: "บางนา"
                // 2: "13.6524"
                // 3: "100.6767"
                // 4: "02-101-5200"
                // 5: "07.00"
                // 6: "21.00"
                // 7: "54 Moo 13   Bangkaew Bang Phli"
                // 8: "09.00"
                // 9: "19.00"
                // 10: "twd"
                // console.log(currentStoreData)
                let starttimestore = opentime;
                let endtimestore = this.StoreData.closetime;
                $("#tmetxt").timepicker({
                    timeFormat: "H:mm",
                    interval: 30,
                    minTime: starttimestore,
                    maxTime: endtimestore,
                    startTime: starttimestore,
                    dynamic: false,
                    dropdown: true,
                    scrollbar: true,
                    change: function(time) {
                        let datetime = new Date(time);
                        let H = datetime.getHours().toString();
                        H = H.length > 1 ? H : '0'+H;
                        let m = datetime.getMinutes().toString();
                        m = m.length > 1 ? m : '0'+m;
                        _this.storeTimePick = H+':'+m
                    }
                });
            },
            async setFocusByID(para_ID){
                setTimeout(function(){ $( "#"+para_ID ).focus(); }, 500);
                
            },
            async startSelectStore(){
                $('#mapModal').modal('show')
            },
            async getMinDatePick() {
                let now = new Date();
                let dd = now.getDate().toString();
                dd = dd.length < 2 ? '0'+dd : dd;

                let mm = (now.getMonth() + 1).toString();
                mm = mm.length < 2 ? '0'+mm : mm;

                let yyyy = now.getFullYear().toString();

                this.minDatePick = yyyy+'-'+mm+'-'+dd;
            },
            async selectedStore(val){
                this.trsend = val
                this.storeDatePick = ''
                this.storeTimePick = ''
                this.startTimePicker();
            },
        },
        computed: {
            StoreCode:function(){
                return this.$store.getters['service/getQTDFSTORE']
            },
            getQTSTORENAME:function(){
                return this.$store.getters['service/getQTSTORENAME']
            },
            storeTimePick: {
                get () {
                    return this.$store.getters['service/getTimePick']
                },
                set (value) {
                    this.$store.dispatch('service/setTimePick',value)
                }
            },
            storeDatePick: {
                get () {
                    return this.$store.getters['service/getDatePick']
                },
                set (value) {
                    this.$store.dispatch('service/setDatePick',value)
                }
            },
            getQTBOOKING: function () {
                return this.$store.getters["auth/getQTBOOKING"];
            },
            getBU: function() {
                return this.$store.getters['customer/getQTBU'];
            },
        
        },
        watch: {
            StoreCode: function () {
                this.trsend = this.StoreCode
                this.startTimePicker();
            },
            storeDatePick: function () {
                let date = new Date(this.storeDatePick);
                let year = date.getFullYear();
                // console.log(year);
                if(year > 2500){
                    serviceMain.showErrorAlert(this,'รูปแบบปีที่เลือกไม่ถูกต้อง(ค.ศ.)')
                    return
                }
                setTimeout(() => this.startTimePicker(), 500);
                
            }
        },
    }
</script>

<style scoped>
    .cardWhite {
        margin: 0px;
        background-color: white;
        padding: 20px 20px;
        border-radius: 7px;
        height: auto;
        margin-bottom: 0px;
        min-height: auto;
    }
    
    a {
        width: 1.1rem;
    }
    
    a:hover{
        cursor: pointer;
    }
</style>