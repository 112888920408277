<template>
    <section>
        <div class='row m-0' style='margin: 0' >
        <br>
            <div class="col-4 col-sm-4 col-md-3 col-lg-3 pl-0 pr-1" v-if="isShowMasterTime">
                <div class="row m-0">
                    <div class="col-12 text-center">
                        <span style="font-family: 'kittithadabold75regular'">เวลา</span>
                    </div>
                    <div class="col-12">&nbsp;</div>
                    <div :class="`col-12 p-2 slotname-${slotNum}`" v-for="e in masterTimeslots" :key="e.timeslotCode">
                        <div :class="`btn btn-payment-${getBU} w-100 slotname`" :slotmastername="e.timeslotDesc" style="position: relative;">
                            <div class="w-100" style="position: absolute;top:50%;left: 50%;transform: translate(-50%,-50%) !important;-webkit-transform: translate(-50%,-50%) !important;">{{e.timeslotDesc}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8 col-sm-8 col-md-9 col-lg-9 pl-1 pr-3">
                <div class="row m-0 owl-carousel owl-theme bookingowl" :key="bookingslots">
                    <div v-for="bookingslot in bookingslots" :key="bookingslot.deliveryDate">
                        <div class="col px-1">
                            <div class="row m-0">
                                <div class="col-12 text-center"><span style="font-family: 'kittithadabold75regular'">{{bookingslot.delidays}}</span></div>
                                <div class="col-12 p-0" style="overflow: hidden;text-align: center;">{{bookingslot.delidaymonth}}</div>
                                <div class="w-100" v-for="timeslot in bookingslot.timeslots" :key="timeslot.timeSlotCode">
                                    <div class="col-12 p-2">
                                        <div :class="`bookingslotbtn-${slotNum}`" v-if="timeslot.isAvailable == 'Y'">
                                            <button :slotcode="timeslot.timeSlotCode" :disabled="!getQTBOOKING" :dlvdate="bookingslot.deliveryDate" :slotname="timeslot.timeSlotName" :class="`btn btn-payment-${getBU} w-100 bookingslotbtn`">
                                                <p class="align-self-center m-0"><i class="fas fa-truck"></i></p>
                                            </button>
                                        </div>
                                        <div :class="`bookingslotbtn-${slotNum}`" v-else>
                                            <button :slotcode="timeslot.timeSlotCode" :dlvdate="bookingslot.deliveryDate" disabled :class="`btn btn-payment-${getBU} w-100 bookingslotbtn`">
                                                <svg width="60" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="30" height="6" rx="3"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'TimeSlot',
    data: function () {
        return {
            isShowMasterTime: false,
            bookingItms:''
        }
    },
    props: {
        bookingslots: {
            type: Array,
            required: true
        },
        masterTimeslots: {
            type: Array,
            required: true
        },
        startDateBooking: {
            type: String,
            required: true
        },
        slotNum: {
            type: String,
            required: true
        },
        sku: {
            type: String,
            required: true
        },
        indexNum: {
            type: String,
            required: true
        },
    },
    mounted() {
        this.bookingItms = {
            code: '', 
            date: '',
            name: '',
            sku:this.sku
        }
    },
    methods: {
        async startOwlCarousel(slot){
            let _this = this;
            
            $('.owl-carousel').owlCarousel({
                items:5,
                loop: false,
                nav: true,
                navText : ['<i class="fas fa-chevron-left fa" aria-hidden="true"></i>','<i class="fas fa-chevron-right fa" aria-hidden="true"></i>'],
                dots: false,
                autoplay:false,
                slideBy:5,
                navSpeed:50,
                responsive : {
                    0 : {
                        items:2,
                        slideBy:2,
                    },
                    480 : {
                        items:2,
                        slideBy:2,
                    },
                    768 : {
                        items:3,
                        slideBy:3,
                    },
                    992 : {
                        items:4,
                        slideBy:4,
                    },
                    1400 : {
                        items:5,
                        slideBy:5,
                    }
                }
            });

            $(`.bookingslotbtn-${slot} > .bookingslotbtn`).click(function(){
                $(this).blur();
                $(`.bookingslotbtn-${slot} > .bookingslotbtn`).removeClass("active");
                $(this).addClass("active");
                $(`.slotname-${slot} > .slotname`).removeClass("active");
                $(`.slotname-${slot}`).find("[slotmastername='"+$(this).attr("slotname")+"']").addClass("active");

                _this.bookingItms = {
                    code: $(this).attr("slotcode"), 
                    date: $(this).attr("dlvdate"),
                    name: $(this).attr("slotname"),
                    sku: _this.sku
                }
            });

            this.isShowMasterTime = true;
            this.setCompleteTimeSlot();
        },
        async setHideMasterTime() {
            this.isShowMasterTime = false
        },
        async setCompleteTimeSlot() {
            this.$emit("completeSlotTime", true)
        },
    },
    computed:{
        getBuColor:function(){
            let color = '';
            switch(this.getBU) {
                case 'bnb':
                    color = '#006e63';
                    break;
                case 'at1':
                    color = '#3866af';
                    break;
                default:
                    color = '#55246c';
            }
            return color;
        },
        getBU:function(){
            return this.$store.getters['customer/getQTBU']
        },
        // bookingItms: {
        //     get () {
        //         this.$store.getters['service/getBookingItms'];
        //     },
        //     set (value) {
        //         this.$store.dispatch('service/setBookingItms',value)
        //     }
        // },
        bookingItems: function() {
            return this.bookingItms;
        },
        getQTCHKP: function () {
            return this.$store.getters["service/getQTCHKP"];
        },
        getQTBOOKING: function () {
            return this.$store.getters["auth/getQTBOOKING"];
        },
    },
    watch: {
        bookingslots: function () {
            this.$nextTick(() => {
                this.isShowMasterTime = false;
                if(this.bookingslots.length > 0) this.startOwlCarousel(this.slotNum);
            })
        },
        masterTimeslots: function () {
            this.setHideMasterTime();
        },
        bookingItems: function() {
            this.$emit("bookingItems", [this.bookingItems,this.indexNum]);
        },
    }
}
</script>

<style scope lang="scss">
    $color-PWB: #55246c;
    $color-bnb: #006e63;
    $color-at1: #3866af;
    .btn-payment-PWB,
    .btn-payment-bnb,
    .btn-payment-at1{
        color: #666666;
        background-color: white;
        font-weight:700;
        font-size: 18px;
        padding: 0;
        border: 1px solid #BBBBBB;
        height: 66px;
        line-height: 1em;
    }
    .btn-payment-PWB:focus,
    .btn-payment-bnb:focus,
    .btn-payment-at1:focus
    {
        outline: none;
        box-shadow: none;
    }
    .btn-payment-PWB svg,
    .btn-payment-bnb svg,
    .btn-payment-at1 svg{
        fill:#CCCCCC;
    }
    .btn-payment-PWB:not(.disable):not(.active):hover svg{fill:$color-PWB;}.btn-payment-PWB.active svg>path:not([fill="white"]){fill:white;}.btn-payment-PWB.active svg>[fill="white"]{fill:$color-PWB;}
    .btn-payment-bnb:not(.disable):not(.active):hover svg{fill:$color-bnb;}.btn-payment-bnb.active svg>path:not([fill="white"]){fill:white;}.btn-payment-bnb.active svg>[fill="white"]{fill:$color-bnb;}
    .btn-payment-at1:not(.disable):not(.active):hover svg{fill:$color-at1;}.btn-payment-at1.active svg>path:not([fill="white"]){fill:white;}.btn-payment-at1.active svg>[fill="white"]{fill:$color-at1;}

    .btn-payment-PWB>span,
    .btn-payment-bnb>span,
    .btn-payment-at1>span{
        display: inline;
    }
    .btn-payment-PWB:not(.disable):not(:disabled):hover{
        border: 1px solid $color-PWB;
    }
    .btn-payment-bnb:not(.disable):not(:disabled):hover{
        border: 1px solid $color-bnb;
    }
    .btn-payment-at1:not(.disable):not(:disabled):hover{
        border: 1px solid $color-at1;
    }
    .btn-payment-PWB.active:not(:disabled){
        color: white;
        background-color: $color-PWB;
        border: 1px solid $color-PWB;
    }
    .btn-payment-bnb.active:not(:disabled){
        color: white;
        background-color: $color-bnb;
        border: 1px solid $color-bnb;
    }
    .btn-payment-at1.active:not(:disabled){
        color: white;
        background-color: $color-at1;
        border: 1px solid $color-at1;
    }
    .bookingowl .owl-prev{
        position: absolute;
        top: .7rem;
        left: -1rem;
        display: block;
        color: black !important;
        opacity: .3;
        z-index: 99;
        cursor: pointer;
        transform: translate(0, -50%);
        background: none !important;
        outline: none !important;
    }
    .bookingowl .owl-prev.disabled{
        display: none !important;
    }
    .bookingowl .owl-prev:hover{
        opacity: .9;
        background: none;
    }
    .bookingowl .owl-next{
        position: absolute;
        top: .7rem;
        right: -1rem;
        display: block;
        color: black !important;
        opacity: .3;
        z-index: 99;
        cursor: pointer;
        transform: translate(0, -50%);
        background: none !important;
        outline: none !important;
    }
    .bookingowl .owl-next.disabled{
        display: none !important;
    }
    .bookingowl .owl-next:hover{
        opacity: .9;
    }
    .bookingslotbtn{
        padding: .3em;
        color: #2a2a2a;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a2a2a;
        width: 100%;
        background-color: white;
    }
    .bookingslotbtn:hover{
        padding: .3em;
        color: white;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a2a2a;
        width: 100%;
        background-color: #2a2a2a;
    }
    .bookingslotbtn.active{
        padding: .3em;
        color: white;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a2a2a;
        width: 100%;
        background-color: #2a2a2a;
    }

    .btnPrevNext{
        border: none;
        color: white;
        padding: 0px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 0.5rem;
        cursor: pointer;
        margin: 0px 2px;
        border-radius:4px;
    }
</style>