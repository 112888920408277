<template>
  <section>
    <div class='slotbooking-div'>
      <div  class="row">
        <div class="col-12 p-0">
          <div class='cardWhite'>
            <span class='fnt-header' style="font-size:1.3rem;">วิธีการจัดส่ง</span>
            <hr style="margin:0px;"><span class='fnt-header'>ประเภทการจัดส่ง</span>
            <select class='form-control' @change='chgSelTrType($event);' :key='QTDLVTRTYPE' v-model='QTDLVTRTYPE'>
              <option value=''>กรุณาเลือกประเภทการจัดส่ง</option>
                <option v-show="!isXmas" :value='dlvTpeOpt.KER'>Kerry</option>
                <option v-show="!isXmas || (isXmas && getBU == 'bnb')" :value='dlvTpeOpt.DIY'>DIY</option>
                <option v-show="!isXmas" :value='dlvTpeOpt.CON'>CON</option>
                <option v-show="isXmas && getBU != 'bnb'" :value='dlvTpeOpt.CRI'>CRI</option>
            </select><br>
            <div v-if='getQTBOOKING'>
              <span v-if='dlvTrType == dlvTpeOpt.KER'>จัดส่งโดยผู้ให้บริการขนส่ง ใช้เวลาจัดส่ง 2 - 3 วันทำการสำหรับกรุงเทพและปริมณฑล และ 3 - 5 วันทำการสำหรับต่างจังหวัด</span>
              <div v-if='[dlvTpeOpt.DIY, dlvTpeOpt.CON, dlvTpeOpt.CRI].includes(dlvTrType)'>
                <span v-if="isShowDesc">เลือกช่วงจัดส่งได้ 14 วันล่วงหน้าเร็วที่สุดที่สามารถเลือกได้ 08:00 - 17:00 ค่าจัดส่ง {{flee && flee != '0' ? flee + ' บาท' : 'ฟรี'}} กรุณาเลือกวันและเวลารับสินค้า</span>
                <div>
                  <div class='row m-0' style='margin: 0' v-if="isShowNextPrev">
                    <div class="col-12">
                        <span style='float: right;' v-if='lastSlotDate'>
                            <button class="twdorbnb btnPrevNext" 
                                    :style="`background-color: ${getBuColor};`" 
                                    @click="getNewSlot($event, 'next', `'${lastSlotDate}'`)">
                                <strong style="font-family: 'kittithadamedium65regular';"> &gt;&gt; </strong>
                            </button>
                        </span>
                        <span style='float: right;' v-if='firstSlotDate && firstSlotDate != startDateBooking'>
                            <button class="twdorbnb btnPrevNext" 
                                    :style="`background-color: ${getBuColor};`" 
                                    @click="getNewSlot($event, 'prev', `'${firstSlotDate}'`)">
                                <strong style="font-family: 'kittithadamedium65regular';"> &lt;&lt; </strong>
                            </button>
                        </span>
                    </div>
                  </div><br>
                  <TimeSlot @completeSlotTime="completeSlotTime" :key="isShowSlot" :bookingslots='bookingSlots' :masterTimeslots='masterTimeSlots' :startDateBooking='startDateBooking'/>
                </div>
                <SlotBookingSkeleton v-if="isLoading && dlvTrType != dlvTpeOpt.KER && !isShowDesc"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as serviceAPI from "@/services/API.service";
import * as serviceMain from "@/services/main.service";
import TimeSlot from "@/components/CheckOut/SlotBooking/TimeSlotB.vue";
import SlotBookingSkeleton from "@/components/CheckOut/SlotBooking/SlotBookingSkeleton.vue";

export default {
  name: "SlotBooking",
  components: {
    TimeSlot,
    SlotBookingSkeleton
  },
  data: function() {
    return {
      dlvTpeOpt: {
        KER: 'KER',
        DIY: 'DIY',
        CON: 'CON',
        CRI: 'CRI'
      },
      itmTran: {},
      dlvTrType: '',
      bookingSlots: [],
      masterTimeSlots: [],
      flee: 0,
      startDateBooking: '',
      errSaveTrans: false,
      errStoreBooking: false,
      deliDaysOfWeek: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
      deliMonthThai: ["ม.ค.","ก.พ.","มี.ค.","ม.ษ.","พ.ค.","มิ.ย.","ก.ค.","ส.ค.","ก.ย.","ต.ค.","พ.ย.","ธ.ค."],
      lastSlotDate: '',
      firstSlotDate: '',
      isShowSlot: false,
      isShowDesc: false,
      isShowNextPrev: false,
      isLoading: true,
      skuChrismas: ['60284074','60284075','60300086','60300087','60330707','60353328'],
      isXmas: false
    }
  },
  props:[
    "orderItems",
    "dataAddress"
  ],
  mounted() {
    this.dlvTrType = this.QTDLVTRTYPE;
    if(!this.dlvTrType) return;
          
    this.flee = this.DeliveryFee
    this.reloadSlotTimes();
  },
  props: [
    "orderItems"
  ],
  methods: {
    async checkIsXmas () {
      try {
        this.isXmas = this.orderItems.findIndex(itm => this.skuChrismas.includes(itm.SKC)) > -1
        if(this.isXmas && !(this.QTDLVTRTYPE == 'CRI') || !this.isXmas && this.QTDLVTRTYPE == 'CRI') {
          this.QTDLVTRTYPE = '';
          this.clearSlotTime()
          if(!this.dlvTrType) return;
          
          this.flee = this.DeliveryFee
          this.reloadSlotTimes();
        }
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async chgSelTrType (event) {
      this.dlvTrType = event.target.value;
      this.QTDLVTRTYPE = this.dlvTrType
      this.isShowSlot = false;
      this.isShowDesc = false;
      this.isShowNextPrev = false;

      if(!this.dlvTrType) return;
      this.bookingSlots = [];
      this.masterTimeSlots = [];
      this.DeliveryFee = 0;
      this.reloadSlotTimes();
    },
    async reloadSlotTimes() {
      try {
        this.isLoading = true
        let data = {
          REF: this.getQTCUSTREF
        }
        let tranData = await serviceAPI.call_API('post', 'checkout/getTransportSpec', data,'auth');
        if(tranData.data.dbrows > 0) {
          this.itmTran = tranData.data.dbitems[0];
          this.dlvTrType = this.QTDLVTRTYPE || this.itmTran.trtype;

          let data = {
            saleSource: this.itmTran.salesource,
            deliveryFromStore: this.itmTran.trfrom,
            deliveryType: this.dlvTrType,
            shipToPostalCode: this.itmTran.topostalcode,
            shipToLatitude: this.itmTran.tolat,
            shipToLongitude: this.itmTran.tolong,
            packageWeight: this.itmTran.sumweight,
            totalprice: this.itmTran.samount,
            cubicom:this.itmTran.cubicom
          }
          this.callDeliveryRate(data);
        } else {
          this.$swal.fire({
            html:
              "<h2>กรุณาเพิ่มที่อยู่ของท่านก่อนการชำระเงินด้วยครับ</h2>",
            showCancelButton: false,
            confirmButtonColor: this.getBuColor,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: "ตกลง",
          }).then((result) => {
            if (result.value) {
            }
          });
        }
      } catch (error) {
        this.isLoading = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async callDeliveryRate(data){
      try {
        let callDeliveryRate = await serviceAPI.call_API('post', 'checkout/callDeliveryRate', data,'auth');
        let deliveryRateData = callDeliveryRate.data.data;
        if(deliveryRateData.errorMessage != "Success") {
          serviceMain.showWarningAlert(this,"ขณะนี้ระบบคำนวนค่าขนส่งขัดข้องชั่วคราว กรณีมีค่าขนส่ง เจ้าหน้าที่จะติดต่อท่านเพื่อยืนยันการจัดส่ง");
          this.isLoading = false;
          return;
        } 
        
        let trans = {
          amount: (Math.round(deliveryRateData.data.deliveryRate * 100) / 100).toFixed(2),
        };
        
        let saveTransportData = {
          REF: this.getQTCUSTREF,
          TRAN_JSON: trans
        }
        
        let saveTransport = await serviceAPI.call_API('post', 'checkout/SaveTransport', saveTransportData, 'auth');
        if(saveTransport.data.dbcode > 0) {
          serviceMain.showWarningAlert(this,"ขณะนี้ระบบคำนวนค่าขนส่งขัดข้องชั่วคราว กรณีมีค่าขนส่ง เจ้าหน้าที่จะติดต่อท่านเพื่อยืนยันการจัดส่ง");
          this.isLoading = false;
          return;
        }
        
        this.reloadOrderInfo();
        if(!this.getQTBOOKING || this.dlvTrType == this.dlvTpeOpt.KER) {
          return;
        }

        this.calculateStartDateBooking();
      } catch (error) {
        this.isLoading = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async calculateStartDateBooking() {
      try {
        let data = {
          REF: this.getQTCUSTREF
        }
        let leadtime = await serviceAPI.call_API('post', 'checkout/getPreOrderMsg4Payment', data, 'auth');

        let datenow = new Date();
        let datenowcompare = (datenow.getHours() < 10 ? '0' : '')+datenow.getHours()+":"+(datenow.getMinutes() < 10 ? '0' : '')+datenow.getMinutes();
        datenow.setDate(datenow.getDate() + ((datenowcompare >= '12:00') ? 2 : 1))
        let leadday = (leadtime.data.dbcode == 0 && leadtime.data.dbrows > 0) ? leadtime.data.dbitems[0]:null;
        if(leadday) datenow.setDate(datenow.getDate() + (leadday.LEADTIME+2));

        this.startDateBooking = datenow.getFullYear()+'-' + (String(datenow.getMonth() + 1).padStart(2, '0')) + '-'+(String(datenow.getDate()).padStart(2, '0'));
        this.callTimeSlots(this.startDateBooking);
      } catch (error) {
        this.isLoading = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async callTimeSlots(startDateSlot) {
      try {
        this.isShowSlot = true;
        let data = {
          saleSource: this.itmTran.salesource,
          saleByStore:this.itmTran.trfrom,
          deliveryByStore:this.itmTran.trfrom,
          deliveryDate:startDateSlot,
          provinceName:this.dataAddress.PVN,
          districtName:this.dataAddress.DST,
          subDistrictName:this.dataAddress.TBN,
          postalCode:this.itmTran.topostalcode,
          homeLat:this.itmTran.tolat,
          homeLon:this.itmTran.tolong,
          jobType:this.dlvTrType,
          truckType:"",
          qty:1,
          packageWeight:this.itmTran.sumweight,
          packageVolume:this.itmTran.cubiccm,
          totalPrice:this.itmTran.samount,
          serviceUpcCode:""
        }
        let getBookingSlot = await serviceAPI.call_API('post', 'checkout/getBookingSlot', data,'auth');
        let bookingSlotData = getBookingSlot.data.data;
        switch(bookingSlotData.errorCode){
          case "":
              this.errSaveTrans = false;
              this.errStoreBooking = false;
              this.bookingSlots = bookingSlotData.data;
              this.masterTimeSlots = bookingSlotData.masterTimeslots;
              this.bookingSlots.forEach((days) => {
                  var new_date = new Date(days.deliveryDate);
                  days.delidays = this.deliDaysOfWeek[new_date.getDay()];
                  var getdelidate = new_date.getDate();
                  var getdelimonth = this.deliMonthThai[new_date.getMonth()];
                  days.delidaymonth = getdelidate+" "+ getdelimonth;
              });
          break;
          case "001":
              this.errSaveTrans = true;
              this.isShowSlot = false;
          case "002":
              this.errStoreBooking = true;
              this.isShowSlot = false;
          break;
        }

        if(this.errSaveTrans){
          serviceMain.showWarningAlert(this,"ขณะนี้ระบบคำนวณค่าขนส่งขัดข้องชั่วคราว หากต้องการให้จัดส่ง กรุณาลองใหม่อีกครั้ง หรือ ติดต่อสาขา");
          this.isLoading = false;
          return;
        }

        if(this.errStoreBooking) {
          this.$swal.fire({
              html: "<h2>สถานที่จัดส่งไม่รองรับบริการขนส่ง กรุณาเลือกที่อยู่จัดส่งใหม่</h2>",//"<h2>สถานที่จัดส่งไม่รองรับบริการขนส่ง กรุณาเลือกสาขาหรือที่อยู่จัดส่งใหม่</h2>",
              showCancelButton: false,//true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: this.getBuColor,
              // cancelButtonColor: '#B4B4B4',
              confirmButtonText: 'เลือกที่อยู่จัดส่ง'//'เลือกสาขา',
              //cancelButtonText: 'เลือกที่อยู่จัดส่ง'
          }).then((result) => {
              if (result.value) {
                  this.isLoading = false;
                  this.QTDLVTRTYPE = '';
                  this.$swal.close();
              }
          });
          return;
        }

  console.log(this.bookingSlots);
        this.firstSlotDate = this.bookingSlots[0].deliveryDate;
        this.lastSlotDate = this.bookingSlots[this.bookingSlots.length - 1].deliveryDate;
        this.isShowNextPrev = true;
        this.calculateFlee();
      } catch (error) {
        this.isLoading = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async calculateFlee(){
      this.reloadFlee();
      this.isShowDesc = true;
    },
    async getNewSlot(event, nextOrPrev, firstOrLastSlotDate) {
        event.target.blur();
        let nextOrPrevSlotDate = new Date(firstOrLastSlotDate);
        let startSlotDate = new Date(nextOrPrevSlotDate.setDate(nextOrPrevSlotDate.getDate() + (nextOrPrev == 'next' ? 1 : -14)));
        let startSlot = startSlotDate.getFullYear()+'-' + (String(startSlotDate.getMonth() + 1).padStart(2, '0')) + '-'+(String(startSlotDate.getDate()).padStart(2, '0'));

        this.isShowSlot = false;
        this.callTimeSlots(startSlot)
    },
    async clearSlotTime() {
      this.isShowSlot = false;
      this.dlvTrType = '';
      this.flee = 0;
    },
    async reserveBooking() {
      if (!this.errSaveTrans && !this.errStoreBooking && this.getQTCHKP == 'Delivery' && this.bookingSlots && this.bookingSlots.length > 0) {
        if(!(this.getBookingItms.code && this.getBookingItms.code != "" && this.getBookingItms.date && this.getBookingItms.date != "" && this.getBookingItms.name && this.getBookingItms.name != "")){
          throw new Error('ไม่สามารถเลือกช่วงเวลาจัดส่งนี้ได้กรุณาลองใหม่อีกครั้ง');
        } 

        try {
          let data = {
            REF: this.getQTCUSTREF,
            bookingSlotCode: this.getBookingItms.code,
            bookingSlotDate: this.getBookingItms.date,
            bookingSlotName: this.getBookingItms.name,
            saleSource: this.getQTSALESOURCE,
            dlvTrType: this.QTDLVTRTYPE
          }
          let reserveBookingSLot = await serviceAPI.call_API('post', 'checkout/reserveBooking', data,'auth');
          switch (reserveBookingSLot.data.data.status){
            case 1:
              return true;
            case 2:
              throw new Error('ช่วงเวลาจัดส่งที่คุณเลือกเต็มแล้วในขณะนี้กรุณาเลือกช่วงเวลาจัดส่งใหม่ครับ');
            default: 
              throw new Error('ไม่สามารถเลือกช่วงเวลาจัดส่งนี้ได้กรุณาลองใหม่อีกครั้ง');
          }   
        } catch(error) {
          throw new Error(error)
        }
      }
    },
    async reloadOrderInfo() {
        this.$store.dispatch('service/setReloadOrderInfo')
    },
    async reloadFlee() {
        this.$store.dispatch('service/setReloadFlee')
    },
    async completeSlotTime(isComplate) {
      this.isLoading = !isComplate
    }
  },
  computed: {
    getQTCUSTREF: function () {
      return this.$store.getters["customer/getQTCUSTREF"];
    },
    getQTCUSTINFO: function () {
      return this.$store.getters["customer/getQTCUSTINFO"];
    },
    getQTBOOKING: function () {
      return this.$store.getters["auth/getQTBOOKING"];
    },
    getBU: function() {
      return this.$store.getters['customer/getQTBU'];
    },
    getBuColor:function(){
      let color = '';
      switch(this.getBU) {
        case 'bnb':
          color = '#006e63';
        break;
        case 'at1':
          color = '#3866af';
        break;
        default:
          color = '#55246c';
      }
      return color;
    },
    getStore: function () {
      return this.$store.getters["service/getQTDFSTORE"];
    },
    QTDLVTRTYPE: {
      get () {
        return this.$store.getters['customer/getQTDLVTRTYPE'] || ''
      },
      set (value) {
        this.$store.dispatch('customer/setQTDLVTRTYPE',value)
      }
    },
    getBookingItms: function () {
      return this.$store.getters["service/getBookingItms"];
    },
    getQTCHKP: function () {
      return this.$store.getters["service/getQTCHKP"];
    },
    getQTSALESOURCE: function () {
      return this.$store.getters["auth/getQTSALESOURCE"];
    },
    DeliveryFee: {
      get () {
        return this.$store.getters['service/getDeliveryFee']
      }, 
      set (value) {
        this.$store.dispatch('service/setDeliveryFee', value)
      }
    }
  },
  watch: {
    getStore: function () {
      this.QTDLVTRTYPE = '';
      this.clearSlotTime();
      // this.reloadSlotTimes();
    },
    getQTCUSTREF: function (){
      this.QTDLVTRTYPE = ''
    },
    DeliveryFee: function() {
      this.isShowDesc = false
      this.flee = this.DeliveryFee
      this.isShowDesc = this.flee > 0
    },
    orderItems: function (newValue, oldValue) {
      if(newValue != oldValue) {
        setTimeout(() => {
          this.checkIsXmas();
        }, 1000)
      }
    }
  }
}
</script>

<style scoped>
  .fnt-header {
    font-family: 'kittithadabold75regular';
  }

  .cardWhite{
    margin: 0px;
    background-color: white;
    padding:20px 20px;
    border-radius: 7px;
    height: auto;
    margin-bottom: 0px;
    min-height: auto;
  }
  
  .form-control {
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    border-bottom: .5px solid #ccc;
    box-shadow: none;
    border-radius: 0px;
    font-weight: bolder;
  }
</style>