<template>
  <section>
    <div class="form-group row">
      <label style="padding-top: 10px; font-family: 'kittithadabold75regular'; color:black;" for="stctxt" class="col-sm-4 col-form-label py-0"> เลือกรับที่สาขา: <br>
      </label>
      <div class="input-group date col-sm-8">
        <!-- <select id="stctxt" v-model="selected" @change="selectStore" class="form-control select-address" style="height:1.5rem;">
          <option disabled value="">เลือกสาขา</option>
          <option v-for="(e,index) in listStore" :key="index" :value="e[0]">{{`${e[10]=='twd'?'ไทวัสดุ สาขา':'' }`}}{{`${e[10]=='bnb'?'บ้านแอนด์บียอนด์ สาขา':  '' }`}}{{`${e[10]=='at1'?'Auto1 สาขา':'' }`}}{{e[1]}} ({{e[0]}})</option>
        </select> -->
        <!-- <select id="stctxt" v-model="selected" @change="selectStore" class="form-control select-address" style="height:1.5rem;">
          <option disabled value="">เลือกสาขา</option>
          <option v-for="(e,index) in listRQStoreData" :key="index" :value="e.id">{{ e.text }}</option>
        </select> -->
        <Select2 class="w-100" @select="selectStore()" v-model="selected" :options="listRQStoreData" :settings="select2Settings"/>
      </div>

    </div>
  </section>
</template>

<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';
  import Select2 from 'vue3-select2-component';
  import mixin from '@/mixins/mixin.js'
  export default {
    name: "PickupStore",
    components: {
      Select2
    },
    mixins: [mixin],
    data:function() {
      return {
        selected: [],
        options: [],
        listStore: [],
        tempObj:[],
        search:'',
        select2Settings: {
          placeholder: "ระบุ Store 5 หลัก", 
          width: "100%"
        },
        listRQStoreData:[],
        listData:[],
      };
    },
    props:[
      "trfrom",
      "pick",
      "SKU"
    ],
    mounted() {
      this.startStore();
    },
    methods: {
      // async startStore(){
      //   let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
      //   if(getQTSAVESTORE){
      //     let locationsTEMP=getQTSAVESTORE.split('|');
      //     locationsTEMP = locationsTEMP.map(e => e.split(','));
      //     console.log(locationsTEMP);
      //     this.listStore = locationsTEMP.map(e => ({
      //           id: '60'+e[0],
      //           text: `60${e[0]} - ${e[1]}`,
      //       }));
      //   }
      //   this.selected = this.trfrom
      //   this.selectStore();
      // },
      async startStore(){
          try {
              let getAPI = await serviceAPI.call_API('post',`checkout/getPickupByStoreSKU`,{SKU:this.SKU},'auth');
              // console.log(getAPI);
              this.listData = getAPI.data.dbitems
              if(!getAPI.data.dbitems[0].listdata){
                return
              }
              let locationsTEMP = getAPI.data.dbitems[0].listdata.split('|');
              this.listStore = locationsTEMP.map(e => e.split(','));
              console.log(this.listStore.map(itm => parseInt(itm[itm.length-1])));
              this.listRQStoreData = this.listStore.filter((e) => parseInt(e[e.length-1]) > 0).map(itm => ({
                  id: itm[0],
                  text: `${itm[0]} - ${itm[1]}`
              }));
              // if(this.pick == 'Y'){
              //   this.listRQStoreData.shift()
              // }
              // // if(this.pick != 'Y'){
              // //   this.listRQStoreData.unshift({
              // //       id: '60004',
              // //       text: `60004 -  DC - Wangnoi Warehouse Branch`
              // //   })
              // // }
              // if(this.trfrom.length == 3){
              //   this.selected = this.trfrom
              // }
              // else{
              //   this.selected = getAPI.data.dbitems.find(e => e.STORE == this.trfrom).stcode
              // }
              // this.selectStore();
          } catch (error) {
              serviceMain.showErrorAlert(this,error.message)
          }
      },
      async selectStore(){
        // let selectstore = this.listStore.find(e => e[0] == this.selected)
        // console.log(selectstore);
        this.$emit("selectedStore", this.selected);
        // this.$emit("selectedStore", selectstore);
      },
      async selectStoreData(){
        // console.log(this.listData.find(e => e.stcode == this.selected.slice(2,5)));
        return this.listStore.find(e => e[0] == this.selected)
      },
    },
    computed: {
      getQTSAVESTORE: function () {
        return this.$store.getters['service/getQTSAVESTORE']
      },
      CartRef: function () {
        return this.$store.getters['customer/getQTCUSTREF']
      },
      StoreCode: function () {
        return this.$store.getters['service/getQTDFSTORE']
      }
    },
    watch: {
      getQTSAVESTORE: function () {
          this.startStore()
      },
      // trfrom: function () {
      //   this.selected = this.trfrom.slice(2)
      // },
    }
  };
</script>

<style scoped lang="scss">
.select-address{
  padding:0px 15px; 
  font-family: 'kittithadabold75regular'; 
  color:black; 
  background-color: rgb(241, 241, 241);
  border-radius: 25px; 
}
</style>
