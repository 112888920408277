<template>
    <section>
        <div class="row checkout-card justify-content-start px-2">
          <div v-show="getQTETAX == 1 || (getQTETAX == 2 && isTax)" class="" style="max-width: 150px;">
            <span class="mr-1" style="font-family: 'kittithadabold75regular'; text-align:center;">{{typeName}}</span>
          </div>
          <div v-show="(getQTETAX == 2 && isTax)" style="mid-width:100px;" id="selectButton">
            <div :class="`btn-toggle-${buCode || getBU} ${invoiceType == 'P' ? `active-${buCode || getBU}` : ''}`" @click="invoiceType='P'" style="border-radius: 4px 0px 0px 4px;">กระดาษ</div>
            <div :class="`btn-toggle-${buCode || getBU} ${invoiceType == 'E' ? `active-${buCode || getBU}` : ''}`" @click="invoiceType='E'" style="border-radius: 0px 4px 4px 0px;">อิเล็คโทรนิคส์</div>
          </div>
          <div v-show="(getQTETAX == 2 && isTax) && invoiceType == 'E'" class="" style="max-width: 150px;" >
            <span class="mx-1" style="font-family: 'kittithadabold75regular'; text-align:center;">ส่งไปที่</span>
          </div>
          <div v-show="(getQTETAX == 1 || ((getQTETAX == 2 && isTax) && invoiceType == 'E'))" style="mid-width:100px;" id="selectButton" >
            <div v-show="!isUnuseSMS" :class="`btn-toggle-${buCode || getBU} ${isActiveSMS ? `${isActiveSMS}-${buCode || getBU}` : ''}`" @click="display('sms')" style="border-radius: 4px 0px 0px 4px;">SMS</div>
            <div :class="`btn-toggle-${buCode || getBU} ${isActiveEMAIL ? `${isActiveEMAIL}-${buCode || getBU}` : ''}`" @click="display('email')" style="border-radius: 0px 4px 4px 0px;">EMAIL</div>
          </div>
          <div v-show="(getQTETAX == 1 || ((getQTETAX == 2 && isTax) && invoiceType == 'E'))" class="flex-grow-1 ml-1">
            <input type="text" style="width:100%;" v-model="displayText">
          </div>
        </div>
    </section>

</template>


<script>
// import * as serviceAPI  from '@/services/API.service';
// import * as serviceMain  from '@/services/main.service';
export default {
  name: 'SmsandEmailButton',
  data: function () {
    return {
      isActiveSMS:'active',
      isActiveEMAIL:'',
      displayText:'',
      invoiceType:'E',
      salesourceOnlyEmail: ['UA','US','U1','UO'],
      isUnuseSMS: false,
    }
  },
  props: [
    'isTax',
    'dataDisplayText',
    "type",
    "buCode"
  ],
  mounted() {
    this.isUnuseSMS = this.salesourceOnlyEmail.includes(this.getQTSALESOURCE)
    this.display(this.type || this.getQTETAX == 2 ? 'email' : 'sms')
    this.$emit('setInvoiceType', this.invoiceType)
  },
  methods: {
    async display(type){
      if(type == 'sms'){
        this.isActiveSMS = 'active';
        this.isActiveEMAIL = '';
        this.displayText = this.dataDisplayText.MOBILE;
      } else if (type == 'email'){
        this.isActiveSMS = '';
        this.isActiveEMAIL = 'active';
        this.displayText = this.dataDisplayText.EMAIL;
      }
    }
  },
  computed: {
    getBU:function(){
      return this.$store.getters['customer/getQTBU']
    },
    typeName:function(){
      if(this.isTax == true){
        return "รับใบกำกับภาษีทาง"
      } else if(this.isTax == false){
        return "รับใบเสร็จทาง"
      }
    },
    getDisplayText:function(){
      return this.displayText
    },
    getQTETAX: function () {
        return this.$store.getters['auth/getQTETAX']
    },
    getinvoiceType: function () {
        return this.invoiceType
    },
    getQTSALESOURCE: function () {
      return this.$store.getters["auth/getQTSALESOURCE"];
    },

      
  },
  watch:{
    dataDisplayText:function(){
      this.display('sms')
    },
    getDisplayText:function(){
      this.$emit('setSendBill', [this.displayText, this.isActiveSMS == 'active' ? 'S,'+this.displayText : 'E,'+this.displayText])
    },
    getinvoiceType:function(){
      this.$emit('setInvoiceType', this.invoiceType)
    },
  }

}

</script>

<style scoped lang="scss">
$color-PWB: #55246c;
$color-bnb: #006e63;
$color-at1: #3866af;
$color-white: white;
$color-black: black;
$listBu: 'PWB' $color-PWB, 'bnb' $color-bnb, 'at1' $color-at1;

.checkout-card {
  border-radius: 7px;
  background-color: $color-white;
  height: auto;
  padding: 10px 0px;
  display: flex;
	align-items: center;
}

@mixin btn-toggle($color) {
  // width: 60px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  color: black;
  font-weight: 900;
  border: 3px solid $color !important;
  font-family: 'kittithadabold75regular';
  text-align:center;
  transition: all 0.5s;
  
  &:hover {
    background-color: $color;
  border: 3px solid $color !important;
  }
  &:active {
    background-color: $color;
  border: 3px solid $color !important;
  }
  &:focus {
    background-color: $color;
  border: 3px solid $color !important;
  }
}

@each $buItems in $listBu {
.btn-toggle-#{nth($buItems, 1)}{
  @include btn-toggle(nth($buItems, 2));
  }
  
}

</style>