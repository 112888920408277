<template>
  
    <section>
      <div class="row card ">
        <div class="col-12">
          <div class="d-flex pt-3 justify-content-between">
            <div>
              <p class="h3">{{tax?'ใบกำกับภาษี':'สถานที่จัดส่ง'}}</p>
            </div>
            <div v-if="!isAddAddress">
              <div v-if="!isChange">
                <button v-if="!tax" @click="isChange=!isChange" :class="`btn-visible-${getBU}`"  >เปลี่ยนที่อยู่จัดส่ง</button>
                <button v-else @click="isChange=!isChange" :class="`btn-visible-${getBU}`"  >เปลี่ยนที่อยู่ใบกำกับภาษี</button>
              </div>
              <button v-else @click="isChange=!isChange" :class="`btn-visible-${getBU}`" >ยกเลิก</button>
            </div>
            <div v-else>
              <button @click="isAddAddress=!isAddAddress" :class="`btn-visible-${getBU}`" >ยกเลิกการเพิ่มที่อยู่ใหม่</button>
            </div>
          </div>
        </div>
        <div v-if="!isAddAddress" class="col-12">
          <div class="row">
            <div v-if="isChange" class="col-12">
              <label for="seladd" style="font-family: 'kittithadabold75regular'; color:black; ">เลือกที่อยู่ในการจัดส่งของท่าน</label>
              <select v-model="selectedData" @change="selectOnChange" for="seladd" class="form-control select-address" style="height:1.5rem;">
                <option disabled value="">เลือกที่อยู่จัดส่ง</option>
                <option v-for="(e,index) in listShip" :key="index" :value="e.ANM">{{ e.ANM }}</option>
              </select>
            </div>
            <div v-if="isChange" class="col-12 mt-2">
              <button :class="`btn-visible-${getBU} mb-2`" @click="selectSaveToDlv" >เลือกที่อยู่และบันทึก</button>
              <!-- <button :class="`btn-visible-black`" @click="isAddAddress=!isAddAddress" >เพิ่มที่อยู่ใหม่</button> -->
            </div>
            <div class="col-12">
              <hr class="my-2">
            </div>
            <div  class="col-12">
              <div class="row">
                <div class="form-group col-md-6">
                    <label class="labelshowaddress" > ชื่อ</label>                               
                    <p class="pshowaddress" >{{selectedObjData.NME}}</p>
                </div>
                <div class="form-group col-md-6">
                    <label class="labelshowaddress"> นามสกุล</label>
                      <p class="pshowaddress" >{{selectedObjData.LNME}}</p>
                </div>
                <div v-if="tax" class="col-12">
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label class="labelshowaddress"> เลขประจำตัวผู้เสียภาษี/เลขบัตรประจำตัวประชาชน</label>
                      <p class="pshowaddress">{{selectedObjData.IDN}}</p>
                    </div>
                  </div>
                </div>
                <div v-if="tax" class="col-12">
                  <div class="row">
                    <div class="form-group col-6 col-md-3">
                      <label class="labelshowaddress"> สำนักงานใหญ่/สาขา</label>
                      <p class="pshowaddress">{{selectedObjData.ISHQ == 'Y' ? 'สำนักงานใหญ่' : selectedObjData.ISHQ == 'N' ? 'สาขา' : ''}}</p>
                    </div>
                    <div class="form-group col-6 col-md-3">
                      <label class="labelshowaddress"> ลำดับที่สาขา</label>
                      <p class="pshowaddress">{{selectedObjData.IDB}}</p>
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label class="labelshowaddress"> บริษัท</label>
                      <p class="pshowaddress">{{selectedObjData.COM}}</p>
                    </div>
                  </div>
                </div>
                <div class="form-group col-6">
                    <label class="labelshowaddress"> เบอร์ติดต่อ</label>
                    <p class="pshowaddress" >{{selectedObjData.PHO}}</p>
                </div>
                <div class="form-group col-6">
                    <label class="labelshowaddress"> เบอร์สำรอง</label>
                    <p class="pshowaddress" >{{selectedObjData.PHO2}}</p>
                </div>
                <div class="form-group col-6 col-md-3">
                    <label class="labelshowaddress">บ้านเลขที่</label>
                    <p class="pshowaddress" >{{selectedObjData.ADNUM}}</p>
                </div>
                <div class="form-group col-6 col-md-3">
                    <label class="labelshowaddress"> หมู่บ้าน/อาคาร</label>
                    <p class="pshowaddress" >{{selectedObjData.BLD}}</p>
                </div>
                <div class="form-group col-6 col-md-3">
                    <label class="labelshowaddress"> ชั้นที่</label>
                    <p class="pshowaddress" >{{selectedObjData.FLR}}</p>
                </div>
                <div class="form-group col-6 col-md-3">
                    <label class="labelshowaddress"> ห้องที่</label>
                    <p class="pshowaddress" >{{selectedObjData.ROM}}</p>
                </div>
                <div class="form-group col-6 col-md-3">
                    <label class="labelshowaddress"> หมู่ที่</label>
                    <p class="pshowaddress" >{{selectedObjData.MOO}}</p>
                </div>
                <div class="form-group col-6 col-md-3">
                    <label class="labelshowaddress"> ซอย</label>
                    <p  class="pshowaddress" >{{selectedObjData.SOI}}</p>
                </div>
                <div class="form-group col-6 col-md-3">
                    <label class="labelshowaddress"> ถนน</label>
                  <p class="pshowaddress"  >{{selectedObjData.ROAD}}</p>
                </div>
                <div class="form-group col-6 col-md-3">
                    <label class="labelshowaddress">รหัสไปรษณีย์</label>
                    <p class="pshowaddress" >{{selectedObjData.ZIP}}</p>
                </div>
                <div class="form-group col-6 col-md-4">
                    <label class="labelshowaddress">ตำบล</label>
                    <p class="pshowaddress" >{{selectedObjData.TBN}}</p>
                </div>
                <div class="form-group col-6 col-md-4">
                    <label class="labelshowaddress">อำเภอ</label>
                    <p class="pshowaddress" >{{selectedObjData.DST}}</p>
                </div>
                <div class="form-group col-6 col-md-4">
                    <label class="labelshowaddress">จังหวัด</label>
                    <p class="pshowaddress" >{{selectedObjData.PVN}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="col-12">
          <div class="row box-black p-1 m-1">
            <div class="form-group col-12 col-md-6">
                <label > TAX Number:</label>
                <input v-model="taxData.TAXNUM" class="form-control" type="text" name="TAXNUM">
            </div>
            <div class="form-group col-12 col-md-6">
                <label > Branch Number:</label>
                    <input v-model="taxData.BRANCHNUM" class="form-control" type="number" name="BRANCHNUM">
            </div>
            <div class="form-group col-12 ">
                <button @click="findTaxData" :class="`btn-visible-${getBU}`" >ค้นหาที่อยู่ด้วยเลข TAX</button>
            </div>
          </div>
          <div class="row p-1">
            <div class="form-group col-12" style="margin-bottom: 0;margin-top: 10px;">
              <label> ใช้ที่อยู่นี้เป็นค่าเริ่มต้นของ</label>
            </div>
            <div class="form-group col-6">
                <label class="containerb"> ที่อยู่ที่จัดส่ง
                    <input v-model="addFormDisplay.isAddressDlv" type="checkbox" class="varckradio"  name="checkshipadd">
                    <span class="checkmarkbox"></span>
                </label>
            </div>
            <div class="form-group col-6">
                <label class="containerb"> ที่อยู่ใบกำกับภาษี
                    <input v-model="addFormDisplay.isAddressTax" @click="checkRaidoTax" type="checkbox" class="varckradio"   name="checkbilladd">
                    <span class="checkmarkbox"></span>
                </label>
            </div>
            <div class="form-group col-6">
                <label for="nameadd">ชื่อ</label>
                <input v-model="addressFormAddData.name" type="text" class="form-control" name="nameadd"  placeholder="">
            </div>
            <div class="form-group col-6">
                <label for="lnameadd">นามสกุล</label>
                <input v-model="addressFormAddData.lname" type="text" class="form-control" name="lnameadd"  placeholder="">
            </div>
            <div v-if="addressFormAddData.iscom" class="col-12">
              <div class="row">
                <div class="form-group col-6" style="margin-bottom: 10px;margin-top: 10px;">
                    <label class="containerq"> นิติบุคคล
                        <input v-model="addressFormAddData.iscom" type="radio" class="varckradio" name="iscomadd"  value="1" checked>
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="form-group col-6" style="margin-bottom: 10px;margin-top: 10px;">
                    <label class="containerq"> บุคคลธรรมดา
                        <input v-model="addressFormAddData.iscom" type="radio" class="varckradio" name="iscomadd" value="0">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div v-if="addressFormAddData.iscom=='1'" class="col-12">
                  <div class="row">
                    <div class="form-group col-8 col-md-4">
                        <label for="idnumadd"> เลขประจำตัวผู้เสียภาษี</label>
                        <input v-model="addressFormAddData.idnum" type="text" class="form-control" name="idnumadd"  placeholder=""
                             maxlength="13" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" >
                    </div>
                    <div class="form-group col-4 col-md-2">
                        <label  for="idbranchadd">ลำดับที่สาขา</label>
                        <input v-model="addressFormAddData.idbranch" type="text" class="form-control" name="idbranchadd" placeholder=""  maxlength="5">
                    </div>
                    <div class="form-group col-12 col-md-6">
                        <label for="companyadd">บริษัท</label>
                        <input v-model="addressFormAddData.company" type="text" class="form-control" name="companyadd" placeholder=""
                            >
                    </div>
                  </div>
                </div>
                <div v-else class="col-12">
                  <div class="row">
                    <div class="form-group col-12 col-md-4">
                        <label for="idnumadd"> เลขบัตรประจำตัวประชาชน</label>
                        <input v-model="addressFormAddData.idnum" type="text" class="form-control" name="idnumadd" placeholder=""
                             maxlength="13" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                    </div>
                    <div class="form-group col-6 col-md-4">
                        <label for="namenocomadd">ชื่อ</label>
                        <input v-model="addressFormAddData.namenocom" type="text" class="form-control" name="namenocomadd" placeholder=""
                            >
                    </div>
                    <div class="form-group col-6 col-md-4">
                        <label for="lnamenocomadd">นามสกุล</label>
                        <input v-model="addressFormAddData.lnamenocom" type="text" class="form-control" name="lnamenocomadd" placeholder=""
                            >
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="form-group col-6">
                <label for="phoneadd">เบอร์ติดต่อ</label>
                <input v-model="addressFormAddData.phone" type="text" class="form-control" name="phoneadd" placeholder=""
                     maxlength="10">
            </div>
            <div class="form-group col-6">
                <label for="phone2add">เบอร์ติดต่อ (สำรอง)</label>
                <input v-model="addressFormAddData.phone2" type="text" class="form-control" name="phone2add" placeholder=""
                     maxlength="10">
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="adrnumadd">บ้านเลขที่</label>
                <input v-model="addressFormAddData.adrnum" type="text" class="form-control" name="adrnumadd" placeholder=""
                    >
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="buildadd">หมู่บ้าน/อาคาร</label>
                <input v-model="addressFormAddData.build" type="text" class="form-control" name="buildadd" placeholder=""
                    >
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="flooradd">ชั้นที่</label>
                <input v-model="addressFormAddData.floor" type="text" class="form-control" name="flooradd" placeholder=""
                    >
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="roomadd">ห้องที่</label>
                <input v-model="addressFormAddData.room" type="text" class="form-control" name="roomadd" placeholder=""
                    >
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="mooadd">หมู่ที่</label>
                <input v-model="addressFormAddData.moo" type="text" class="form-control" name="mooadd" placeholder=""
                    >
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="soiadd">ซอย</label>
                <input v-model="addressFormAddData.soi" type="text" class="form-control" name="soiadd" placeholder=""
                    >
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="roadadd">ถนน</label>
                <input v-model="addressFormAddData.road" type="text" class="form-control" name="roadadd" placeholder=""
                    >
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="zipcodeadd">รหัสไปรษณีย์</label><span style="margin: 0px;font-size:.9em;color: red;">*</span>
                <input v-model="addressFormAddData.zipcode" :err="validatFormAddData.zipcode" type="text" class="form-control" @keyup="changZipcode(addressFormAddData.zipcode)" name="zipcodeadd" placeholder=""
                     maxlength="5">
            </div>
            <div v-if="addFormDisplay.isShowZip" class="col-12">
                <div class="row">
                  <div class="form-group col-6 col-md-4">
                      <label for="subdistrictadd">ตำบล</label><span style="margin: 0px;font-size:.9em;color: red;">*</span>
                      <select v-model="addressFormAddData.subdistrict" :err="validatFormAddData.subdistrict" name='subdistrictadd' class="form-control">
                          <option v-for="(e, index) in zipcodeData.tbn" :key="index" :value="e">{{e}}</option>
                      </select>
                  </div>
                  <div class="form-group col-6 col-md-4">
                      <label for="districtadd">อำเภอ</label><span style="margin: 0px;font-size:.9em;color: red;">*</span>
                      <select v-model="addressFormAddData.district" :err="validatFormAddData.district" name='districtadd' class="form-control">
                          <option v-for="(e, index) in zipcodeData.dst" :key="index" :value="e">{{e}}</option>
                      </select>
                  </div>
                  <div class="form-group col-6 col-md-4">
                      <label for="provinceadd">จังหวัด</label><span style="margin: 0px;font-size:.9em;color: red;">*</span>
                      <select v-model="addressFormAddData.province" :err="validatFormAddData.province" name="provinceadd" class="form-control">
                          <option v-for="(e, index) in zipcodeData.pvn" :key="index" :value="e">{{e}}</option>
                      </select>
                  </div>
                </div>
            </div>
            <div class="form-group col-6 col-md-6">
                <label for="adrnameadd">โปรดใส่ชื่อของที่อยู่นี้</label>
                <span style="margin: 0px;font-size:.9em;color: red;">*</span>
                <input v-model="addressFormAddData.adrname" type="text" :err="validatFormAddData.adrname" class="form-control" name="adrnameadd" maxlength="20" placeholder="บ้านฉัน,บ้านคุณพ่อ,ออฟฟิศ,..." >
            </div>
            <div class="form-group col-12" style="margin-bottom: 0;margin-top: 10px;">
                <label> ลักษณะที่อยู่อาศัย</label>
            </div>
            <div class="form-group col-6 ">
                <label class="containerq"> บ้านเดี่ยว
                    <input v-model="addressFormAddData.typeofadr" type="radio" class="varckradio" name="typeofadradd" value="ban">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="form-group col-6 ">
                <label class="containerq"> คอนโดฯ
                    <input v-model="addressFormAddData.typeofadr" type="radio" class="varckradio" name="typeofadradd" value="condo">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="form-group col-6 ">
                <label class="containerq"> ทาวน์เฮ้าส์
                    <input v-model="addressFormAddData.typeofadr" type="radio" class="varckradio" name="typeofadradd" value="townho">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="form-group col-6 ">
                <label class="containerq"> อพาทเมนท์
                    <input v-model="addressFormAddData.typeofadr" type="radio" class="varckradio" name="typeofadradd" value="apart">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="form-group col-12 ">
                <div class="row">
                    <div class="col-3 " style="padding-right: 0;">
                        <label class="containerq"> อื่นๆ
                            <input v-model="addressFormAddData.typeofadr" type="radio" class="varckradio" name="typeofadradd" value="other">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-9 " style="padding-left: 0;">
                        <input v-model="addressFormAddData.othertxt" style="border: none;border-bottom: solid 1px #ccc;box-shadow: none;border-radius: 0;" type="text" class="form-control" name="othertxtadd" placeholder=""
                             maxlength="20">
                    </div>
                </div>
            </div>
            <div class="form-group col-12" style="margin-bottom: 0;margin-top: 10px;">
                <label> มีลิฟท์ส่งสินค้าหรือไม่</label>
            </div>
            <div class="form-group col-6">
                <label class="containerq"> มี
                    <input v-model="addressFormAddData.havele" type="radio" class="varckradio" name="haveleadd" value="1">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="form-group col-6">
                <label class="containerq"> ไม่มี
                    <input v-model="addressFormAddData.havele" type="radio" class="varckradio" name="haveleadd" value="0">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="form-group col-12" style="margin-bottom: 0;margin-top: 10px;">
                <label> รถที่สามารถเข้าพื้นที่ได้</label>
            </div>
            <div class="form-group col-12 col-md-6">
                <label class="containerq"> รถ 4 และ 6 ล้อเข้าได้
                    <input v-model="addressFormAddData.can" type="radio" class="varckradio" name="canadd" value="1">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="form-group col-12 col-md-6">
                <label class="containerq"> รถ 4 ล้อเข้าได้
                    <input v-model="addressFormAddData.can" type="radio" class="varckradio" name="canadd" value="2">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="form-group col-12 col-md-6">
                <label class="containerq"> รถ 6 ล้อเข้าได้
                    <input v-model="addressFormAddData.can" type="radio" class="varckradio" name="canadd" value="3">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="form-group col-12 col-md-6">
                <label class="containerq"> รถ 4 และ 6 ล้อเข้าไม่ได้
                    <input v-model="addressFormAddData.can" type="radio" class="varckradio" name="canadd" value="4">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="col-12">
              <button @click="newAddressToDlv" :class="`btn-visible-${getBU}`" >บันทึกที่อยู่ใหม่</button>
            </div>
          </div>
        </div>
      </div>
    </section>

</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';

export default {
  name: 'SelectAddress',
  components: {
  },
  data: function () {
    return {
      listData:[],   
      selectedData:'',
      selectedObjData:{},
   
      isAddAddress:false,
      isChange:false,
      addFormDisplay:{
        isAddressDlv:false,
        isAddressTax:false,
        isCompany:false,
        isShowZip:false
       
      },
      addressFormAddData:{
          adrname:'',
          name: '',
          lname: '',
          iscom: '',
          company: '',
          namenocom: '',
          lnamenocom: '',
          idnum: '',
          idbranch: '',
          phone: '',
          phone2: '',
          adrnum: '',
          build: '',
          floor: '',
          room: '',
          moo: '',
          soi: '',
          road: '',
          subdistrict: '',
          district: '',
          province: '',
          zipcode: '',
          ulat: '',
          ulong: '',
          typeofadr: '',
          othertxt: '',
          havele: '',
          can: '',
          adrstt: '',
          ishq: '',
        },
      validatFormAddData:{
          adrname:false,
          name: false,
          lname: false,
          iscom: false,
          company: false,
          namenocom: false,
          lnamenocom: false,
          idnum: false,
          idbranch: false,
          phone: false,
          phone2: false,
          adrnum: false,
          build: false,
          floor: false,
          room: false,
          moo: false,
          soi: false,
          road: false,
          subdistrict: false,
          district: false,
          province: false,
          zipcode: false,
          ulat: false,
          ulong: false,
          typeofadr: false,
          othertxt: false,
          havele: false,
          can: false,
          adrstt: false,
          ishq: false,
        },
      zipcodeData:{
          tbn:[],
          dst:[],
          pvn:[]
        },
      saveCurrentZipData:[],
      taxData:{
        TAXNUM:'',
        BRANCHNUM:0
      },
      zipChange: false,
      haveAddress: false,
      firstcome:true
    }
  },
  mounted() {
    this.Init()
    
  },
  props: [
    "tax",
    "isTax"
  ],
  methods: {
    async Init(){
      await this.getAddress()
      // if(this.getDSMMSSTORE){
      //   this.selectedData = this.DSBillAddress
      // }
      if(this.haveAddress){
        await this.selectSaveToDlv();
        this.firstcome = false
      }
    },
    async checkRaidoTax(){
      if(!this.addFormDisplay.isAddressTax){
        this.addressFormAddData.iscom = 1;
      }else{
        this.addressFormAddData.iscom = "";
      }
    },
    async findTaxData(){
      try {
        let data = this.taxData
        let getAPI = await serviceAPI.call_API('post', 'checkout/TaxData', data,'auth');
        let getError = getAPI.data.data.errorCode
        if(getError=="1"){
          let getErrorMessage = getAPI.data.data.errorMessage
          throw new Error(getErrorMessage);
        }
        
        let getData = getAPI.data.data.output
        if(getData.NID==""){
          throw new Error("ไม่พบข้อมูล");
        }
        await this.changZipcode(getData.postCode)
        this.addressFormAddData.iscom = "1"
        this.addressFormAddData.idnum = getData.NID
        this.addressFormAddData.idbranch = getData.branchNumber
        this.addressFormAddData.build = getData.buildingName
        
        this.addressFormAddData.floor = getData.floorNumber
        this.addressFormAddData.adrnum = getData.houseNumber
        this.addressFormAddData.moo = getData.mooNumber
        this.addressFormAddData.company = `${getData.titleName} ${getData.name} ${getData.branchName}`
        this.addressFormAddData.adrname = `${getData.titleName} ${getData.name} ${getData.branchName}`
        this.addressFormAddData.zipcode = getData.postCode
        
        this.addressFormAddData.room = getData.roomNumber
        this.addressFormAddData.soi = getData.soiName
        this.addressFormAddData.road = getData.streetName

        
        let getTAMBON = [];
        let getDISTRICT = [];
        let getPROVINCE = [];
        getTAMBON.push(getData.subDistrictName)
        getDISTRICT.push(getData.districtName)
        getPROVINCE.push(getData.provinceName)
        this.zipcodeData={
            tbn:getTAMBON,
            dst:getDISTRICT,
            pvn:getPROVINCE
          }
        this.addFormDisplay.isShowZip=true;
        this.addressFormAddData.subdistrict = getData.subDistrictName
        this.addressFormAddData.district = getData.districtName
        this.addressFormAddData.province = getData.provinceName
        // {
        //   adrname:'',
        //   name: '',
        //   lname: '',
        //   iscom: '',
        //   company: '',
        //   namenocom: '',
        //   lnamenocom: '',
        //   idnum: '',
        //   idbranch: '',
        //   phone: '',
        //   phone2: '',
        //   adrnum: '',
        //   build: '',
        //   floor: '',
        //   room: '',
        //   moo: '',
        //   soi: '',
        //   road: '',
        //   subdistrict: '',
        //   district: '',
        //   province: '',
        //   zipcode: '',
        //   ulat: '',
        //   ulong: '',
        //   typeofadr: '',
        //   othertxt: '',
        //   havele: '',
        //   can: '',
        //   adrstt: '',
        // }
        // {
        //   "errorMessage": "",
        //   "errorCode": "0",
        //   "systemDateTime": "",
        //   "output": {
        //     "NID": "0105539004352",
        //     "TIN": "",
        //     "titleName": "บริษัท",
        //     "name": "เพาเวอร์บาย จำกัด",
        //     "surName": "-",
        //     "branchTitleName": "",
        //     "branchName": "สำนักงานใหญ่",
        //     "branchNumber": "0",
        //     "buildingName": "เซาท์ทาวเวอร์",
        //     "floorNumber": "11",
        //     "villageName": "-",
        //     "roomNumber": "-",
        //     "houseNumber": "919/555",
        //     "mooNumber": "-",
        //     "soiName": "-",
        //     "streetName": "สีลม",
        //     "subDistrictName": "สีลม",
        //     "districtName": "บางรัก",
        //     "provinceName": "กรุงเทพมหานคร",
        //     "postCode": "10500"
        //   }
        // }
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async getAddress(){
      try {
        let data = {
          VIPID:this.getQTCUSTINFO.VIPID
        };
        let getAPI = await serviceAPI.call_API('post', 'checkout/getMultiDlv', data,'auth');
        if(getAPI.data.dbrows==0){
          // this.isAddAddress=true;
          this.haveAddress = false
          // throw new Error("คุณยังไม่มีที่อยู่กรุณาเพิ่มที่อยู่ใหม่ด้วยครับ")
          if(this.$store.getters['auth/getISTB']){
            return
          }
          let msg = ''
          msg = '<h2>คุณยังไม่มีที่อยู่กรุณาเพิ่มที่อยู่ใหม่ด้วยครับ</h2>'
          this.$swal.fire({
              html: msg,
              showCancelButton: true,
              confirmButtonColor: this.getBuColor,
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: 'ไปหน้าจัดการที่อยู่',
              denyButtonText: 'ยกเลิก',
          }).then(async (result) => {
              if (result.isConfirmed) {
                // console.log('CheckOut');
                this.$router.push({ name: 'Address' });
              }
          }).catch((error) => {
              serviceMain.showErrorAlert(this, error)
          });
          return
        }
        this.listData = getAPI.data.dbitems;
        // console.log(this.listData);
        if(this.tax){
          let billAddress = this.listData.filter(e => ['all', 'bill'].includes(e.STT))
          if(billAddress.length == 0){
            // this.isAddAddress=true;
            this.haveAddress = false
            // throw new Error("คุณยังไม่มีที่อยู่ใบกำกับภาษีกรุณาเพิ่มที่อยู่ใหม่ด้วยครับ")
            let msg = ''
            msg = '<h2>คุณยังไม่มีที่อยู่ใบกำกับภาษีกรุณาเพิ่มที่อยู่ใหม่ด้วยครับ</h2>'
            this.$swal.fire({
                html: msg,
                showCancelButton: true,
                confirmButtonColor: this.getBuColor,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'ไปหน้าจัดการที่อยู่',
                denyButtonText: 'ยกเลิก',
            }).then(async (result) => {
                if (result.isConfirmed) {
                  // console.log('CheckOut');
                  this.$router.push({ name: 'Address' });
                }
            }).catch((error) => {
                serviceMain.showErrorAlert(this, error)
            });
            return
          }
          this.selectedObjData = billAddress[0];
          this.addFormDisplay.isAddressTax = true;
          this.addressFormAddData.iscom = 1;
        }else{
          let dlvAddress = this.listData.filter(e => ['all', 'ship'].includes(e.STT))
          if(dlvAddress.length == 0){
            // this.isAddAddress=true;
            this.haveAddress = false
            // throw new Error("คุณยังไม่มีที่อยู่กรุณาเพิ่มที่อยู่ใหม่ด้วยครับ")
            let msg = ''
            msg = '<h2>คุณยังไม่มีที่อยู่กรุณาเพิ่มที่อยู่ใหม่ด้วยครับ</h2>'
            this.$swal.fire({
                html: msg,
                showCancelButton: true,
                confirmButtonColor: this.getBuColor,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'ไปหน้าจัดการที่อยู่',
                denyButtonText: 'ยกเลิก',
            }).then(async (result) => {
                if (result.isConfirmed) {
                  // console.log('CheckOut');
                  this.$router.push({ name: 'Address' });
                }
            }).catch((error) => {
                serviceMain.showErrorAlert(this, error)
            });
            return
          }
          this.selectedObjData = dlvAddress[0];
          this.addFormDisplay.isAddressDlv = true;
        }
        this.selectedData = this.selectedObjData.ANM;
        this.AddNewAddress = this.selectedObjData.ANM;
        this.haveAddress = true
        this.$emit('saveADD',this.selectedObjData)
        this.$emit('headerQT',this.selectedObjData)
        
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }

    },
    async selectOnChange(){
      let selectedObj = this.listData.find(e =>e.ANM == this.selectedData)
      this.selectedObjData = selectedObj
      this.selectedData = selectedObj.ANM;
    },
    async changZipcode(para_zip){
      this.zipChange = true;
      try {
        if(para_zip.length==5){

          let data={
            ZIPCODE:para_zip
          }
          let getAPI = await serviceAPI.call_API('post',`checkout/getAddrbyZipcode`,data,'auth');
          if(getAPI.data.dbrows==0){
            throw new Error("ไม่พบรหัสไปรษณีย์ครับ")
          }
          let getData = getAPI.data.dbitems;
          this.saveCurrentZipData = getData;
          let getTAMBON = [];
          let getDISTRICT = [];
          let getPROVINCE = [];
          let oldTAMBON = null;
          let oldDISTRICT = null;
          let oldPROVINCE = null;
          for(let e of getData){
            if (e.TAMBON != oldTAMBON) {
              getTAMBON.push(e.TAMBON.trim())
              oldTAMBON = e.TAMBON.trim();
            }
            if (e.DISTRICT != oldDISTRICT) {
              getDISTRICT.push(e.DISTRICT.trim())
              oldDISTRICT = e.DISTRICT.trim();
            }
            if (e.PROVINCE != oldPROVINCE) {
              getPROVINCE.push(e.PROVINCE.trim())
              oldPROVINCE = e.PROVINCE.trim();
            }
          }
          
          this.zipcodeData={
            tbn:getTAMBON,
            dst:getDISTRICT,
            pvn:getPROVINCE
          }
          this.addressFormAddData.subdistrict = this.zipcodeData.tbn[0]
          this.addressFormAddData.district = this.zipcodeData.dst[0]
          this.addressFormAddData.province = this.zipcodeData.pvn[0]
          this.addFormDisplay.isShowZip=true;
        }else{
          this.addFormDisplay.isShowZip=false;
          this.zipcodeData={
            tbn:[],
            dst:[],
            pvn:[]
          }
        }
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    async selectSaveToDlv(){
     try {
        if(this.tax){
          if (this.selectedObjData.STT == "bill") {
            this.selectedObjData.STT = "bill";
          } else if (this.selectedObjData.STT == "all") {
            this.selectedObjData.STT = "all";
          } else if (this.selectedObjData.STT == "ship") {
            this.selectedObjData.STT = "all";
          } else {
            this.selectedObjData.STT = "bill";
          }
        }else{
          if (this.selectedObjData.STT == "ship") {
            this.selectedObjData.STT = "ship";
          } else if (this.selectedObjData.STT == "all") {
            this.selectedObjData.STT = "all";
          } else if (this.selectedObjData.STT == "bill") {
            this.selectedObjData.STT = "all";
          } else {
            this.selectedObjData.STT = "ship";
          }
        }
        let JSON_DLV={
          adrname:this.selectedObjData.ANM,
          name: this.selectedObjData.NME,
          lname: this.selectedObjData.LNME,
          iscom: this.selectedObjData.ISCOM,
          company: this.selectedObjData.COM,
          namenocom: this.selectedObjData.NNC,
          lnamenocom: this.selectedObjData.LNNC,
          idnum: this.selectedObjData.IDN,
          idbranch: this.selectedObjData.IDB,
          phone: this.selectedObjData.PHO,
          phone2: this.selectedObjData.PHO2,
          adrnum: this.selectedObjData.ADNUM,
          build: this.selectedObjData.BLD,
          floor: this.selectedObjData.FLR,
          room: this.selectedObjData.ROM,
          moo: this.selectedObjData.MOO,
          soi: this.selectedObjData.SOI,
          road: this.selectedObjData.ROAD,
          subdistrict: this.selectedObjData.TBN,
          district: this.selectedObjData.DST,
          province: this.selectedObjData.PVN,
          zipcode: this.selectedObjData.ZIP,
          ulat: this.selectedObjData.LAT,
          ulong: this.selectedObjData.LNG,
          typeofadr: this.selectedObjData.TOA,
          othertxt: this.selectedObjData.OTT,
          havele: this.selectedObjData.HVEL,
          can: this.selectedObjData.CAN,
          adrstt: this.selectedObjData.STT,
          ishq: this.selectedObjData.ISHQ,
        }
        let data={
          MODE:'E', 
          VIPID:this.getQTCUSTINFO.VIPID ,
          ADDRESS_JSON:JSON_DLV, 
          REF:this.CartRef
        }
        let getAPI = await serviceAPI.call_API('post',`checkout/SaveMultipleDlv`,data,'auth');
        this.$emit('saveADD',this.selectedObjData)
        if(!this.tax && !this.firstcome){
          this.$store.dispatch('service/setChangeAddress')
        }
        serviceMain.showSuccessAlertWithTime(this,`เปลี่ยนเป็นที่อยู่ ${this.selectedObjData.ANM} แล้ว`,1200)
        this.isChange = false;
        
     } catch (error) {
       serviceMain.showErrorAlert(this,error)
     }
      
    },
    async newAddressToDlv(){
      try{
        
        
        let ListError = new Error("กรอกข้อมูลไม่ครบถ้วน")
        ListError.validation=[]
        if(this.addressFormAddData.zipcode==''){   
          ListError.validation.push({msg:"กรุณากรอกรหัสไปรษณีย์"}) 
          this.validatFormAddData.zipcode = true;
        }
        if(this.addressFormAddData.subdistrict==''){   
          ListError.validation.push({msg:"กรุณากรอกตำบล"}) 
          this.validatFormAddData.subdistrict = true;
        }
        if(this.addressFormAddData.district==''){   
          ListError.validation.push({msg:"กรุณากรอกอำเภอ"}) 
          this.validatFormAddData.district = true;
        }
        if(this.addressFormAddData.province==''){   
          ListError.validation.push({msg:"กรุณากรอกจังหวัด"}) 
          this.validatFormAddData.province = true;
        }
        if(this.addressFormAddData.adrname==''){   
          ListError.validation.push({msg:"กรุณากรอกชื่อที่อยู่"}) 
          this.validatFormAddData.adrname = true;
        }
        if(this.addressFormAddData.idnum.length!=13 && this.addressFormAddData.idnum!=''){   
          ListError.validation.push({msg:"กรุณากรอกเลขประจำตัวผู้เสียภาษี/เลขบัตรประจำตัวประชาชนให้ครบ13หลัก"}) 
          this.validatFormAddData.idnum = true;
        }
        if(ListError.validation.length!=0){
          throw ListError
        }
        if(this.addFormDisplay.isAddressDlv && this.addFormDisplay.isAddressTax){
          this.addressFormAddData.adrstt='all'
        }else{
          if(this.addFormDisplay.isAddressDlv){
            this.addressFormAddData.adrstt='ship'
          }
          if(this.addFormDisplay.isAddressTax){
            this.addressFormAddData.adrstt='bill'
          }
        }
        if(this.zipChange == false){
          this.changZipcode(this.addressFormAddData.zipcode)
        }
        await serviceMain.Delay(1000);
        let getDataLatLng = this.saveCurrentZipData.find(e => e.TAMBON.trim() == this.addressFormAddData.subdistrict)
        this.addressFormAddData.ulat=getDataLatLng.LAT
        this.addressFormAddData.ulong=getDataLatLng.LNG
        let data={
          MODE:'N', 
          VIPID:this.getQTCUSTINFO.VIPID ,
          ADDRESS_JSON:this.addressFormAddData, 
          REF:this.CartRef
        }
        let getAPI = await serviceAPI.call_API('post',`checkout/SaveMultipleDlv`,data,'auth');
        this.$store.dispatch('service/setChangeAddress')
        serviceMain.showSuccessAlertWithTime(this,`เพิ่มที่อยู่ใหม่ ${this.addressFormAddData.adrname} แล้ว`,1200)
        this.isChange = false;
        this.isAddAddress = false;
       
        this.refreshAddressForm()
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }

    },
    refreshAddressForm(){
      this.addressFormAddData={
          adrname:'',
          name: '',
          lname: '',
          iscom: '',
          company: '',
          namenocom: '',
          lnamenocom: '',
          idnum: '',
          idbranch: '',
          phone: '',
          phone2: '',
          adrnum: '',
          build: '',
          floor: '',
          room: '',
          moo: '',
          soi: '',
          road: '',
          subdistrict: '',
          district: '',
          province: '',
          zipcode: '',
          ulat: '',
          ulong: '',
          typeofadr: '',
          othertxt: '',
          havele: '',
          can: '',
          adrstt: '',
          ishq:'',
        }
      this.validatFormAddData={
          adrname:false,
          name: false,
          lname: false,
          iscom: false,
          company: false,
          namenocom: false,
          lnamenocom: false,
          idnum: false,
          idbranch: false,
          phone: false,
          phone2: false,
          adrnum: false,
          build: false,
          floor: false,
          room: false,
          moo: false,
          soi: false,
          road: false,
          subdistrict: false,
          district: false,
          province: false,
          zipcode: false,
          ulat: false,
          ulong: false,
          typeofadr: false,
          othertxt: false,
          havele: false,
          can: false,
          adrstt: false,
          ishq: false,
        }
    }
  },
  computed: {
    getBU:function(){
      return this.$store.getters['customer/getQTBU']
    },
    getQTCUSTINFO:function(){
      return this.$store.getters['customer/getQTCUSTINFO']
    },
    CartRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    ChangeAddress:function(){
      return this.$store.getters['service/getChangeAddress']
    },
    listShip:function(){
      // let listDataShip = [];
      // if(!this.tax){
      //   listDataShip = this.listData
      // }else{
      //   for (let i = 0; i < this.listData.length; i++) {
      //     if(this.listData[i].IDN != ''){
      //       listDataShip.push(this.listData[i])
      //     }
      //   }
      // }
      return this.listData.filter(e => (!this.tax ? ['all', 'ship', 'bill', ''] : ['all', 'bill']).includes(e.STT));
    },
    AddNewAddress: {
      get () {
        return this.$store.getters['service/getAddNewAddress']
      }, 
      set (value) {
        this.$store.dispatch('service/setAddNewAddress', value)
      }
    },
    getDSMMSSTORE:function(){
      return this.$store.getters['service/getDSMMSSTORE']
    },
    DSBillAddress:function(){
      return this.$store.getters['service/getDSBillAddress']
    },
  },
  watch:{
    ChangeAddress:function(){
      this.getAddress();
    },
  }
  
}

</script>

<style scoped>
.labelshowaddress {
  font-family: 'kittithadabold75regular';
}

.pshowaddress {
  border-bottom: 1px solid gray;
  height: 32px;
}



.form-group {
  margin-bottom: 5px;
}

label {
  margin-bottom: 0px;
  color: rgb(125, 125, 125);
  font-size: .9em;
}
.card{
  background-color: white;
  border:none;

}
.form-control {
  height: 1.5rem;
  padding: 2px 5px;
}
.select-address{
  padding:0px 15px; 
  font-family: 'kittithadabold75regular'; 
  color:black; 
  background-color: rgb(241, 241, 241);
  border-radius: 25px; 
}
.containerq {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.containerq input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: #999999;
}


.containerq:hover input~.checkmark {
    background-color: #ccc;
}


.containerq input:checked~.checkmark {
    background-color: white;
    border-style: solid;
    border-color: #55246c;
}


.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


.containerq input:checked~.checkmark:after {
    display: block;
}


.containerq .checkmark:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #55246c;

}
.varckradio{
  float:left;
}
input[err="true"]{
  border: 2px red solid !important;
}
select[err="true"]{
  border: 2px red solid !important;
}
.box-black{
  border:2px black solid !important;
  border-radius:8px;
}
.containerb {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: rgb(255, 255, 255);
  border-style: solid;
  border-color: #999999;
}

/* On mouse-over, add a grey background color */
.containerb:hover input ~ .checkmarkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerb input:checked ~ .checkmarkbox {
  background-color: #ffffff;
  border-style: solid;
  border-color: #55246c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerb input:checked ~ .checkmarkbox:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerb .checkmarkbox:after {
  left: 8px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #55246c;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
