<template>
  <section>
    <div class='slotbooking-div' :key="itmTran">
      <div  class="row">
        <div class="col-12 p-0">
          <div class='cardWhite'>
            <div class="row pointer" @click="showDivDetail(itmTran,slotNum+1)">
              <span class='fnt-header' style="font-size:1.3rem;">แพคเกจที่ {{ slotNum+1 }}</span>
              <!-- <div class="px-1 px-md-2 text-right text-md-center d-inline-flex d-md-inline my-auto ml-auto ml-md-0" style="line-height: 1em;">
                <div>จำนวน<span class="d-none d-md-inline">สินค้า</span></div>
                <div class="f-kitti75 px-1" style="text-decoration: underline;width: 90px;">{{ itmTran.sumqnt }} รายการ</div>
              </div> -->
              <div class="px-1 px-md-2 text-right text-md-center d-flex my-auto py-2 ml-auto ml-md-0">
                <span class="mx-1 border"><ImageBySKU  style="width:100%;max-width:60px;" :sku="itmTran.SKC" /></span>
              </div>
              <div class="d-inline d-md-none w-100"></div>
              <div class="row">
                <div class="col-12 text-left">{{itmTran.PRNAME}} - ({{itmTran.SKC}})</div>
                <div class="col-12"><span class="text-left">{{itmTran.QNT}} {{itmTran.UNAME}}</span><span class="text-right">   รวม {{itmTran.AMTSHOW}}</span></div>
              </div>
              <!-- <div class="px-1 px-md-2 text-right my-auto ml-auto d-none d-md-inline">
                <div style="font-size: 20px;">ได้รับสินค้าตามวันที่เลือก</div>
              </div> -->
            </div>
            <div v-show="false">
              <SelectStoreDLV v-show="false" ref="SelectStoreRef" :pick="'Y'" :trfrom ="getStore" @selectedStore="selectedStore"/>
            </div>
            <div>
              <PickupStore ref="PickupStore" :trfrom ="itmTran.trfrom" @selectedStore="selectedStore"/>
            </div>
            <select class='form-control' @change='chgSelTrType();' :key='dlvTrType' v-model='dlvTrType'>
              <option value=''>กรุณาเลือกประเภทการจัดส่ง</option>
              <optgroup label="รับสินค้า" >
                <option :value='dlvTpeOpt.PICKNOW' v-show="!isUnusePickupNow" >รับของทันที</option>
                <option :value='dlvTpeOpt.PICK'>รับที่ ไทวัสดุ สาขา {{!$store.getters['auth/getISTB'] ? getQTSTORENAME : $store.getters['customer/getEPCUSTINFO'].STNAME}}</option>
              </optgroup>
                <!-- <option v-show="!isXmas && getQTBOOKING" :value='dlvTpeOpt.COM'>จับคู่แพคเกจ(ไม่สามารถเลือกแพคเกจที่เลือกจับคู่กับแพคเกจอื่นได้)</option>
                <option v-show="!isXmas" :value='dlvTpeOpt.KER'>Kerry</option>
                <option v-show="!isXmas" :value='dlvTpeOpt.PICK'>PickUp</option>
                <option v-show="!isXmas || (isXmas && getBU == 'bnb')" :value='dlvTpeOpt.DIY'>DIY</option>
                <option v-show="!isXmas" :value='dlvTpeOpt.CON'>CON</option>
                <option v-show="isXmas && getBU != 'bnb'" :value='dlvTpeOpt.CRI'>CRI</option>
                <option v-show="!isXmas && getQTBOOKING" :value='dlvTpeOpt.VEN'>VEN</option>
                <option v-show="!isXmas" :value='dlvTpeOpt.SER'>SER</option> -->
            </select><br>
            <div class="row" v-if='[dlvTpeOpt.PICK].includes(dlvTrType)'>
                <!-- <div class="col-12">
                  <input placeholder="เลือกวันรับสินค้าของท่าน" type='date'  id="dattxt" autocomplete="off" class="form-control" v-model="dateVEN" :min="minDateVen">
                </div> -->
                <Pickupbyseq @pickupItems="pickupItems" :slotNum='`${slotNum}`' :trsend="trsend" :StoreData="StoreData"/>
                <!-- <div class="col-12 col-md-6">
                  <input placeholder="เลือกเวลารส่งสินค้าของท่าน" autocomplete="off" type='text' id="tmetxt" class="form-control timepicker" v-model="storeTimePick"/>
                </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="DlvDetail" size="60">
      <template v-slot:header>
        <p class="text-left pl-2">แพคเกจที่ {{ slotNum+1 }}</p>
      </template>
      <template v-slot:body >
        <div v-if="itmTran" :key="itmTran">
          <div class="row">
            <div class="col-2"><ImageBySKU  style="width:100%;max-width:60px;" :sku="itmTran.SKC" /></div>
            <div class="col-8">
              <div class="row">
                <div class="col-12 text-left">{{itmTran.PRNAME}} - ({{itmTran.SKC}})</div>
                <div class="col-12 text-left">{{itmTran.QNT}} {{itmTran.UNAME}}</div>
              </div>
            </div>
            <div class="col-2"> รวม {{itmTran.AMTSHOW}}</div>
          </div>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import * as serviceAPI from "@/services/API.service";
import * as serviceMain from "@/services/main.service";
import TimeSlot from "@/components/CheckOut/SlotBooking/TimeSlot.vue";
import SlotBookingSkeleton from "@/components/CheckOut/SlotBooking/SlotBookingSkeleton.vue";
import ImageBySKU from '@/components/ImageBySKU.vue';
import Modal from '@/components/Modal.vue';
import Pickupbyseq from "@/components/CheckOut/SlotBooking/Pickupbyseq.vue";
import SelectStoreDLV from "@/components/CheckOut/SlotBooking/SelectStoreDLV.vue";
import PickupStore from "@/components/CheckOut/SlotBooking/PickupStore.vue";

export default {
  name: "SlotPickup",
  components: {
    TimeSlot,
    SlotBookingSkeleton,
    ImageBySKU,
    Modal,
    Pickupbyseq,
    SelectStoreDLV,
    PickupStore
  },
  data: function() {
    return {
      dlvTpeOpt: {
        KER: 'KER',
        DIY: 'DIY',
        CON: 'CON',
        CRI: 'CRI',
        VEN: 'VEN',
        SER: 'SER',
        COM: 'COM',
        PICK:'PICK',
        PICKNOW:'PICKNOW',
      },
      dlvTrType: '',
      bookingSlots: [],
      masterTimeSlots: [],
      flee: 0,
      startDateBooking: '',
      errSaveTrans: false,
      errStoreBooking: false,
      deliDaysOfWeek: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
      deliMonthThai: ["ม.ค.","ก.พ.","มี.ค.","ม.ษ.","พ.ค.","มิ.ย.","ก.ค.","ส.ค.","ก.ย.","ต.ค.","พ.ย.","ธ.ค."],
      lastSlotDate: '',
      firstSlotDate: '',
      isShowSlot: false,
      isShowDesc: false,
      isShowNextPrev: false,
      isLoading: true,
      skuChrismas: ['60284074','60284075','60300086','60300087','60330707','60353328'],
      getBookingItms:{},
      bookingId:'',
      dateVEN: new Date().toISOString().substr(0, 10),
      minDateVen: new Date().toISOString().substr(0, 10),
      storeTimePick:'',
      pickPackage:'',
      getPickUpDate:{Date:'',
                     Time:''},
      isXmas: false,
      storeUnusePickupNow: ['UL', 'UC'],
      storeUnusePickup: [],
      isUnusePickupNow: false,
      trsend:'',
      StoreData : '',
    }
  },
  props:[
    "orderItems",
    "itmTran",
    "slotNum",
    "getPickPackage",
    "getDlvTrType",
    "changePick",
    "canShowSlot"
  ],
  mounted() {
    this.$emit("dlvType", [false,this.slotNum]);
    this.dlvTrType = this.QTDLVTRTYPE;
    this.isUnusePickupNow = this.storeUnusePickupNow.includes(this.getQTSALESOURCE) || this.storeUnusePickupNow.includes(this.getStore);
    // console.log(!this.dlvTrType);
    // if(!this.dlvTrType) return;
          
    // this.flee = this.DeliveryFee
    // this.reloadSlotTimes();
    this.trsend = this.getStore 
    
  },
  methods: {
    async chgSelTrType () {
      
      // this.dlvTrType = event.target.value;
      this.QTDLVTRTYPE = this.dlvTrType
      this.isShowSlot = false;
      this.isShowDesc = false;
      this.isShowNextPrev = false;
      if(!this.dlvTrType) return;
      this.$emit("fndlvTrType", [this.dlvTrType,this.slotNum]);
      this.getPickUpDate = {Date:'',
                            Time:'',
                            trsend:this.trsend}
      this.$emit("fnlistPickDate", [this.getPickUpDate,this.slotNum]);
      if(this.dlvTrType == this.dlvTpeOpt.PICKNOW){
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        let hours = today.getHours().toString(); // => 9
        let min = today.getMinutes().toString(); // =>  30
        hours = hours.length > 1 ? hours : '0'+hours;
        min = min.length > 1 ? min : '0'+min;
        // if(hours < 23){
        //   if(min <= 30){
        //     min = 30
        //   } else if (min > 30){
        //     min = 0
        //     hours = hours + 1
        //   }
        // }
        if(yyyy > 2500){
          yyyy = yyyy - 543
        }
        let timenow = hours + ':' + min;
        today = dd + '/' + mm + '/' + yyyy;
        this.getPickUpDate = {Date:today,
                              Time:timenow,
                              trsend:this.trsend}
        // console.log(this.getPickUpDate);
        this.$emit("fnlistPickDate", [this.getPickUpDate,this.slotNum]);
      }
    },
    async openModal() {
      try {
        this.$refs.DlvDetail.showmodal()
      } catch (error) {
        serviceMain.showErrorAlert(this, error.message)
      }
    },
    async hideModal() {
      try {
        this.$refs.DlvDetail.hidemodal()
      } catch (error) {
        serviceMain.showErrorAlert(this, error.message)
      }
    },

    showDivDetail(e,index){
      this.dlvDetail = e;
      this.dlvDetailIndex = index+1;
      this.openModal()
    },

    async pickupItems(val) {
      console.log('pickmain');
      this.getPickUpDate = val[0];
      this.getPickUpDate.trsend = this.trsend;
      // console.log(this.getPickUpDate);
      this.$emit("fnlistPickDate", [this.getPickUpDate,this.slotNum]);
      // this.getBookingItms[val[1]] = val[0];
      // this.$refs.DetailCartRef.showListCart()
    },

    async selectedStore(val){
      this.trsend = val
      this.dlvTrType = '';
      this.StoreData = await this.$refs.SelectStoreRef.selectStoreData()
      this.chgSelTrType();
    },
  },
  computed: {
    getQTCUSTREF: function () {
      return this.$store.getters["customer/getQTCUSTREF"];
    },
    getQTCUSTINFO: function () {
      return this.$store.getters["customer/getQTCUSTINFO"];
    },
    getQTBOOKING: function () {
      return this.$store.getters["auth/getQTBOOKING"];
    },
    getBU: function() {
      return this.$store.getters['customer/getQTBU'];
    },
    getBuColor:function(){
      let color = '';
      switch(this.getBU) {
        case 'bnb':
          color = '#006e63';
        break;
        case 'at1':
          color = '#3866af';
        break;
        default:
          color = '#55246c';
      }
      return color;
    },
    getStore: function () {
      return this.$store.getters["service/getQTDFSTORE"];
    },
    QTDLVTRTYPE: {
      get () {
        return this.$store.getters['customer/getQTDLVTRTYPE'] || ''
      },
      set (value) {
        this.$store.dispatch('customer/setQTDLVTRTYPE',value)
      }
    },
    // getBookingItms: function () {
    //   return this.$store.getters["service/getBookingItms"];
    // },
    getQTCHKP: function () {
      return this.$store.getters["service/getQTCHKP"];
    },
    getQTSALESOURCE: function () {
      return this.$store.getters["auth/getQTSALESOURCE"];
    },
    DeliveryFee: {
      get () {
        return this.$store.getters['service/getDeliveryFee']
      }, 
      set (value) {
        this.$store.dispatch('service/setDeliveryFee', value)
      }
    },
    WaitBooking: {
      get () {
        return this.$store.getters['service/getWaitBooking']
      }, 
      set (value) {
        this.$store.dispatch('service/setWaitBooking', value)
      }
    },
    dlvType: function() {
      if(this.dlvTrType){
        return true
      }
      return false
    },
    priceDlv: {
      get () {
        return this.$store.getters['service/getQTPDLV']
      },
      set (value) {
        this.$store.dispatch('service/setQTPDLV',value)
      }
    },
    getQTSTORENAME:function(){
      return this.$store.getters['service/getQTSTORENAME']
    },
  },
  watch: {
    getStore: function () {
      this.QTDLVTRTYPE = '';
      this.clearSlotTime();
      // this.reloadSlotTimes();
    },
    getQTCUSTREF: function (){
      this.QTDLVTRTYPE = ''
    },
    DeliveryFee: function() {
      this.isShowDesc = false
      this.flee = this.DeliveryFee
      this.isShowDesc = this.flee > 0
    },
    getQTCHKP: function() {
      if(this.getQTCHKP == 'Pickup')
        this.$emit("dlvType", [false,this.slotNum]);
    },
    changePick: function() {
      // if(this.getDlvTrType == this.dlvTpeOpt.COM || this.getDlvTrType == this.dlvTpeOpt.SER){
      //   this.dlvTrType = this.dlvTpeOpt.COM;
      //   this.pickPackage = this.getPickPackage

      // } else if (this.getDlvTrType == this.dlvTpeOpt.DIY || this.getDlvTrType == this.dlvTpeOpt.CON ){
      //   this.dlvTrType = this.getDlvTrType;
      //   this.pickPackage = this.getPickPackage
      //   this.chgSelTrType();
      // }
      this.dlvTrType = this.getDlvTrType;
      if (!(this.getDlvTrType == this.dlvTpeOpt.DIY || this.getDlvTrType == this.dlvTpeOpt.CON)){
        this.pickPackage = this.getPickPackage
      }
      this.chgSelTrType();
    },
    changePickQTBOOKING: function() {
      this.dlvTrType = this.getDlvTrType;
      this.pickPackage = ''
      this.chgSelTrType();
    }
  }
}
</script>

<style scoped>
  .fnt-header {
    font-family: 'kittithadabold75regular';
  }

  .cardWhite{
    margin: 0px;
    background-color: white;
    padding:20px 20px;
    border-radius: 7px;
    height: auto;
    margin-bottom: 0px;
    min-height: auto;
  }
  
  .form-control {
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    border-bottom: .5px solid #ccc;
    box-shadow: none;
    border-radius: 0px;
    font-weight: bolder;
  }
</style>