<template>
  <section>
    <div class="p-0 m-0" v-show="!WaitBooking">
    <div class="row mx-0 mt-3">
      <h4 class='font-weight-bold m-0'>Check Out</h4>
    </div>
    <div class="row mx-0 mb-3">
      <div class="col-12 col-md-7">
      
      </div>
    <div class="col-12 col-md-5 pr-0">
      <span class="h5 font-kitti-bold m-0" style="float:left;">Order Item {{getRef}}<span v-if="getCopyRef" class="h5 font-kitti-bold m-0" style="float:left;"> ( {{getCopyRef}} )</span></span>
      <span v-if="getCreditBalance" class="h6 font-kitti-bold m-0" style="float:left;">CreditBalance {{getCreditBalance}}</span>
      <!-- <ButtonCartGrouping /> -->
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12 col-md-7">
          <EditUserInfoForm v-if="!$store.getters['auth/getISTB']" :dataEditProps="dataUser"  @confirmEdit="confirmEdit" :mode="'S'"/>
          <Promotion101 v-show="false" v-if="!$store.getters['auth/getISTB'] && (!$store.getters['auth/getWSFLAG'] || ( $store.getters['auth/getWSFLAG'] && $store.getters['service/getWSplpp']))"/>
          <DisMktCode v-show="!isLoading" v-if="!$store.getters['auth/getISTB'] && (!$store.getters['auth/getWSFLAG'] || ( $store.getters['auth/getWSFLAG'] && $store.getters['service/getWSdiscountBill']))"/>
          <SelectPick v-if="!$store.getters['auth/getISTB']"/>
          <!-- <div v-if="getQTCHKP=='Pickup'" class="row">
            <div class="col-12">
              <PickupatStore />
            </div>
          </div> -->
          <div v-show="!$store.getters['auth/getISTB']"  class="row mt-3">
            <div class="col-12">
              <SelectAddress ref="SelectAddressDlvRef" @saveADD="saveADD"/>
            </div>
          </div>
          <div class="row my-2" v-show="!$store.getters['auth/getISTB']">
            <div class="col-12 pt-2">
                <span style="font-family: 'kittithadabold75regular'; margin-right:15px;">คุณต้องการใบกำกับภาษีหรือไม่</span>
                <label class="switchter">
                  <input v-model="isTax" type="checkbox">
                  <span class="sliderter roundter"></span>
                </label>
            </div>
            <div class="col-12">
                <SmsandEmailButton v-if='getQTETAX == 1 || (getQTETAX == 2 && isTax)' :isTax="isTax" :dataDisplayText="dataUser" @setSendBill="setSendBill" @setInvoiceType="setInvoiceType"/>
            </div>
          </div>
          <div v-if="isTax" class="row">
            <div class="col-12">
              <SelectAddress ref="SelectAddressTaxRef" tax="true" @headerQT="headerQT"/>
            </div>
            <br>
          </div>
          <div v-show="!isLoading" v-if="!isLoading" :key="ChangeAddress">
            <div :key="getStore">
              <div class="row">
                <div class="col-12">
                  <EditPriceDlv v-show="(!(CHKP == 'PICK' || CHKP == 'PICKNOW')) && getQTSALESOURCE != 'U1'&& !$store.getters['auth/getISTB']"/>
                  <!-- <SlotBooking ref="SlotBookingRef" :orderItems="orderItems"/> -->
                  <SlotBookingVfix v-if="!$store.getters['auth/getISTB']" ref="SlotBookingRef" :orderItems="orderItems" :canReload="canReload" :dataAddress="dataAddress" @confirmCHKP="confirmCHKP" @fnlistdlvTrType="fnlistdlvTrType"/>
                  <SlotBookingB2B v-if="$store.getters['auth/getISTB']" ref="SlotBookingRefB2B" :orderItems="orderItems" :dataAddress="dataAddress"/>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="col-12 col-md-5 " >
          <OrderInfo ref="OrderInfoRef" @setOrderItems="setOrderItems"/>
          <RemarkCheckOut v-show="!$store.getters['auth/getISTB']" />
          <!-- <EditHeaderQT v-if="!$store.getters['auth/getISTB']" page="checkout" :dataEditHeaderQT='dataHeaderQT' :tax="isTax" @headQT="startComponentEdit()"/>
          <p v-show="!$store.getters['auth/getISTB']" :class="`text-center m-0 h3 text-danger font-color-${qtBu}`">( กรุณาบันทึกก่อนพิมพ์ )</p> -->
          <!-- <PrintQuotation v-show="!isLoading" v-if="!$store.getters['auth/getISTB']" :cartref="getRef" :stcode="getStore" :dlvcode="'temp'" :buCode="qtBu"/> -->
          
          
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <button v-show="!isLoading" @click="validateConfirmQT" :class="`btn-visible-PWB bg-${qtBu}`" >ยืนยันใบเสนอราคา</button>
      </div>
    </div>
    </div>
    <CheckOutMainSkeleton v-show="WaitBooking" />
    <div v-show="isLoading" class="loading"></div>
  </section>
</template>

<script>
// @ is an alias to /src
import OrderInfo from '@/components/CheckOut/OrderInfo.vue'
import EditUserInfoForm from '@/components/EditUserInfoForm.vue'
import SmsandEmailButton from '@/components/CheckOut/SmsandEmailButton.vue';
import Promotion101 from '@/components/CheckOut/Promotion101.vue';
import DisMktCode from '@/components/CheckOut/DisMktCode.vue';
import RemarkCheckOut from '@/components/CheckOut/RemarkCheckOut.vue';
import PrintQuotation from '@/components/Menu/PrintQuotation.vue'
import EditHeaderQT from '@/components/CheckOut/EditHeaderQT.vue';
import PickupatStore from '@/components/CheckOut/PickupatStore.vue';
import EditPriceDlv from '@/components/CheckOut/EditPriceDlv.vue';
import SelectAddress from '@/components/CheckOut/SelectAddress.vue';
import SelectPick from '@/components/CheckOut/SelectPick.vue';
import SlotBooking from '@/components/CheckOut/SlotBooking/SlotBooking.vue';
import ButtonCartGrouping from '@/components/ButtonCartGrouping.vue';
import SlotBookingVfix from '@/components/CheckOut/SlotBooking/SlotBooking_Vfix.vue';
import CheckOutMainSkeleton from '@/components/CheckOut/CheckOutMainSkeleton.vue';
import SlotBookingB2B from '@/components/CheckOut/SlotBooking/SlotBookingB2B.vue';

import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';

export default {
  name: 'CheckOutMain',
  components: {
    OrderInfo,
    EditUserInfoForm,
    Promotion101,
    DisMktCode,
    RemarkCheckOut,
    PrintQuotation,
    EditHeaderQT,
    PickupatStore,
    EditPriceDlv,
    SelectAddress,
    SelectPick,
    SlotBooking,
    ButtonCartGrouping,
    SmsandEmailButton,
    SlotBookingVfix,
    CheckOutMainSkeleton,
    SlotBookingB2B
  },
  data: function () {
    return {
      dataUser:{},
      isTax:false,
      JSDELIVERY_DATA:null,
      FlagApprove_DATA:null,
      orderItems: [],
      dataHeaderQT:{},
      dataAddress:{},
      sendBill:'',
      sendBillData:'',
      haveSER: false,
      haveDLV: false,
      canReload: false,
      isLoading:true,
      pickupTime: '',
      CHKP:'',
      listdlvTrType:'',
      onlyPickup: true,
      invoiceType:'',
      creditRemain:0,
      reserveDate:0,
      reserveNo:0,
      BATCHID:'',
      listReseveStock: [],
      listDataCredit:[],
    }
  },
  mounted() {
    this.startComponentEdit()
    this.ResetVuexOnload()
    // setTimeout(() => {
    //   this.checkSER()
    // }, 1000) 
    // this.checkSER()
    this.getQTDLVTRTYPE = ''
    this.getQTPDLV = ''
    this.WaitBooking = false;
    if(this.getDSMMSSTORE) this.isTax = true
  },
  methods: {
    async startComponentEdit(){
      this.dataUser = this.getQTCUSTINFO;
      await this.getTheOneNumber()
      this.$store.dispatch('customer/setQTCUSTINFO', this.dataUser)
      // console.log(this.getQTCUSTINFO);
    },
    async confirmEdit(para_val){
      this.dataUser = para_val
      this.$store.dispatch('customer/setQTCUSTINFO',para_val )
    },
    async ResetVuexOnload(){
      
    },
    async getTheOneNumber(){
      try {
        // if(this.dataSelected.MOBILE.length != 10){
        //     return
        // }
        let data = this.dataUser;
        let getAPI = await serviceAPI.call_API('post', 'user/findTheOne', data,'auth');
        let getData = getAPI.data.dbitems[0];
        if(!getData ||getData.T1CNumber=='NOOK'){
          // this.dataSelected.T1C = getData.T1CNumber || '' ;
          this.dataUser.EMPLOYEEBUSHORTCODE = '';
          this.dataUser.EMPLOYEEID = '';
          this.dataUser.ISSTAFF = '';
          return
        }
        // this.dataUser.T1C = getData.T1CNumber || '' ;
        // this.dataSelected.CNAME = `${getData.MemberFirstNameThai} ${getData.MemberLastNameThai}`;
        this.dataUser.EMPLOYEEBUSHORTCODE = getData.EmployeeBUShortCode || '';
        this.dataUser.EMPLOYEEID = getData.EmployeeId || '';
        this.dataUser.ISSTAFF = getData.EmployeeFlag || '';
        // console.log(this.dataUser);
        
      } catch (error) {
        this.dataUser.EMPLOYEEBUSHORTCODE = '';
        this.dataUser.EMPLOYEEID = '';
        this.dataUser.ISSTAFF = '';
        // serviceMain.showErrorAlert(this, error.message)
      }
    },
    async validateConfirmQT(){
      try {
        this.WaitBooking = true;
        this.onlyPickup = true
        for (let i = 0; i < this.listdlvTrType.length; i++) {
          if(['KER','DIY','CON','CRI','VEN','SER','DCS'].includes(this.listdlvTrType[i])){
            this.onlyPickup = false
          }
        }
        // console.log(this.onlyPickup);
        if(this.onlyPickup){
          // await this.EditDlvZero()
        }
        // pickup Now
        if(this.getQTCHKP=='PickupNow'){
          
        }
        // pickup at store
        if(this.getQTCHKP=='Pickup'){
 
          if(!this.storeTimePick){
            throw new Error("กรุณาเลือกเวลารับสินค้า")
          }
          if(!this.storeDatePick){
            throw new Error("กรุณาเลือกวันรับสินค้า")
          }
        }
        // Dlv
        if(this.getQTCHKP=='Delivery' && this.getQTBOOKING && this.CHKP != 'PICK' && this.CHKP != 'PICKNOW' && !this.onlyPickup){
          if(this.$refs.SelectAddressDlvRef.selectedData==''){
            throw new Error("กรุณาเลือกสถานที่จัดส่งด้วยครับ")
          }
          if(this.isTax){
            if(this.$refs.SelectAddressTaxRef.selectedData==''){
              throw new Error("กรุณาเลือกสถานที่จัดส่งด้วยครับ")
            }
          }
          if(!this.getQTDLVTRTYPE){
              throw new Error("กรุณาเลือกวิธีการจัดส่ง")
          }
          // if(!this.getQTDLVTRTYPE || (this.getQTBOOKING && this.getQTDLVTRTYPE != 'KER' && this.getBookingItms.code == '')){
          //     throw new Error("กรุณาเลือกวิธีการจัดส่ง")
          // }
          // if(this.getQTDLVTRTYPE !== 'KER'){
          //   if(this.getQTBOOKING && this.getBookingItms.code == '') {
          //     throw new Error("กรุณาเลือกรอบรถสำหรับการจัดส่ง")
          //   }
          // }
          // if(!this.getQTPDLV){
          //   throw new Error("กรุณากรอกค่าขนส่ง")
          // }
          if(this.getQTPDLV<0){
            throw new Error("ไม่สามารถกรอกค่าจัดส่งติดลบได้")
          }
          
          
        }
        // if(this.getQTCHKP=='Delivery'){
            let tempPDLV = this.orderItems.find(rl => rl.SKC == '154338')
            if(!tempPDLV){
              if(!(this.getQTPDLV === '0.00')){
                throw new Error("กรุณากรอกค่าขนส่ง")
              }
            }
          // }
        

        if((this.getQTETAX == 1 || (this.getQTETAX == 2 && this.isTax)) && (!this.sendBill || this.sendBill.trim() == '')){
          throw new Error("กรุณาเลือกช่องทางรับใบเสร็จ/ใบกำกับภาษี")
        } else {
          let data={
            VIPID:this.getQTCUSTINFO.VIPID
          }
          let getAPI = await serviceAPI.call_API('post',`checkout/JSDELIVERY`,data,'auth');
          this.JSDELIVERY_DATA = getAPI.data.dbitems;
          setTimeout(() => {
            this.start2pay()
          }, 2000) 
          
        }
        // start2pay
        // checkApproveFlag
            // convFix() ` exec TWDQT..qt_requestQuotation '${ref}', '${stc}'`
              // linenotify(para_JSN);
              // mailnotify(para_JSN);
          // OR
            // conQT()` exec  TWDQT..qt_confirmQuotation '${ref}', '${stc}'`
              // alert(`สร้างใบเสนอราคาหมายเลข (${getsorder}) เรียบร้อยแล้ว`);
              // removeCookiewhenConQT

      } catch (error) {
        this.WaitBooking = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async saveSendETax(){
      try {
        let data={
          REF:this.getRef,
          SENDDATA:this.sendBillData,
          INVOICETYPE:this.invoiceType
        }
        let getAPI = await serviceAPI.call_API('post',`checkout/saveSendETax`,data,'auth');
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async start2pay(){
      try {
        let dlvtp = this.getQTCHKP;
        let qtbooking = this.getQTBOOKING;
        let getSumNet = await this.priceBath(this.$refs.OrderInfoRef.sumNET);
        let JSON_PAY = {
          tendor: "CALL later" ,
          amount: getSumNet,
        }
        let JSON_DLV = {}
        for (let [key, value] of Object.entries(this.JSDELIVERY_DATA)) {
          JSON_DLV[value.key] = value.value;
        }
        if(dlvtp=='PickupNow'){
          dlvtp="Pickup"
          JSON_DLV["dlvnow"] = "1";
          let today = new Date();
          let dd = String(today.getDate()).padStart(2, '0');
          let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          let yyyy = today.getFullYear();
          let hours = today.getHours().toString(); // => 9
          let min = today.getMinutes().toString(); // =>  30
          hours = hours.length > 1 ? hours : '0'+hours;
          min = min.length > 1 ? min : '0'+min;
          // if(hours < 23){
          //   if(min <= 30){
          //     min = 30
          //   } else if (min > 30){
          //     min = 0
          //     hours = hours + 1
          //   }
          // }
          if(yyyy > 2500){
            yyyy = yyyy - 543
          }
          let timenow = hours + ':' + min;
          today = dd + '/' + mm + '/' + yyyy;
          JSON_DLV["dlvdate"] = today;
          JSON_DLV["dlvtime"] = timenow;
          this.storeDatePick = today
          this.storeTimePick = timenow
        }else{
          JSON_DLV["dlvnow"] = "0";
          JSON_DLV["dlvdate"] = null;
          JSON_DLV["dlvtime"] = null;
        }
        JSON_DLV["dlvtype"] = dlvtp;
        // JSON_DLV["dlvtype"] = this.onlyPickup ? 'Pickup' : 'Delivery';
        JSON_DLV["dlvmulti"] = {
          adrname:'',name:'',lname:'',company:'',idnum:'',idbranch:'',phone:'',phone2:'',adrnum:'',build:'',floor:'',room:'',moo:'',soi:'',road:'',subdistrict:'',district:'',province:'',zipcode:'',ulat:'',ulong:'',typeofadr:'',othertxt:'',havele:'',can:'',adrstt:'ship',
        };
        if(this.getQTCHKP=='Pickup'){
          JSON_DLV["dlvdate"] = this.storeDatePick.split('-').reverse().join('/') || null;
          JSON_DLV["dlvtime"] = this.storeTimePick || null;
        }
        if(dlvtp=='Delivery'){
          let savepinfo = this.$refs.SelectAddressDlvRef.selectedObjData;
          JSON_DLV["dlvmulti"] = savepinfo;
          if(qtbooking == true) {
            let trtype = this.getQTDLVTRTYPE
            JSON_DLV["tspgroup"] = (!['DIY', 'CON'].includes(trtype)) ? 1 : (trtype == 'DIY') ? 2 : 3;
          }
        }    

        if((this.getQTETAX == 1 || (this.getQTETAX == 2 && this.isTax)) && (!this.sendBill || this.sendBill.trim() == '')){
          throw new Error("กรุณาเลือกช่องทางรับใบเสร็จ/ใบกำกับภาษี")
        } 
        
        if ((this.getQTETAX == 1 || (this.getQTETAX == 2 && this.isTax)) || (this.sendBill && this.sendBill.trim() != '')) {
          await this.saveSendETax();
        } else if(this.isTax) {
          let datatax={
          REF:this.getRef,
          SENDDATA:'S,'+this.dataUser.MOBILE,
          INVOICETYPE:'P'
          }
          let getAPItax = await serviceAPI.call_API('post',`checkout/saveSendETax`,datatax,'auth');
        }

        // if(this.getQTCHKP=='Delivery'){
        //   let tempPDLV = this.orderItems.find(rl => rl.SKC == '154338')
        //   if(tempPDLV){
        //     if(tempPDLV.AMT != this.getQTPDLV){
        //       throw new Error("กรุณากรอกค่าขนส่ง")
        //     }
        //   }
        //   if(this.getQTPDLV){
        //     if(parseFloat(this.getQTPDLV) != 0){
        //       if(!tempPDLV){
        //         throw new Error("กรุณากรอกค่าขนส่ง")
        //       }else{
        //         if(tempPDLV.AMT != this.getQTPDLV){
        //           throw new Error("กรุณากรอกค่าขนส่ง")
        //         }
        //       }
        //     }
        //   }else{
        //     throw new Error("กรุณากรอกค่าขนส่ง")
        //   }
        // }
        if(this.getQTCHKP=='Delivery' && this.CHKP != 'PICK' && this.CHKP != 'PICKNOW' && !this.onlyPickup){
          let tempPDLV = this.orderItems.find(rl => rl.SKC == '154338')
          if(!tempPDLV){
            if(!(this.getQTPDLV === '0.00')){
              throw new Error("กรุณากรอกค่าขนส่ง")
            }
          }
        }
        if(this.$store.getters['auth/getISTB']){
          if(this.getEPCUSTINFO.CUSTIDDS != '9999990189'){
            await this.checkCreditDS()
          }
        }
        
        let data={
          REF:this.getRef ,
          JSON_PAY:JSON_PAY ,
          JSON_DLV:JSON_DLV ,
          REQ_TAX:this.isTax?1:0
        }
        
        let getAPI = await serviceAPI.call_API('post',`checkout/start2pay`,data,'auth');
        // if(dlvtp=='Pickup'){
        //   this.checkUserFlagApprove()
        // }else{
        //   let dlvtrtype = this.getQTDLVTRTYPE
          
        //   if(qtbooking !== true || (qtbooking == true && dlvtrtype == 'KER')) {
        //     this.checkUserFlagApprove()
        //   } else {
       
        //     let reserveBooking = await this.$refs.SlotBookingRef.reserveBooking()
        //     this.checkUserFlagApprove()
        //   }
        // }
        // if(dlvtp=='Pickup' && !this.haveSER){
        //   this.checkUserFlagApprove()
        // }else{
          let reserveBooking = ''
          if(this.$store.getters['auth/getISTB']){
            console.log('SlotBookingRefB2B');
            reserveBooking = await this.$refs.SlotBookingRefB2B.reserveBooking()
          }else{
            reserveBooking = await this.$refs.SlotBookingRef.reserveBooking()
          }
          console.log(reserveBooking);
          if(reserveBooking.packagPass){
            // let confirmBookingV2 = await this.$refs.SlotBookingRef.confirmBookingV2()
            let confirmBookingV2 = ''
            if(this.$store.getters['auth/getISTB']){
              confirmBookingV2 = await this.$refs.SlotBookingRefB2B.confirmBookingV2()
            }else{
              confirmBookingV2 = await this.$refs.SlotBookingRef.confirmBookingV2()
            }
            // console.log(confirmBookingV2);
            // if(confirmBookingV2.conPass){
            //   // if(this.$store.getters['auth/getISTB']){
            //   //   this.reserveCreditDS()
            //   // }else{
            //     setTimeout(() => {
            //       this.checkUserFlagApprove()
            //     }, 500) 
            //   // }
            // }else{
            //   throw new Error(confirmBookingV2.errorMsg);
            // }
            if(confirmBookingV2.conPass){
              if(this.$store.getters['auth/getISTB']){
                setTimeout(() => {
                  this.reserveStockMMSB2B()
                }, 500) 
              }else if(this.getDSMMSSTORE){
                setTimeout(() => {
                  // this.reserveStockMMS()
                  this.checkStockCart()
                }, 500) 
              }else if(this.$store.getters['auth/getWSFLAG']){
                setTimeout(() => {
                  this.updateJsnCredit()
                }, 500) 
              }else{
                setTimeout(() => {
                  this.checkUserFlagApprove()
                }, 500) 
              }
            }else{
              throw new Error(confirmBookingV2.errorMsg);
            }
          }else{
            let errMassage = ''
            if(reserveBooking.refresh) errMassage = reserveBooking.refresh
            if(reserveBooking.packageFull != ''){
              errMassage = `แพ็คเกจ ${reserveBooking.packageFull} ช่วงเวลาจัดส่งที่คุณเลือกเต็มแล้วในขณะนี้กรุณาเลือกช่วงเวลาจัดส่งใหม่`
            }
            if(reserveBooking.packageNotAva != ''){
              errMassage = errMassage == '' ? `แพ็คเกจ ${reserveBooking.packageNotAva} ไม่สามารถเลือกช่วงเวลาจัดส่งนี้ได้กรุณาลองใหม่อีกครั้ง` : errMassage + '\n' + `แพ็คเกจ ${reserveBooking.packageNotAva} ไม่สามารถเลือกช่วงเวลาจัดส่งนี้ได้กรุณาลองใหม่อีกครั้ง`
            }
            await this.$refs.SlotBookingRef.csvoidBooking()
            throw new Error(errMassage);
          }
        
      } catch (error) {
        this.WaitBooking = false;
        console.log('start2pay');
        serviceMain.showErrorAlert(this,error)
      }
    },

    async deleteORDRESERVETEMP(){
      try {
        let data={
          BATCHID: this.BATCHID,
        }
        await serviceAPI.call_API('post',`wholesale/deleteORDRESERVE`,data,'auth');
        // console.log(getData);
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async updateJsnCredit(){
      try {
        let data = {
            REF:this.getRef,
        }
        let getAPI = await serviceAPI.call_API('post', `wholesale/getDetail4ReserveCredit`, data, 'auth');
        this.listDataCredit = getAPI.data.dbitems[0];

        let JSNCREDIT = {
          docNo: this.listDataCredit.WSREF.trim(),
          wholeSaleCustomerId: this.listDataCredit.CUSTIDWS.trim(),
          directSaleCustomerId:'',
          directSaleMMSStoreCode: '',
          reserveNo: '',
          reserveDate: '',
          prNo: '',
          // reserveMMSNo: this.listdataMMSRES.HOLDREF
          reserveMMSNo: ''
        }
        let datapaid={
          REF:this.getRef ,
          JSNCREDIT:JSNCREDIT
        }
        // console.log(datapaid);
        let getAPIpaid = await serviceAPI.call_API('post',`wholesale/updateQTOrderNumberWS`,datapaid,'auth');
        var getsorder = getAPIpaid.data.dbitems;
        setTimeout(() => {
          this.checkUserFlagApprove()
        }, 500) 
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async reserveStockMMSB2B(){
      try {
        // if(!this.$store.getters['auth/getISTB']){
        //   let dataSTORE={
        //     store: this.getDSMMSSTORE
        //   }
        //   console.log(dataSTORE);
        //   let getAPISTORE = await serviceAPI.call_API('post',`service/getSTORE`,dataSTORE,'auth');
        //   console.log(getAPISTORE);
        //   let store = getAPISTORE.data.dbitems[0].STORE;
        //   if(!store){
        //     throw new Error('ไม่สามารถจอง stock MMS ได้ (error Store)')
        //   }
        // }

        // let data={
        //   REF: this.getRef 
        // }
        // let getAPI = await serviceAPI.call_API('post',`wholesale/getBatchID`,data,'auth');
        // this.BATCHID = getAPI.data.dbitems[0].BATCH_ID;
        // if(!this.BATCHID){
        //   throw new Error('ไม่สามารถจอง stock MMS ได้ (error BATCHID)')
        // }

        // await this.deleteORDRESERVETEMP()

        // this.listReseveStock = []
        // let tempsku =  this.orderItems.map(rl => rl.SKC)
        // tempsku = [...new Set(tempsku)]
        // for (let i = 0; i < this.orderItems.length; i++) {
        //   for (let j = 0; j < tempsku.length; j++) {
        //     if(tempsku[j] == this.orderItems[i].SKC && tempsku[j] != '154338'){
        //       let k = (this.listReseveStock.findIndex(itm => itm.SKC == tempsku[j]))
        //       if (k > -1) {
        //         this.listReseveStock[k].QNT = this.listReseveStock[k].QNT + this.orderItems[i].QNT
        //       }else{
        //         this.listReseveStock.push(this.orderItems[i])
        //       }
        //     }
        //   }
        // }

        // for (let i = 0; i < this.listReseveStock.length; i++) {
        //   let dataINSORS={
        //     REF: this.getRef, 
        //     BATCHID: this.BATCHID,
        //     STCODE: this.$store.getters['auth/getISTB'] ? this.$store.getters["service/getStcShowStock"] : store, 
        //     UPC: this.listReseveStock[i].PCD, 
        //     QTY: this.listReseveStock[i].QNT, 
        //     RETL: this.listReseveStock[i].AMT, 
        //     ORDERNO: this.$store.getters['auth/getISTB'] ? this.getRef : this.getCopyRef, 
        //     SEQ: this.listReseveStock[i].SEQ,
        //     SALESOURCE: this.getQTSALESOURCE
        //   }
        //   let getAPIINSORS = await serviceAPI.call_API('post',`wholesale/insertORDRESERVE`,dataINSORS,'auth');
        // }

        // let dataINSIN={
        //   BATCHID: this.BATCHID,
        // }
        // let getAPIINSIN = await serviceAPI.call_API('post',`wholesale/intfORDRESERVEDIRECTSALES`,dataINSIN,'auth');
        // console.log(getAPIINSIN);
        // this.listdataMMSRES = getAPIINSIN.data.dbitems[getAPIINSIN.data.dbitems.length -1]
        // let listerror = getAPIINSIN.data.dbitems
        // console.log(listerror);
        // console.log(this.listdataMMSRES);
        // if(getAPIINSIN.data.dbcode > 0){
        //   throw new Error(getAPIINSIN.data.dbmessage);
        // }
        // let msg = ''
        // if(this.listdataMMSRES.RESULT != 'OKOK'){
        //   if (listerror.length-1 != 0) {
        //     msg = `<h2>Stock สินค้ามีไม่พอ</h2>`
        //     for (let i = 0; i < listerror.length-1; i++) {
        //       for (let j = 0; j < this.orderItems.length; j++) {
        //         if(this.orderItems[j].SKC == listerror[i].SKCODE)
        //         msg = msg + `<span>${listerror[i].SKCODE} ${this.orderItems[j].PRN}</span><br>`
        //       }
        //     }
        //     this.$swal.fire({
        //         html: msg
        //     })
        //   }
        //   throw new Error(msg);
        // }
        let dataUPDATEMMS={
          REF: this.getRef, 
          MMSNO: '',
          //MMSNO: this.listdataMMSRES.HOLDREF
        }
        await serviceAPI.call_API('post',`wholesale/updateQTReserveMMSNo`,dataUPDATEMMS,'auth');
        setTimeout(() => {
          this.checkUserFlagApprove()
        }, 500) 
      } catch (error) {
        this.WaitBooking = false;
        serviceMain.showErrorAlert(this,error)
      }
    },

    async reserveStockMMS(){
      try {
        let dataSTORE={
          store: this.getDSMMSSTORE
        }
        console.log(dataSTORE);
        let getAPISTORE = await serviceAPI.call_API('post',`service/getSTORE`,dataSTORE,'auth');
        console.log(getAPISTORE);
        let store = getAPISTORE.data.dbitems[0].STORE;
        if(!store){
          throw new Error('ไม่สามารถจอง stock MMS ได้ (error Store)')
        }

        let data={
          REF: this.getRef 
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/getBatchID`,data,'auth');
        this.BATCHID = getAPI.data.dbitems[0].BATCH_ID;
        if(!this.BATCHID){
          throw new Error('ไม่สามารถจอง stock MMS ได้ (error BATCHID)')
        }

        await this.deleteORDRESERVETEMP()

        this.listReseveStock = []
        let tempsku =  this.orderItems.map(rl => rl.SKC)
        tempsku = [...new Set(tempsku)]
        for (let i = 0; i < this.orderItems.length; i++) {
          if(this.orderItems[i].OFflag != 1){
            for (let j = 0; j < tempsku.length; j++) {
              if(tempsku[j] == this.orderItems[i].SKC && tempsku[j] != '154338'){
                let k = (this.listReseveStock.findIndex(itm => itm.SKC == tempsku[j]))
                if (k > -1) {
                  this.listReseveStock[k].QNT = this.listReseveStock[k].QNT + this.orderItems[i].QNT
                }else{
                  this.listReseveStock.push(this.orderItems[i])
                }
              }
            }
          }
          
        }
        if(this.listReseveStock.length != 0 ){
          for (let i = 0; i < this.listReseveStock.length; i++) {
            let dataINSORS={
              REF: this.getRef, 
              BATCHID: this.BATCHID,
              STCODE: store, 
              UPC: this.listReseveStock[i].PCD, 
              QTY: this.listReseveStock[i].QNT, 
              RETL: this.listReseveStock[i].AMT, 
              ORDERNO: this.getCopyRef, 
              SEQ: this.listReseveStock[i].SEQ,
              SALESOURCE: this.getQTSALESOURCE
            }
            let getAPIINSORS = await serviceAPI.call_API('post',`wholesale/insertORDRESERVE`,dataINSORS,'auth');
          }

          let dataINSIN={
            BATCHID: this.BATCHID,
          }
          let getAPIINSIN = await serviceAPI.call_API('post',`wholesale/intfORDRESERVEDIRECTSALES`,dataINSIN,'auth');
          console.log(getAPIINSIN);
          this.listdataMMSRES = getAPIINSIN.data.dbitems[getAPIINSIN.data.dbitems.length -1]
          let listerror = getAPIINSIN.data.dbitems
          console.log(listerror);
          console.log(this.listdataMMSRES);
          if(getAPIINSIN.data.dbcode > 0){
            throw new Error(getAPIINSIN.data.dbmessage);
          }
          let msg = ''
          if(this.listdataMMSRES.RESULT != 'OKOK'){
            if (listerror.length-1 != 0) {
              msg = `<h2>${this.listdataMMSRES.RESULT}</h2>`
              for (let i = 0; i < listerror.length-1; i++) {
                msg = msg + `<span>${listerror[i].SKCODE} ${listerror[i].REMARK}</span><br>`
              }
              this.$swal.fire({
                  html: msg
              })
            }
            throw new Error(msg);
          }
        }
        let dataUPDATEMMS={
          REF: this.getRef, 
          MMSNO: this.listdataMMSRES ? this.listdataMMSRES.HOLDREF : ''
        }
        await serviceAPI.call_API('post',`wholesale/updateQTReserveMMSNo`,dataUPDATEMMS,'auth');
        setTimeout(() => {
          this.checkUserFlagApprove()
        }, 500) 
      } catch (error) {
        this.WaitBooking = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async checkStockCart(){
      try {
        let data={
          REF: this.getRef 
        }
        let getAPI = await serviceAPI.call_API('post',`wholesale/checkStockCart`,data,'auth');
        let listerror = getAPI.data.dbitems
        if(getAPI.data.dbcode > 0){
          throw new Error(getAPI.data.dbmessage);
        }
        let msg = ''
        if(listerror.length != 0){
            // msg = `<h2>${listerror[1].MSG}</h2>`
            for (let i = 0; i < listerror.length; i++) {
              msg = msg + `<span>${listerror[i].SKC} QNT:${listerror[i].QNT} AvailSTORE:${listerror[i].AvailSTORE} AvailDC:${listerror[i].AvailDC} MSG:${listerror[i].MSG}</span><br>`
            }
            this.$swal.fire({
                html: msg
            })
          throw new Error(msg);
        }

        await this.checkCreditWS()

        let dataUPDATEMMS={
          REF: this.getRef, 
          MMSNO: ''
        }
        await serviceAPI.call_API('post',`wholesale/updateQTReserveMMSNo`,dataUPDATEMMS,'auth');

        // if(this.$store.getters['customer/getQTPONUM']) {
          let dataPO={
            PONUM: this.$store.getters['customer/getQTPONUM'],
            REF: this.getRef 
          }
          let getAPIPO = await serviceAPI.call_API('post',`history/updatePORequestHistoryQTRef`,dataPO,'auth');
        // }

        setTimeout(() => {
          this.checkUserFlagApprove()
        }, 500) 
      } catch (error) {
        this.WaitBooking = false;
        serviceMain.showErrorAlert(this,error)
      }
    },

    async checkUserFlagApprove(){
      try {
        let data={
          REF:this.getRef ,
          USERID:this.getQTUSERID
        }
        let getAPI = await serviceAPI.call_API('post',`checkout/checkApproveFlag`,data,'auth');
        let getQTUSERAPVFLAG = getAPI.data.dbitems[0].QTUSERAPVFLAG;
        let getSKUAPPROVEFLAG = getAPI.data.dbitems[0].SKUAPPROVEFLAG;
        let getJSN = getAPI.data.dbitems[0]

        if(getSKUAPPROVEFLAG){ 
          
          if(getQTUSERAPVFLAG){
            
            this.convFix(getJSN);
          }else{
            this.conQT();
          }
        }else{
          // alert("else conQT")
          this.conQT();
        }
      } catch (error) {
        this.WaitBooking = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async conQT(){
     
      try {
        let data={
          REF:this.getRef ,
          STCODE:this.getStore
        }
        let getAPI = await serviceAPI.call_API('post',`checkout/confirmQuotation`,data,'auth');
        var getsorder = getAPI.data.dbitems[0].SORDERREF;
        if(this.$store.getters['auth/getISTB']){
          this.reserveCreditDS()
        }else{
          serviceMain.showSuccessAlertWithTime(this,`สร้างใบเสนอราคาหมายเลข (${getsorder}) เรียบร้อยแล้ว`,3000)
          this.clearData()
        }
        
                                    
      } catch (error) {
        this.WaitBooking = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async convFix(para_JSN){
      try {
        let data={
          REF:this.getRef ,
          STCODE:this.getStore
        }
        let getAPI = await serviceAPI.call_API('post',`checkout/requestQuotation`,data,'auth');
        this.mailnotify(para_JSN)
        this.linenotify(para_JSN)

        serviceMain.showSuccessAlertWithTime(this,`ขออนุมัติใบเสนอราคาเรียบร้อยแล้ว`,3000)

        this.clearData()
      } catch (error) {
        this.WaitBooking = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async clearData(){
      if(this.$store.getters['auth/getWSFLAG']){
        this.$router.push({ name: 'OrderWholeSale' , query: { confirm: 'success' }})
      }else{
        this.$router.push({ name: 'FindUser' , query: { confirm: 'success' }})
      }
 
    },
    async mailnotify(para_JSN) {
        try {
          let data = {
              QT: this.getRef,
              MAIL_TO: para_JSN.reqApproveEmailTo,
              MAIL_CC: para_JSN.reqApproveEmailCC,
              TOTAL: para_JSN.QTTotal,
              FN: '',
              STC: this.getStore,
              USERNAME: para_JSN.CREATEUSER.trim(),
              REMARK: '',
              HOSTNAME:`${location.protocol}//${location.host}`
          };
          await serviceAPI.call_API('post', `service/mailNotify`, data, 'auth');
   
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
    },
    async linenotify(para_JSN) {
        try {
            let data = {
                QT: this.getRef,
                TOKEN: para_JSN.reqApproveLine,
                TOTAL: para_JSN.QTTotal,
                FN: '',
                STC: this.getStore,
                USERNAME: para_JSN.CREATEUSER.trim(),
                REMARK: '',
                HOSTNAME:`${location.protocol}//${location.host}`
            };
            await serviceAPI.call_API('post', `service/lineNotify`, data, 'auth');
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }

    },
    async priceBath(para_price) {
      return para_price.toLocaleString(undefined, {minimumFractionDigits: 2})
    },
    async setOrderItems(orderItems) {
      this.orderItems = orderItems;
      this.isLoading = false;
      console.log('finish orderinfo');
    },
    async setSendBill(sendBill) {
      this.sendBill = sendBill[0];
      this.sendBillData = sendBill[1];
    },
    async setInvoiceType(data) {
      this.invoiceType = data
    },
    async confirmCHKP(e) {
      this.CHKP = e;
    },
    async headerQT(para_headerQT) {
        try {
            this.dataHeaderQT = para_headerQT;
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
    },
    async saveADD(para_ADD) {
        try {
            this.dataAddress = para_ADD;
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
    },
    async checkSER() {
      try {
        let itmTranDlv = []
        let itmTranSer = []
        // this.getQTPDLV = ''
        let data = {
          REF: this.getRef
        }
        let tranData = await serviceAPI.call_API('post', 'checkout/getTransportSpecv2', data,'auth');
        if(tranData.data.dbrows > 0) {
          itmTranDlv = tranData.data.dbitems.filter(pk=>pk.tsptype.trim() != "SER")
          itmTranSer = tranData.data.dbitems.filter(pk=>pk.tsptype.trim() == "SER")
        }
        if(itmTranSer.length != 0){
          this.haveSER = true;
        }
        else{
          this.haveSER = false;
        }
        if(itmTranDlv.length != 0){
          this.haveDLV = true;
        }
        else{
          this.haveDLV = false;
        }
        // this.$store.dispatch('service/setReloadOrderInfo')
      } catch (error) {
        console.log('checkSER');
        this.canReloadS = true;
        if(error.message == "Invalid object name 'SORDER'."){
          // location.reload(true);
          return
        }
        serviceMain.showErrorAlert(this,error)
      }
    },
    async fnlistdlvTrType(val) {
      try{
        this.listdlvTrType = val
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },
    async EditDlvZero(){
      try {
            let data={
                REF: this.getRef, 
                TRAN_JSON:{
                  amount: (Math.round(0 * 100) / 100).toFixed(2),
                }
            }
            let getAPI = await serviceAPI.call_API('post',`checkout/SaveTransport`,data,'auth');
            this.$store.dispatch('service/setReloadOrderInfo')
            this.getQTPDLV = '0.00'
            console.log('EditDlvZeroMain');
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async checkCreditDS(){
      try {
        let data={
          REF:this.getRef,
          // STCODE:this.$store.getters["service/getStcShowStock"],
          STCODE:'60011',
          CustomerDSID:this.getEPCUSTINFO.CUSTIDDS
        }
        // console.log(data);
        let getAPI = await serviceAPI.call_API('post',`directsale/checkCreditDS`,data,'auth');
        let getData = JSON.parse(getAPI.data.data);
        // console.log(getData);
        if(getData.status == 0){
          throw new Error(getData.message)
        }
        this.creditRemain = getData.data.creditremain
        let getSumTOT = 0
        for(let e of this.orderItems){
          getSumTOT = getSumTOT + e.AMT
        }
        console.log('เครดิต'+this.creditRemain+'ขาย'+getSumTOT);
        if(!(this.creditRemain >= getSumTOT)){
          throw new Error("เครดิตไม่พอ")
        }
        // this.creditPermanenct = getData.data.creditpermanenct
        // this.creditTemp = getData.data.credittemp
        // this.creditTotal = getData.data.credittotal
      } catch (error) {
        this.WaitBooking = false;
        console.log('checkCreditDS');
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    async checkCreditWS(){
      try {
        let data={
          REF:this.getRef,
          // STCODE:this.$store.getters["service/getStcShowStock"],
          STCODE:this.getStore,
          CustomerDSID:this.$store.getters['service/getDSCUSTID']
        }
        // console.log(data);
        let getAPI = await serviceAPI.call_API('post',`wholesale/checkCreditDS`,data,'auth');
        let getData = JSON.parse(getAPI.data.data);
        // console.log(getData);
        if(getData.status == 0){
          throw new Error(getData.message)
        }
        this.creditRemain = getData.data.creditremain
        let getSumTOT = 0
        for(let e of this.orderItems){
          getSumTOT = getSumTOT + e.AMT
        }
        console.log('เครดิต'+this.creditRemain+'ขาย'+getSumTOT);
        if(!(this.creditRemain >= getSumTOT)){
          throw new Error("เครดิตไม่พอ")
        }
        // this.creditPermanenct = getData.data.creditpermanenct
        // this.creditTemp = getData.data.credittemp
        // this.creditTotal = getData.data.credittotal
      } catch (error) {
        this.WaitBooking = false;
        console.log('checkCreditWS');
        serviceMain.showErrorAlert(this,error.message)
      }
    },

    // async test(){
    //   let dataNoti = {
    //     REF: this.getRef,
    //     USERID:this.getQTUSERID
    //   }
    //   let getAPINoti = await serviceAPI.call_API('post',`directsale/getSendNotify`,dataNoti,'auth');
    //     let getDataNoti = getAPINoti.data.dbitems[0]
    //     console.log(getAPINoti.data.dbitems[0]);
    //     await this.mailnotifyB2B(getDataNoti)
    //     await this.linenotifyB2B(getDataNoti)
    // },
    async mailnotifyB2B(para_JSN,orderitm) {
        try {
          let data = {
              QT: this.getRef,
              MAIL_TO: para_JSN.EmailTo,
              MAIL_CC: para_JSN.EmailCC,
              TOTAL: para_JSN.Total,
              STC: this.$store.getters["service/getStcShowStock"],
              USERNAME: this.getQTCUSTINFO.CNAME,
              REMARK: '',
              HOSTNAME:`${location.protocol}//${location.host}`,
              ORDERITMS:orderitm,
          };
          console.log(data);
          await serviceAPI.call_API('post', `directsale/mailNotifyB2B`, data, 'auth');
   
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
    },
    async linenotifyB2B(para_JSN) {
        try {
            let data = {
                QT: this.getRef,
                TOKEN: para_JSN.LINETO,
                TOTAL: para_JSN.Total,
                STC: this.$store.getters["service/getStcShowStock"],
                USERNAME: this.getQTCUSTINFO.CNAME,
                REMARK: '',
                HOSTNAME:`${location.protocol}//${location.host}`
            };
            await serviceAPI.call_API('post', `directsale/lineNotifyB2B`, data, 'auth');
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }

    },
    async reserveCreditDS(){
      try {
        if(!this.getEPCUSTINFO.CUSTIDDS) {
          serviceMain.showWarningAlert(this,'กรุณาระบุค่า PR No')
          return;
        }
        if(this.getEPCUSTINFO.CUSTIDDS != '9999990189'){
          await this.checkCreditDS()
        }

        let dataNoti = {
          REF: this.getRef,
          USERID:this.getQTUSERID
        }
        let getAPINoti = await serviceAPI.call_API('post',`directsale/getSendNotify`,dataNoti,'auth');
        let getDataNoti = getAPINoti.data.dbitems[0]
        // console.log(getAPINoti.data.dbitems[0]);

        // let getSumTOT = 0
        // for(let e of this.orderItems){
        //   getSumTOT = getSumTOT + e.AMT
        // }
        // if(!(this.creditRemain >= getSumTOT)){
        //   serviceMain.showWarningAlert(this,'เครดิตไม่พอ')
        // }
        // let data={
        //   REF:this.getRef,
        //   SALESOURCE:this.getQTSALESOURCE,
        //   STCODE:this.$store.getters["service/getStcShowStock"],
        //   AMOUNT:getSumTOT,
        //   CustomerDSID:this.getEPCUSTINFO.CUSTIDDS
        // }
        // // console.log(data);
        // let getAPI = await serviceAPI.call_API('post',`directsale/reserveCreditDS`,data,'auth');
        // let getData = JSON.parse(getAPI.data.data);
        // // console.log(getData);
        // if(getData.status == 0){
        //   throw new Error(getData.message)
        // }

        // this.reserveNo = getData.reserveNo
        // this.reserveDate = getData.reserveDate
        // this.creditRemain = getData.creditRemain

        // // await this.reserveStockMMS()
        // // if(this.resStockMMSStatus != true){
        // //   this.cancelReserveCreditDS()
        // // }
        
        // // this.mailnotify(para_JSN)
        // // this.linenotify(para_JSN)

        // let JSNCREDIT = {
        //   docNo: '',
        //   wholeSaleCustomerId: '',
        //   directSaleCustomerId:this.getEPCUSTINFO.CUSTIDDS,
        //   directSaleMMSStoreCode: this.$store.getters["service/getStcShowStock"],
        //   reserveNo: getData.reserveNo,
        //   reserveDate: getData.reserveDate,
        //   prNo: '',
        //   // reserveMMSNo: this.listdataMMSRES.HOLDREF
        //   reserveMMSNo: ''
        // }
        // let JSNPOSREF = [getData.reserveNo]
        // let datapaid={
        //   REF:this.getRef ,
        //   JSNCREDIT:JSNCREDIT,
        //   JSNPOSREF:JSNPOSREF
        // }
        // // console.log(datapaid);
        // let getAPIpaid = await serviceAPI.call_API('post',`directsale/paidByCredit`,datapaid,'auth');
        // var getsorder = getAPIpaid.data.dbitems;
        // // console.log(getsorder);
        // // this.conQT();
        // serviceMain.showSuccessAlertWithTime(this,`การจองเครดิตสำเร็จ ${getData.reserveNo}`,3000)

        await this.updatePickB2B()

        let datasend={
          REF:this.getRef ,
          SALESOURCE:this.getQTSALESOURCE,
        }
        let getAPISend = await serviceAPI.call_API('post',`directsale/sendQTB2B2STS`,datasend,'auth');
        serviceMain.showSuccessAlertWithTime(this,`สร้างใบเสนอราคาเรียบร้อยแล้ว`,3000)
        // this.mailnotifyB2B(getDataNoti,this.orderItems)
        // this.linenotifyB2B(getDataNoti)
        // this.$store.dispatch('customer/clearAfterConfirmCheckout')
        // this.$store.dispatch('service/clearAfterConfirmCheckout')
        // this.$router.push({ name: 'History', params: { whereBy: 'ByUser' } })
        // this.$router.push({ name: 'twdb2blogin', query: { confirm: 'success' }})
        this.$router.push("/tb/getcart");
        // this.clearData()
        // this.$emit("setreloadcard");
        // this.checkCreditDS()
      } catch (error) {
        this.WaitBooking = false;
        console.log('reserveCreditDS');
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async updatePickB2B() {
        try{
          let jsnItemsPick = []
          for (let i = 0; i < this.orderItems.length; i++) {
            jsnItemsPick.push({
              "orderSeq": this.orderItems[i].SKUSEQ,
              "skuCode": this.orderItems[i].SKC,
              "upcCode": this.orderItems[i].PCD,
              "quant": this.orderItems[i].QNT
            })
          }
          let data ={
            "saleSource": this.getQTSALESOURCE,
            "quotationNO": this.getRef,
            "items": jsnItemsPick
          }
          await serviceAPI.call_API('post', `directsale/updatePickB2B`, data, 'auth');
        } catch (error) {
          serviceMain.showErrorAlert(this, error)
        }
    },
  },
  computed: {
    getWSFLAG:function(){
      return this.$store.getters['auth/getWSFLAG']
    },
    getWSplpp:function(){
      return this.$store.getters['service/getWSplpp']
    },
    getWSdiscountBill:function(){
      return this.$store.getters['service/getWSdiscountBill']
    },
    getRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    getStore:function(){
      return this.$store.getters['service/getQTDFSTORE']
    },
    getStoreName:function(){
      return this.$store.getters['service/getQTSTORENAME']
    },
    getBuName:function(){
      let getBU = this.$store.getters['customer/getQTBU']
      if(getBU=='bnb'){
        return "บ้านแอนด์บียอนด์"
      }else if(getBU=='at1'){
        return "Auto1"
      }else{
        return "ไทวัสดุ"
      }
    },
    getQTCUSTINFO:function(){
      return this.$store.getters['customer/getQTCUSTINFO']
    },
    getQTCHKP:function(){
      return this.$store.getters['service/getQTCHKP']
    },
    getQTBOOKING:function(){
      return this.$store.getters['auth/getQTBOOKING']
    },
    getQTUSERID:function(){
      return this.$store.getters['auth/getQTUSERID']
    },
    // getQTDLVTRTYPE:function(){
    //   return this.$store.getters['customer/getQTDLVTRTYPE']
    // },
    getQTDLVTRTYPE: {
      get () {
        return this.$store.getters['customer/getQTDLVTRTYPE'] || ''
      },
      set (value) {
        this.$store.dispatch('customer/setQTDLVTRTYPE',value)
      }
    },
    WaitBooking: {
      get () {
        return this.$store.getters['service/getWaitBooking']
      }, 
      set (value) {
        this.$store.dispatch('service/setWaitBooking', value)
      }
    },
    qtBu: function (){
      return this.$store.getters['customer/getQTBU']
    },
    getBookingItms: function() {
      return this.$store.getters['service/getBookingItms']
    },
    getReloadOrderInfo:function(){
      return this.$store.getters['service/getReloadOrderInfo']
    },
    getCallOrderInfo:function(){
      return this.$store.getters['service/getCallOrderInfo']
    },
    getQTETAX: function () {
        return this.$store.getters['auth/getQTETAX']
    },
    getQTPDLV: {
      get () {
        return this.$store.getters['service/getQTPDLV']
      },
      set (value) {
        this.$store.dispatch('service/setQTPDLV',value)
      }
    },
    ChangeAddress:function(){
      return this.$store.getters['service/getChangeAddress']
    },
    getQTBOOKING: function () {
      return this.$store.getters["auth/getQTBOOKING"];
    },
    DisMemberCode: {
      get () {
          return this.$store.getters['service/getDisMemberCode']
      },
      set (value) {
          this.$store.dispatch('service/setDisMemberCode',value)
      }
    },
    DisMemberName: {
      get () {
          return this.$store.getters['service/getDisMemberName']
      },
      set (value) {
          this.$store.dispatch('service/setDisMemberName',value)
      }
    },
    storeTimePick: {
      get () {
        return this.$store.getters['service/getTimePick']
      },
      set (value) {
        this.$store.dispatch('service/setTimePick',value)
      }
    },
    storeDatePick: {
      get () {
        return this.$store.getters['service/getDatePick']
      },
      set (value) {
        this.$store.dispatch('service/setDatePick',value)
      }
    },
    getReloadStore:function(){
      return this.$store.getters['service/getReloadStore']
    },
    getCopyRef:function(){
      return this.$store.getters['customer/getQTCOPYREF']
    },
    getCreditBalance:function(){
      return this.$store.getters['customer/getQTCREDITBAL']
    },
    getQTSALESOURCE: function () {
      return this.$store.getters["auth/getQTSALESOURCE"];
    },
    getEPCUSTINFO: function () {
      return this.$store.getters["customer/getEPCUSTINFO"];
    },
    qtShopItemLimit: function() {
      return this.$store.getters["customer/getQTSHOPITEM_LIMIT"];
    },
    getDSMMSSTORE:function(){
      return this.$store.getters['service/getDSMMSSTORE']
    },
  },
  watch: {
    isLoading: function () {
      console.log('start check service');
      // this.checkSER();
    },
    getCallOrderInfo:function(){
      // console.log('start check service');
      // this.checkSER();
      // this.isLoading = true
    },
    getReloadStore: function () {
      setTimeout(() => { window.location.href = `../cart`; }, 500);
    },
    getReloadOrderInfo: function () {
      // this.isLoading = true
    },
  }

}
</script>

<style scoped>
.loading {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-image: url("/img/loadsmall.gif");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.checkout-detail-title {
  padding: 10px 15px;
  font-size: 1.2em;
  font-family: "kittithadabold75regular";
  margin-bottom: 0;
}

.checkout-line-title {
  padding: 10px 0 0 0;
  margin: 0px;
}

.checkout-card {
  margin-top: 10px;
  border-radius: 7px;
  background-color: white;
  height: auto;
  padding: 10px 20px;
}
.font-weight-bold{
  font-family: 'kittithadabold75regular';
}

.font-weight-normal{
  font-family: 'kittithadamedium65regular';
}


.switchter {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switchter input {
  opacity: 0;
  width: 0;
  height: 0;
}
.sliderter {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderter:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.sliderter {
  background-color: #55246c;
}

input:focus+.sliderter {
  box-shadow: 0 0 1px #55246c;
}

input:checked+.sliderter:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sliderter.roundter {
  border-radius: 34px;
}

.sliderter.roundter:before {
  border-radius: 50%;
}
</style>