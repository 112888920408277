<template>
    <section>
        <div class="shadowDiv" style='overflow:hidden;'>
            <div class='row m-0' style='width: 100%;'>
                <div class="col-12 p-0 m-0">
                    <div class="row m-0">
                        <div class="col-9 p-0 m-0">

                        </div>
                        <div class='col-3 p-0 m-0' >
                            <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row m-0 skeleton-box' style='width: 100%; height: 200px'>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'SlotBookingSkeleton'
}
</script>

<style scoped>
    .shadowDiv {
        margin: 0px;
        background-color: white;
        padding: 10px;
        border-radius: 7px;
        height: auto;
    }
    .shadowDiv:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
</style>