<template>
<section class='px-2 py-0'>
    <div class="row">
        <div class="col-12 checkout-card">
            <div class="row">
                <span class="font-kitti-bold mr-0 h6 checkout-detail-title" >หมายเหตุ</span>
            </div>
            <div class='row'>
                <textarea @change="updateRemark" rows="3" placeholder="Remark........." name="Remark" v-model="remarkData"></textarea>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
export default {
    name: 'RemarkCheckOut',
    data: () => {
        return {
            remarkData: ''
        }
    },
    mounted() {

    },
    methods: {
        async updateRemark(){
            try {
                const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                if(specialChars.test(this.remarkData)) {
                    throw new Error('Remark มีตัวอักษรพิเศษ')
                }
                let data={
                    REF:this.getRef ,
                    REMARK:this.remarkData ,
                    STCODE:this.getStore
                }
                let getAPI = await serviceAPI.call_API('post',`checkout/updateInfoScantrans`,data,'auth');
           

         
            } catch (error) {
                serviceMain.showErrorAlert(this,error)
            }
        }
    },
    computed: {
      getRef:function(){
        return this.$store.getters['customer/getQTCUSTREF']
      },
      getStore:function(){
        return this.$store.getters['service/getQTDFSTORE']
      },
      getCopyRef:function(){
        return this.$store.getters['customer/getQTCOPYREF']
      },
    },
}
</script>

<style scoped>
    textarea { 
        width: 100%; 
        margin: 10px; 
        padding: 5px; 
        border-width: 0; 
        resize: none;
    }
</style>