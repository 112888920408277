<template>
    <section >
        <div class='row m-0' style='margin: 0' >
        <br>
            <div class="col-4 col-sm-4 col-md-3 col-lg-3 pl-0 pr-1" v-if="isShowMasterTime">
                <div class="row m-0">
                    <div class="col-12 text-center">
                        <span style="font-family: 'kittithadabold75regular'">เวลา</span>
                    </div>
                    <div class="col-12">&nbsp;</div>
                    <div :class="`col-12 p-2 slotnamevfix-${slotNum}`" v-for="e in masterTimeslots" :key="e.timeslotCode">
                        <div :class="`btn btn-payment-${getBU} w-100 slotnamevfix`" :slotmastername="e.timeslotDesc" style="position: relative;">
                            <div class="w-100" style="position: absolute;top:50%;left: 50%;transform: translate(-50%,-50%) !important;-webkit-transform: translate(-50%,-50%) !important;">{{e.timeslotDesc}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8 col-sm-8 col-md-9 col-lg-9 pl-1 pr-3">
                <div class="row m-0 owl-carousel owl-theme bookingowl" :key="bookingslots">
                    <div v-for="bookingslot in bookingslots" :key="bookingslot.deliveryDate">
                        <div class="col px-1">
                            <div class="row m-0">
                                <div class="col-12 text-center"><span style="font-family: 'kittithadabold75regular'">{{bookingslot.delidays}}</span></div>
                                <div class="col-12 p-0" style="overflow: hidden;text-align: center;">{{bookingslot.delidaymonth}}</div>
                                <div class="w-100" v-for="timeslot in bookingslot.timeslots" :key="timeslot.timeSlotCode">
                                    <div class="col-12 p-2">
                                        <div :class="`bookingslotvfixbtn-${slotNum}`" v-if="timeslot.isAvailable == 'Y'">
                                            <button :slotcode="timeslot.timeSlotCode" :dlvdate="bookingslot.deliveryDate" :disabled="!getQTBOOKING" :slotname="timeslot.timeSlotName" :class="`btn btn-payment-${getBU} w-100 bookingslotvfixbtn`">
                                                <div v-if="getBU == 'bnb'">
                                                    <svg width="60" height="21" viewBox="0 0 66 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M31.9185 15.2174V9.75815C31.9185 9.75815 30.2663 3.92228 26.2633 2.24076H19.6243V14.6049H18.8732L18.9258 0H0V17.8158H3.64998C3.69125 16.9227 4.07048 16.08 4.70891 15.4628C5.34735 14.8456 6.19592 14.5012 7.0784 14.5012C7.96089 14.5012 8.80946 14.8456 9.44789 15.4628C10.0863 16.08 10.4656 16.9227 10.5068 17.8158H20.8071C20.8484 16.9227 21.2276 16.08 21.8661 15.4628C22.5045 14.8456 23.3531 14.5012 24.2355 14.5012C25.118 14.5012 25.9666 14.8456 26.605 15.4628C27.2435 16.08 27.6227 16.9227 27.664 17.8158H33V15.2174H31.9185Z" />
                                                        <path d="M7.07862 21C8.73359 21 10.0752 19.6408 10.0752 17.9641C10.0752 16.2874 8.73359 14.9282 7.07862 14.9282C5.42365 14.9282 4.08203 16.2874 4.08203 17.9641C4.08203 19.6408 5.42365 21 7.07862 21Z" />
                                                        <path d="M24.2354 21C25.8903 21 27.2319 19.6408 27.2319 17.9641C27.2319 16.2874 25.8903 14.9282 24.2354 14.9282C22.5804 14.9282 21.2388 16.2874 21.2388 17.9641C21.2388 19.6408 22.5804 21 24.2354 21Z" />
                                                        <path d="M21.2388 4.60317V8.74991C21.2388 8.81686 21.2518 8.88315 21.2771 8.945C21.3024 9.00685 21.3394 9.06304 21.3861 9.11038C21.4329 9.15772 21.4883 9.19527 21.5494 9.22089C21.6104 9.24651 21.6759 9.25969 21.742 9.25969H28.7603C28.8439 9.26062 28.9265 9.24024 29.0004 9.20042C29.0742 9.1606 29.137 9.10262 29.183 9.0318C29.229 8.96098 29.2567 8.87959 29.2635 8.7951C29.2704 8.71062 29.2561 8.62575 29.2222 8.54828C28.7115 7.35752 27.5511 5.01024 26.0416 4.16187C25.968 4.12082 25.8852 4.09984 25.8012 4.101H21.742C21.6098 4.10098 21.4829 4.15365 21.3888 4.24761C21.2946 4.34157 21.2407 4.46929 21.2388 4.60317Z" fill="white"/>
                                                        <!-- <path d="M14.983 2.77332H3.60498V13.0945H14.983V2.77332Z" fill="white"/> -->
                                                        <!-- <path d="M10.5819 3.64465C8.77571 3.64465 7.30746 4.83922 7.30746 6.3077V3.64465H4.57373V12.2197H7.30746V8.71585L8.89212 7.33107C8.94252 7.28179 9.00983 7.25425 9.07987 7.25425C9.14992 7.25425 9.21723 7.28179 9.26763 7.33107L10.3604 8.24411V7.39194C10.3604 7.35763 10.3738 7.32473 10.3978 7.30047C10.4217 7.27622 10.4542 7.26259 10.488 7.26259H11.01C11.0439 7.26259 11.0763 7.27622 11.1003 7.30047C11.1242 7.32473 11.1377 7.35763 11.1377 7.39194V12.2197H13.8526V6.3115C13.8601 4.83922 12.3919 3.64465 10.5819 3.64465Z"/> -->
                                                        <path d="M34.974 11.4246V9.71362H38.1146V6.573H39.8138V9.71362H42.9661V11.4246H39.8138V14.5652H38.1146V11.4246H34.974Z"/>
                                                        <path d="M65.3055 10.5C65.3055 16.021 60.8116 20.5 55.2638 20.5C49.7161 20.5 45.2222 16.021 45.2222 10.5C45.2222 4.97902 49.7161 0.5 55.2638 0.5C60.8116 0.5 65.3055 4.97902 65.3055 10.5Z" stroke="#CCCCCC" fill="white"/>
                                                        <path d="M57.455 6.84441C57.3256 6.97585 57.2532 7.15257 57.2532 7.33663C57.2532 7.52068 57.3256 7.6974 57.455 7.82884L58.5845 8.95391C58.7164 9.08275 58.8939 9.15491 59.0787 9.15491C59.2634 9.15491 59.4409 9.08275 59.5728 8.95391L62.2343 6.30297C62.5893 7.08432 62.6967 7.95487 62.5424 8.79861C62.3881 9.64235 61.9792 10.4192 61.3704 11.0256C60.7615 11.6321 59.9816 12.0393 59.1345 12.193C58.2874 12.3467 57.4134 12.2397 56.629 11.8861L51.7508 16.745C51.47 17.0247 51.0891 17.1819 50.6919 17.1819C50.2947 17.1819 49.9138 17.0247 49.633 16.745C49.3521 16.4652 49.1943 16.0858 49.1943 15.6902C49.1943 15.2946 49.3521 14.9152 49.633 14.6355L54.5111 9.77661C54.1561 8.99526 54.0487 8.12471 54.203 7.28097C54.3573 6.43724 54.7662 5.66039 55.375 5.05395C55.9839 4.44752 56.7638 4.0403 57.6109 3.88657C58.458 3.73284 59.332 3.8399 60.1164 4.19348L57.462 6.83738L57.455 6.84441Z" fill="white" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </div>
                                                <div v-else>
                                                    <svg width="66" height="21" viewBox="0 0 66 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M31.9185 15.2174V9.75815C31.9185 9.75815 30.2663 3.92228 26.2633 2.24076H19.6243V14.6049H18.8732L18.9258 0H0V17.8158H3.64998C3.69125 16.9227 4.07048 16.08 4.70891 15.4628C5.34735 14.8456 6.19592 14.5012 7.0784 14.5012C7.96089 14.5012 8.80946 14.8456 9.44789 15.4628C10.0863 16.08 10.4656 16.9227 10.5068 17.8158H20.8071C20.8484 16.9227 21.2276 16.08 21.8661 15.4628C22.5045 14.8456 23.3531 14.5012 24.2355 14.5012C25.118 14.5012 25.9666 14.8456 26.605 15.4628C27.2435 16.08 27.6227 16.9227 27.664 17.8158H33V15.2174H31.9185Z" />
                                                        <path d="M7.07862 21C8.73359 21 10.0752 19.6408 10.0752 17.9641C10.0752 16.2874 8.73359 14.9282 7.07862 14.9282C5.42365 14.9282 4.08203 16.2874 4.08203 17.9641C4.08203 19.6408 5.42365 21 7.07862 21Z" />
                                                        <path d="M24.2354 21C25.8903 21 27.2319 19.6408 27.2319 17.9641C27.2319 16.2874 25.8903 14.9282 24.2354 14.9282C22.5804 14.9282 21.2388 16.2874 21.2388 17.9641C21.2388 19.6408 22.5804 21 24.2354 21Z" />
                                                        <path d="M21.2388 4.60317V8.74991C21.2388 8.81686 21.2518 8.88315 21.2771 8.945C21.3024 9.00685 21.3394 9.06304 21.3861 9.11038C21.4329 9.15772 21.4883 9.19527 21.5494 9.22089C21.6104 9.24651 21.6759 9.25969 21.742 9.25969H28.7603C28.8439 9.26062 28.9265 9.24024 29.0004 9.20042C29.0742 9.1606 29.137 9.10262 29.183 9.0318C29.229 8.96098 29.2567 8.87959 29.2635 8.7951C29.2704 8.71062 29.2561 8.62575 29.2222 8.54828C28.7115 7.35752 27.5511 5.01024 26.0416 4.16187C25.968 4.12082 25.8852 4.09984 25.8012 4.101H21.742C21.6098 4.10098 21.4829 4.15365 21.3888 4.24761C21.2946 4.34157 21.2407 4.46929 21.2388 4.60317Z" fill="white"/>
                                                        <path d="M14.983 2.77332H3.60498V13.0945H14.983V2.77332Z" fill="white"/>
                                                        <path d="M10.5819 3.64465C8.77571 3.64465 7.30746 4.83922 7.30746 6.3077V3.64465H4.57373V12.2197H7.30746V8.71585L8.89212 7.33107C8.94252 7.28179 9.00983 7.25425 9.07987 7.25425C9.14992 7.25425 9.21723 7.28179 9.26763 7.33107L10.3604 8.24411V7.39194C10.3604 7.35763 10.3738 7.32473 10.3978 7.30047C10.4217 7.27622 10.4542 7.26259 10.488 7.26259H11.01C11.0439 7.26259 11.0763 7.27622 11.1003 7.30047C11.1242 7.32473 11.1377 7.35763 11.1377 7.39194V12.2197H13.8526V6.3115C13.8601 4.83922 12.3919 3.64465 10.5819 3.64465Z"/>
                                                        <path d="M34.974 11.4246V9.71362H38.1146V6.573H39.8138V9.71362H42.9661V11.4246H39.8138V14.5652H38.1146V11.4246H34.974Z"/>
                                                        <path d="M65.3055 10.5C65.3055 16.021 60.8116 20.5 55.2638 20.5C49.7161 20.5 45.2222 16.021 45.2222 10.5C45.2222 4.97902 49.7161 0.5 55.2638 0.5C60.8116 0.5 65.3055 4.97902 65.3055 10.5Z" stroke="#CCCCCC" fill="white"/>
                                                        <path d="M57.455 6.84441C57.3256 6.97585 57.2532 7.15257 57.2532 7.33663C57.2532 7.52068 57.3256 7.6974 57.455 7.82884L58.5845 8.95391C58.7164 9.08275 58.8939 9.15491 59.0787 9.15491C59.2634 9.15491 59.4409 9.08275 59.5728 8.95391L62.2343 6.30297C62.5893 7.08432 62.6967 7.95487 62.5424 8.79861C62.3881 9.64235 61.9792 10.4192 61.3704 11.0256C60.7615 11.6321 59.9816 12.0393 59.1345 12.193C58.2874 12.3467 57.4134 12.2397 56.629 11.8861L51.7508 16.745C51.47 17.0247 51.0891 17.1819 50.6919 17.1819C50.2947 17.1819 49.9138 17.0247 49.633 16.745C49.3521 16.4652 49.1943 16.0858 49.1943 15.6902C49.1943 15.2946 49.3521 14.9152 49.633 14.6355L54.5111 9.77661C54.1561 8.99526 54.0487 8.12471 54.203 7.28097C54.3573 6.43724 54.7662 5.66039 55.375 5.05395C55.9839 4.44752 56.7638 4.0403 57.6109 3.88657C58.458 3.73284 59.332 3.8399 60.1164 4.19348L57.462 6.83738L57.455 6.84441Z" fill="white" stroke="#CCCCCC" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                        <div :class="`bookingslotvfixbtn-${slotNum}`" v-else>
                                            <button :slotcode="timeslot.timeSlotCode" :dlvdate="bookingslot.deliveryDate" disabled :class="`btn btn-payment-${getBU} w-100 bookingslotvfixbtn`">
                                                <svg width="60" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="30" height="6" rx="3"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'TimeSlot',
    data: function () {
        return {
            isShowMasterTime: false,
            bookingItms:''
        }
    },
    props: {
        bookingslots: {
            type: Array,
            required: true
        },
        masterTimeslots: {
            type: Array,
            required: true
        },
        startDateBooking: {
            type: String,
            required: true
        },
        slotNum: {
            type: String,
            required: true
        },
    },
    mounted() {
        this.bookingItms = {
            code: '', 
            date: '',
            name: ''
        }
    },
    methods: {
        async startOwlCarousel(slot){
            let _this = this;
            $('.owl-carousel').owlCarousel({
                items:5,
                loop: false,
                nav: true,
                navText : ['<i class="fas fa-chevron-left fa" aria-hidden="true"></i>','<i class="fas fa-chevron-right fa" aria-hidden="true"></i>'],
                dots: false,
                autoplay:false,
                slideBy:5,
                navSpeed:50,
                responsive : {
                    0 : {
                        items:2,
                        slideBy:2,
                    },
                    480 : {
                        items:2,
                        slideBy:2,
                    },
                    768 : {
                        items:3,
                        slideBy:3,
                    },
                    992 : {
                        items:4,
                        slideBy:4,
                    },
                    1400 : {
                        items:5,
                        slideBy:5,
                    }
                }
            });

            $(`.bookingslotvfixbtn-${slot} > .bookingslotvfixbtn`).click(function(){
                // console.log(slot);
                $(this).blur();
                $(`.bookingslotvfixbtn-${slot} > .bookingslotvfixbtn`).removeClass("active");
                $(this).addClass("active");
                $(`.slotnamevfix-${slot} > .slotnamevfix`).removeClass("active");
                $(`.slotnamevfix-${slot}`).find("[slotmastername='"+$(this).attr("slotname")+"']").addClass("active");

                _this.bookingItms = {
                    code: $(this).attr("slotcode"), 
                    date: $(this).attr("dlvdate"),
                    name: $(this).attr("slotname")
                }
            });

            this.isShowMasterTime = true;
            this.setCompleteTimeSlot();
        },
        async setHideMasterTime() {
            this.isShowMasterTime = false
        },
        async setCompleteTimeSlot() {
            this.$emit("completeSlotTime", true)
        },
    },
    computed:{
        getBuColor:function(){
            let color = '';
            switch(this.getBU) {
                case 'bnb':
                    color = '#006e63';
                    break;
                case 'at1':
                    color = '#3866af';
                    break;
                default:
                    color = '#55246c';
            }
            return color;
        },
        getBU:function(){
            return this.$store.getters['customer/getQTBU']
        },
        // bookingItms: {
        //     get () {
        //         this.$store.getters['service/getBookingItms'];
        //     },
        //     set (value) {
        //         this.$store.dispatch('service/setBookingItms',value)
        //     }
        // },
        slotTimeSelect: function() {
            if(this.bookingItms.code){
                return true
            }
            return false
        },
        bookingItems: function() {
            return this.bookingItms;
        },
        getQTCHKP: function () {
            return this.$store.getters["service/getQTCHKP"];
        },
        getQTBOOKING: function () {
            return this.$store.getters["auth/getQTBOOKING"];
        },
    },
    watch: {
        bookingslots: function () {
            this.$nextTick(() => {
                this.isShowMasterTime = false;
                if(this.bookingslots.length > 0) this.startOwlCarousel(this.slotNum);
            })
        },
        masterTimeslots: function () {
            this.setHideMasterTime();
        },
        slotTimeSelect: function() {
            this.$emit("slotTimeSelect", this.slotTimeSelect);
        },
        bookingItems: function() {
            this.$emit("bookingItems", this.bookingItems);
        },
    }
}
</script>

<style scope lang="scss">
    $color-PWB: #55246c;
    $color-bnb: #006e63;
    $color-at1: #3866af;
    .btn-payment-PWB,
    .btn-payment-bnb,
    .btn-payment-at1{
        color: #666666;
        background-color: white;
        font-weight:700;
        font-size: 18px;
        padding: 0;
        border: 1px solid #BBBBBB;
        height: 66px;
        line-height: 1em;
    }
    .btn-payment-PWB:focus,
    .btn-payment-bnb:focus,
    .btn-payment-at1:focus
    {
        outline: none;
        box-shadow: none;
    }
    .btn-payment-PWB svg,
    .btn-payment-bnb svg,
    .btn-payment-at1 svg{
        fill:#CCCCCC;
    }
    .btn-payment-PWB:not(.disable):not(.active):hover svg{fill:$color-PWB;}.btn-payment-PWB.active svg>path:not([fill="white"]){fill:white;}.btn-payment-PWB.active svg>[fill="white"]{fill:$color-PWB;}
    .btn-payment-bnb:not(.disable):not(.active):hover svg{fill:$color-bnb;}.btn-payment-bnb.active svg>path:not([fill="white"]){fill:white;}.btn-payment-bnb.active svg>[fill="white"]{fill:$color-bnb;}
    .btn-payment-at1:not(.disable):not(.active):hover svg{fill:$color-at1;}.btn-payment-at1.active svg>path:not([fill="white"]){fill:white;}.btn-payment-at1.active svg>[fill="white"]{fill:$color-at1;}

    .btn-payment-PWB>span,
    .btn-payment-bnb>span,
    .btn-payment-at1>span{
        display: inline;
    }
    .btn-payment-PWB:not(.disable):not(:disabled):hover{
        border: 1px solid $color-PWB;
    }
    .btn-payment-bnb:not(.disable):not(:disabled):hover{
        border: 1px solid $color-bnb;
    }
    .btn-payment-at1:not(.disable):not(:disabled):hover{
        border: 1px solid $color-at1;
    }
    .btn-payment-PWB.active:not(:disabled){
        color: white;
        background-color: $color-PWB;
        border: 1px solid $color-PWB;
    }
    .btn-payment-bnb.active:not(:disabled){
        color: white;
        background-color: $color-bnb;
        border: 1px solid $color-bnb;
    }
    .btn-payment-at1.active:not(:disabled){
        color: white;
        background-color: $color-at1;
        border: 1px solid $color-at1;
    }
    .bookingowl .owl-prev{
        position: absolute;
        top: .7rem;
        left: -1rem;
        display: block;
        color: black !important;
        opacity: .3;
        z-index: 99;
        cursor: pointer;
        transform: translate(0, -50%);
        background: none !important;
        outline: none !important;
    }
    .bookingowl .owl-prev.disabled{
        display: none !important;
    }
    .bookingowl .owl-prev:hover{
        opacity: .9;
        background: none;
    }
    .bookingowl .owl-next{
        position: absolute;
        top: .7rem;
        right: -1rem;
        display: block;
        color: black !important;
        opacity: .3;
        z-index: 99;
        cursor: pointer;
        transform: translate(0, -50%);
        background: none !important;
        outline: none !important;
    }
    .bookingowl .owl-next.disabled{
        display: none !important;
    }
    .bookingowl .owl-next:hover{
        opacity: .9;
    }
    .bookingslotvfixbtn{
        padding: .3em;
        color: #2a2a2a;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a2a2a;
        width: 100%;
        background-color: white;
    }
    .bookingslotvfixbtn:hover{
        padding: .3em;
        color: white;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a2a2a;
        width: 100%;
        background-color: #2a2a2a;
    }
    .bookingslotvfixbtn.active{
        padding: .3em;
        color: white;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a2a2a;
        width: 100%;
        background-color: #2a2a2a;
    }

    .btnPrevNext{
        border: none;
        color: white;
        padding: 0px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 0.5rem;
        cursor: pointer;
        margin: 0px 2px;
        border-radius:4px;
    }
</style>