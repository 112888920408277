<template>
  <section v-if="itmTran" >
    <div v-show="reloadSlot">
      <button v-if="canReload || canReloadS" @click="reloadWindowVfix()" :class="`btn-visible-PWB bg-${qtBu}`" >ขอจองรอบรถ</button>
      <div class="row mb-3" v-if="getQTBOOKING" v-show="false">
        <div class="col-12 cardWhite">
          <div class="row"><span class='fnt-header' style="font-size:1.3rem;">เลือกแพคเกจหลัก</span></div>
          <select class='form-control' @change='chgPickPackageMain()' :key='dlvTrType' v-model='dlvTrTypeAll'>
            <option value=''>กรุณาเลือกประเภทการจัดส่ง</option>
              <!-- <option v-show="!isXmas" :value='dlvTpeOpt.KER'>KER</option>
              <option v-show="!isXmas || (isXmas && getBU == 'bnb')" :value='dlvTpeOpt.DIY'>DIY</option>
              <option v-show="!isXmas" :value='dlvTpeOpt.CON'>CON</option>
              <option v-show="!isXmas" :value='dlvTpeOpt.SER'>SER</option> -->
              <optgroup label="รับสินค้า">
                <!-- <option :value='dlvTpeOpt.PICKNOW' v-show="!isUnusePickupNow">รับของทันที</option> -->
                <option v-if="pickFlag" :value='dlvTpeOpt.PICK'>รับที่สาขา</option>
              </optgroup>
              <optgroup label="ส่งถึงบ้าน">
                <!-- <option v-show="!isXmas && getQTBOOKING" :value='dlvTpeOpt.COM'>จับคู่แพคเกจ(ไม่สามารถเลือกแพคเกจที่เลือกจับคู่กับแพคเกจอื่นได้)</option> -->
                <option v-if="shipFlag" v-show="!checkIsXmasItem" :value='dlvTpeOpt.KER'>3PL</option>
                <option v-show="!checkIsXmasItem" :value='dlvTpeOpt.DIY'>OWF</option>
                <!-- <option v-show="!checkIsXmasItem" :value='dlvTpeOpt.CON'>CON</option> -->
                <!-- <option v-show="!checkIsXmasItem  && (dcsAvailable || getQTSALESOURCE == 'UL')" :value='dlvTpeOpt.DCS'>DC</option> -->
                <option v-show="!checkIsXmasItem" :value='dlvTpeOpt.SER'>SER</option>
                <!-- <option v-show="checkIsXmasItem" :value='dlvTpeOpt.CRI'>CRI</option> -->
              </optgroup>
          </select><br>
          <div v-if='[dlvTpeOpt.SER].includes(dlvTrTypeAll)' >
            <select class='form-control' @change='chgPickPackageAll();' :key='pickPackageAll' v-model='pickPackageAll'>
              <option value=''>กรุณาเลือกแพคเกจหลัก</option>
              <option v-for="item in listPackageSER" v-bind:key="item.SLOTNUM" v-bind:value="item.SLOTNUM">แพคเกจ{{ item.SLOTNUM+1 }} - {{ item.PRNAME }} - {{ item.SKC }}</option>
            </select>
          </div>
          <div v-if='[dlvTpeOpt.KER, dlvTpeOpt.DIY, dlvTpeOpt.CON, dlvTpeOpt.DCS, dlvTpeOpt.COM].includes(dlvTrTypeAll)' >
            <select class='form-control' @change='chgPickPackageAll();' :key='pickPackageAll' v-model='pickPackageAll'>
              <option value=''>กรุณาเลือกแพคเกจหลัก</option>
              <option v-for="item in listPackageDLV" v-bind:key="item.SLOTNUM" v-bind:value="item.SLOTNUM">แพคเกจ{{ item.SLOTNUM+1 }} - {{ item.PRNAME }} - {{ item.SKC }}</option>
            </select>
          </div>
          <div v-if='[dlvTpeOpt.CRI].includes(dlvTrTypeAll)' >
            <select class='form-control' @change='chgPickPackageAll();' :key='pickPackageAll' v-model='pickPackageAll'>
              <option value=''>กรุณาเลือกแพคเกจหลัก</option>
              <option v-for="item in listPackageDLV" v-bind:key="item.SLOTNUM" v-bind:value="item.SLOTNUM" v-show="skuChrismas.includes(item.SKC)">แพคเกจ{{ item.SLOTNUM+1 }} - {{ item.PRNAME }} - {{ item.SKC }}</option>
            </select>
          </div>
          <div v-show="false" v-if="dlvTrTypeAll=='PICKNOW'"><SelectStoreDLV :pick="'Y'" :trfrom ="getStore" @selectedStore="selectedStore"/></div>
        </div>
      </div>
      <div class="row mb-3" v-if="!getQTBOOKING">
        <div class="col-12 cardWhite">
          <div class="row"><span class='fnt-header' style="font-size:1.3rem;">เลือกแพคเกจหลัก</span></div>
          <select class='form-control' @change='chgPickPackageAllQTBOOKING()' :key='dlvTrType' v-model='dlvTrTypeAll'>
            <option value=''>เลือกรับของรายสินค้า</option>
              <!-- <option v-show="!isXmas" :value='dlvTpeOpt.KER'>KER</option>
              <option v-show="!isXmas || (isXmas && getBU == 'bnb')" :value='dlvTpeOpt.DIY'>DIY</option>
              <option v-show="!isXmas" :value='dlvTpeOpt.CON'>CON</option>
              <option v-show="!isXmas" :value='dlvTpeOpt.SER'>SER</option> -->
            <optgroup label="รับสินค้า">
              <option :value='dlvTpeOpt.PICKNOW'>รับของทันที</option>
              <option :value='dlvTpeOpt.PICK'>รับที่ ไทวัสดุ สาขา {{!$store.getters['auth/getISTB'] ? getQTSTORENAME : $store.getters['customer/getEPCUSTINFO'].STNAME}}</option>
            </optgroup>
            <!-- <optgroup label="ส่งถึงบ้าน" > -->
              <!-- <option v-show="!isXmas && getQTBOOKING" :value='dlvTpeOpt.COM'>จับคู่แพคเกจ(ไม่สามารถเลือกแพคเกจที่เลือกจับคู่กับแพคเกจอื่นได้)</option> -->
              <!-- <option v-show="!checkIsXmasItem" :value='dlvTpeOpt.KER'>KER</option>
              <option v-show="!checkIsXmasItem || (checkIsXmasItem && getBU == 'bnb')" :value='dlvTpeOpt.DIY'>DIY</option>
              <option v-show="!checkIsXmasItem" :value='dlvTpeOpt.CON'>CON</option>
              <option v-show="!checkIsXmasItem" :value='dlvTpeOpt.SER'>SER</option>
              <option v-show="checkIsXmasItem && getBU != 'bnb'" :value='dlvTpeOpt.CRI'>CRI</option>
            </optgroup> -->
          </select>
        </div>
      </div>
      <div v-if="dlvTrTypeAll=='PICK'" class="row">
        <div class="col-12">
          <PickupatStore @settrsendpickup="settrsendpickup"/>
        </div>
      </div>
      <div v-if="!getQTBOOKING && getQTSALESOURCE != 'U1'&& !$store.getters['auth/getISTB'] && false" class="row mb-3">
        <div class="col-12">
          <ShowSlotQTBOOKING :orderItems="orderItems"/>
        </div>
      </div>
      <div v-if="!getQTBOOKING && getQTCHKP == 'Delivery'" :key="dlvTrTypeAll">
        <div v-for="(e, index) in itmTranPicklist" :key="index">
          <div :key="e">
            <SlotPickup :ref="`SlotPickupRef${index}`" :orderItems="orderItems" :itmTran="e" :slotNum="index" :getDlvTrType="getDlvTrType[index]" :getPickPackage="getPickPackage[index]" :changePick="changePick" @fndlvTrType="fndlvTrType" @fnlistPickDate="fnlistPickDate" :canShowSlot="canShowSlot" />
          </div>
          <br>
        </div>
      </div>
      <div :key="dlvTrTypeAll">
        <div v-for="(e, index) in itmTran" :key="index">
          <div :key="e">
            <SlotBookingDlv :ref="`SlotBookingDlvRef${index}`" v-if="e.tsptype != 'SER' && getQTCHKP == 'Delivery' && getQTBOOKING" :orderItems="orderItems" :itmTran="e" :slotNum="index" :listPackageSER="listPackageSER"  :dcsAvailable="dcsAvailable" :dataAddress="dataAddress"
            :listPackageSERPic="listPackageSERPic" :listPackageDLV="listPackageDLV" :listPackageDLVPic="listPackageDLVPic" :listBookingID="listBookingID" @dlvType="dlvType" @csvoidBooking="csvoidBooking" @fnlistPackageSERPic="fnlistPackageSERPic"  @fnliststcdeli="fnliststcdeli" :listdata="listdata"
            @fnlistPackageDLVPic="fnlistPackageDLVPic" @fnlistBookingID="fnlistBookingID" :getDlvTrType="getDlvTrType[index]" :getPickPackage="getPickPackage[index]" :changePick="changePick" @fndlvTrType="fndlvTrType" @fnlistPickDate="fnlistPickDate" :canShowSlot="canShowSlot" 
            :listdlvTrTypePWB="listdlvTrTypePWB" />
            <SlotBookingVFix :ref="`SlotBookingVFixRef${index}`" v-else-if="e.tsptype == 'SER'" :orderItems="orderItems" :itmTran="e" :slotNum="index" @dlvSER="dlvSER"  @csvoidBooking="csvoidBooking" @fnlistBookingID="fnlistBookingID" :canShowSlot="canShowSlot" 
            :listPackageSER="listPackageSER" :listBookingID="listBookingID" :dataAddress="dataAddress"/>
          </div>
          <br>
        </div>
      </div>
    </div>
    <div v-show="!reloadSlot">
      <SlotBookingSkeleton />
    </div>
  </section>
</template>

<script>
import * as serviceAPI from "@/services/API.service";
import * as serviceMain from "@/services/main.service";
import SlotBookingDlv from "@/components/CheckOut/SlotBooking/SlotBookingDlv.vue";
import SlotBookingVFix from "@/components/CheckOut/SlotBooking/SlotBookingVFix.vue";
import ShowSlotQTBOOKING from "@/components/CheckOut/SlotBooking/ShowSlotQTBOOKING.vue";
import PickupatStore from '@/components/CheckOut/PickupatStore.vue';
import SlotPickup from "@/components/CheckOut/SlotBooking/SlotPickup.vue";
import SlotBookingSkeleton from "@/components/CheckOut/SlotBooking/SlotBookingSkeleton.vue";
import SelectStoreDLV from "@/components/CheckOut/SlotBooking/SelectStoreDLV.vue";

export default {
  name: "SlotBookingVFIX",
  components: {
    SlotBookingDlv,
    SlotBookingVFix,
    PickupatStore,
    SlotBookingSkeleton,
    ShowSlotQTBOOKING,
    SlotPickup,
    SelectStoreDLV
  },
  data: function() {
    return {
      dlvTpeOpt: {
        KER: 'KER',
        DIY: 'DIY',
        CON: 'CON',
        DCS: 'DCS',
        CRI: 'CRI',
        VEN: 'VEN',
        SER: 'SER',
        COM: 'COM',
        PICK: 'PICK',
        PICKNOW: 'PICKNOW',
      },
      itmTran: {},
      dlvTrType: '',
      bookingSlots: [],
      masterTimeSlots: [],
      flee: 0,
      startDateBooking: '',
      errSaveTrans: false,
      errStoreBooking: false,
      deliDaysOfWeek: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
      deliMonthThai: ["ม.ค.","ก.พ.","มี.ค.","ม.ษ.","พ.ค.","มิ.ย.","ก.ค.","ส.ค.","ก.ย.","ต.ค.","พ.ย.","ธ.ค."],
      lastSlotDate: '',
      firstSlotDate: '',
      isShowSlot: false,
      isShowDesc: false,
      isShowNextPrev: false,
      isLoading: true,
      skuChrismas: ['60284074','60284075','60300086','60300087','60330707','60353328'],
      isXmas: false,
      servicnotgowithepickupdatas:[],
      longtailpickarr:[],
      itmTranSer:'',
      itmTranDlv:'',
      itmTranPick:'',
      itmTranPicklist:'',
      dlvDetail:[],
      dlvDetailIndex:'',
      allDlv:[],
      canVoidVFix:false,
      CurrentBooking:'',
      bookingIDItm:[],
      haveSer:false,
      listPackageSER:[],
      listPackageSERPic:[],
      listPackageDLV:[],
      listPackageDLVPic:[],
      listBookingID:[],
      listdlvTrType:[],
      listPickDate:[],
      canReloadS: false,
      conPass: { conPass: true,
                  errorMsg: ''
                },
      dlvTrTypeAll:'',
      pickPackageAll:'',
      sendPickage:[],
      getDlvTrType:[],
      getPickPackage:[],
      changePick: Date.now(),
      storeUnusePickupNow: ['UL', 'UC'],
      isUnusePickupNow: false,
      reloadSlot: true,
      reloadSlotTime: '',
      salesourceShowSlot: ['US', 'UP'],
      canShowSlot: false,
      dcsAvailable: false,
      trsendpickup: '',
      listdlvTrTypePWB:[],
      listdata:[],
    }
  },
  props:[
    "orderItems",
    "canReload",
    "dataAddress"
  ],
  mounted() {
    console.log('start book');
    this.setTimeReload()
    if(this.getQTBOOKING && this.getQTSALESOURCE != 'U1'&& !this.$store.getters['auth/getISTB']){
      setTimeout(() => {
        this.getTransportSpecv2();
      }, 1000) 
    }else{
      setTimeout(() => {
        this.getPickupSpecV2();
      }, 1000) 
    }
    // this.dlvTrType = this.QTDLVTRTYPE;
    // if(!this.dlvTrType) return;
    // if(this.getQTCHKP == 'Delivery'){
    //   setTimeout(() => {
    //     this.getTransportSpecv2();
    //   }, 1000) 
    //   // this.getTransportSpecv2();
    // }
    // if(this.getQTCHKP == 'Pickup' || this.getQTCHKP == 'PickupNow'){

    //   setTimeout(() => {
    //     this.getPickupSpecV2()
    //   }, 1000)
    //   // this.getPickupSpecV2();
    // }
    this.getCurrentBooking();
    this.getQTPDLV = ''
    this.isUnusePickupNow = this.storeUnusePickupNow.includes(this.getQTSALESOURCE) || this.storeUnusePickupNow.includes(this.getStore);
    this.canShowSlot = this.salesourceShowSlot.includes(this.getQTSALESOURCE)
    setTimeout(() => {
      if(this.getQTSALESOURCE == 'U1'){
        this.dlvTrTypeAll = this.dlvTpeOpt.PICKNOW
        this.chgPickPackageAllQTBOOKING()
      }else if(this.$store.getters['auth/getISTB']){
        this.dlvTrTypeAll = this.dlvTpeOpt.PICK
        this.chgPickPackageAllQTBOOKING()
      }
    }, 2000) 
    
    
  },
  methods: {
    async getTransportSpecv2() {
      try {
        console.log('TransportSpec');
        this.itmTran = [];
        this.itmTranSer = [];
        this.itmTranDlv = [];
        this.listPackageSER = [];
        this.listPackageDLV = [];
        this.listdlvTrType = [];
        this.listdlvTrTypePWB = [];
        this.isLoading = true
        // this.getQTPDLV = ''
        let data = {
          REF: this.getQTCUSTREF
        }
        let tranData = await serviceAPI.call_API('post', 'checkout/getTransportSpecv2', data,'auth');
        if(tranData.data.dbrows > 0) {
          this.itmTran = tranData.data.dbitems;
          this.itmTranSer = tranData.data.dbitems.filter(pk=>pk.tsptype.trim() == "SER")
          this.itmTranDlv = tranData.data.dbitems.filter(pk=>pk.tsptype.trim() != "SER")
          for (let i = 0; i < this.itmTran.length; i++) {
            if(this.itmTran[i].tsptype.trim() == "SER"){
              this.listPackageSER.push({
                SLOTNUM: i,
                SKC: JSON.parse(this.itmTran[i].jsnskulist)[0].SKC,
                PRNAME: JSON.parse(this.itmTran[i].jsnskulist)[0].PRNAME,
                SEQ: this.itmTran[i].seq
              })
            } else {
              this.listPackageDLV.push({
                SLOTNUM: i,
                SKC: JSON.parse(this.itmTran[i].jsnskulist)[0].SKC,
                PRNAME: JSON.parse(this.itmTran[i].jsnskulist)[0].PRNAME,
                SEQ: this.itmTran[i].seq
              })
            }
          }
          if(this.itmTranSer != ''){
            this.haveSer = true;
          }
          this.DCSCoverageArea();
        } else {
          this.$swal.fire({
            html:
              "<h2>กรุณาเพิ่มที่อยู่ของท่านก่อนการชำระเงินด้วยครับ</h2>",
            showCancelButton: false,
            confirmButtonColor: this.getBuColor,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: "ตกลง",
          }).then((result) => {
            if (result.value) {
            }
          });
        }
        this.$store.dispatch('service/setReloadOrderInfo')
        // console.log(this.itmTran);
      } catch (error) {
        console.log('getTransportSpecv2');
        this.isLoading = false;
        this.canReloadS = true;
        if(error.message == "Invalid object name 'SORDER'."){
          // location.reload(true);
          return
        }
        serviceMain.showErrorAlert(this,error)
      }
    },

    async getPickupSpecV2() {
      try {
        console.log('PickupSpec');
        this.itmTran = [];
        this.itmTranSer = [];
        this.itmTranDlv = [];
        this.itmTranPick = [];
        this.itmTranPicklist = [];
        this.isLoading = true
        // this.getQTPDLV = ''
        let data = {
          REF: this.getQTCUSTREF
        }
        let tranData = await serviceAPI.call_API('post', 'checkout/getPickupSpecV2', data,'auth');
        if(tranData.data.dbrows > 0) {
          this.itmTran = tranData.data.dbitems;
          this.itmTranSer = tranData.data.dbitems.filter(pk=>pk.tsptype.trim() == "SER")
          this.itmTranPick = tranData.data.dbitems.filter(pk=>pk.tsptype.trim() != "SER")
          // console.log(this.itmTranPick[0]);
          this.itmTranPicklist = this.itmTranPick[0] ? JSON.parse(this.itmTranPick[0].jsnskulist) : []
        }
        this.$store.dispatch('service/setReloadOrderInfo')
      } catch (error) {
        console.log('getPickupSpecV2');
        this.isLoading = false;
        this.canReloadS = true;
        if(error.message == "Invalid object name 'SORDER'."){
          // location.reload(true);
          return
        }
        serviceMain.showErrorAlert(this,error)
      }
    },

    reloadWindowVfix(){
      location.reload(true);
    },

    async getCurrentBooking() {
        try {
            let data = {
                ref: this.getQTCUSTREF,
            }
            let getAPI = await serviceAPI.call_API('post', `history/getCurrentBookingV2`, data, 'auth');
            let getsorder = getAPI.data.dbitems[0];
            this.CurrentBooking = getsorder
            this.canVoidVFix = true
            let bookingID = JSON.parse(this.CurrentBooking.Booking_id)
            let bookingIDItms = []
            this.bookingIDItm = []
            for (let i = 0; i < bookingID.length; i++) {
                if(!bookingIDItms.includes(bookingID[i].Booking_id)){
                    bookingIDItms.push(bookingID[i].Booking_id)
                }
            }
            // console.log(bookingIDItms);
            this.bookingIDItm = bookingIDItms
        } catch (error) {
            this.canVoidVFix = false
            // serviceMain.showErrorAlert(this, error.message)
        }
    },

    async DCSCoverageArea() {
      try {
        let data = {
          storeCode: this.itmTran[0].trfrom,
          postalCode: '',
          lat: '',
          lon: ''
        }
        let getAPI = await serviceAPI.call_API('post', 'checkout/DCSCoverageArea', data,'auth');
        let stsres = getAPI.data.data
        if (stsres && stsres.errorCode == ""){
          this.dcsAvailable = stsres.dcsAvailable == 'Y'?true:false
        }  
      } catch(error) {
        throw new Error(error)
      }
    },

    async dlvType(val) {
      // console.log(val);
      this.allDlv[val[1]] = val[0]
    },

    async dlvSER(val) {
      // console.log(val);
      this.allDlv[val[1]] = val[0]
    },

    async settrsendpickup(val) {
      // console.log(val);
      this.trsendpickup = val
    },

    async fnlistPackageSERPic(val) {
      try{
        if(val[0] === ''){
          this.listPackageSERPic[val[1]] = ''
          // throw new Error(`เลือกแพคเกจซ้ำ กรุณาเลือกใหม่`)
        }else{
          for (let i = 0; i < this.listPackageSER.length; i++) {
            if(this.listPackageSER[i]){
              if(this.listPackageSER[i].SLOTNUM == val[0]){
                this.listPackageSERPic[val[1]] = this.listPackageSER[i]
              }
            }
          }
        }
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },

    async fnlistPackageDLVPic(val) {
      try{
        if(val[0] === ''){
          this.listPackageDLVPic[val[1]] = ''
          // throw new Error(`เลือกแพคเกจซ้ำ กรุณาเลือกใหม่`)
        }else{
          for (let i = 0; i < this.listPackageDLV.length; i++) {
            if(this.listPackageDLV[i]){
              if(this.listPackageDLV[i].SLOTNUM == val[0]){
                this.listPackageDLVPic[val[1]] = this.listPackageDLV[i]
              }
            }
          }
        }
        // console.log(this.listPackageDLVPic[val[1]]);
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },

    async fnlistBookingID(val) {
      try{
        this.listBookingID[val[1]] = val[0]
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },

    async fndlvTrType(val) {
      try{
        this.listdlvTrType[val[1]] = val[0]
        this.listdlvTrTypePWB[val[1]] = {type:val[0],skulist:val[2]}
        this.$emit("fnlistdlvTrType", this.listdlvTrType);
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },

    async fnlistPickDate(val) {
      try{
        this.listPickDate[val[1]] = val[0]
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },

    
    async fnliststcdeli(val) {
      try{
        this.listdata[val[1]] = val[0]
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },

    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    
    async reserveBooking() {
      let reserveBookingDlv,reserveBookingVFix
      let reserveBooking = ''
      let packageFull = ''
      let packageNotAva = ''
      let packagPass = true
      this.listBookingID = []
      if(this.canVoidVFix){
        await this.csvoidAllBooking();
      }
      if(this.getQTBOOKING){
        if(this.itmTran.length == 0){
          return {
            packagPass : false,
            packageFull : '',
            packageNotAva : '',
            refresh : 'ไม่สามารถยืนยันการจองรอบรถได้ กรุณารีโหลดหน้าเว็บไซต์'
          }
        }
        for (let i = 0; i < this.itmTran.length; i++) {
          // console.log('reserveBooking');
          let slotRef = this.itmTran[i].tsptype.trim() == "SER" ? 'SlotBookingVFixRef'+i : 'SlotBookingDlvRef'+i
          if(this.itmTran[i].tsptype.trim() == "SER"){
            // console.log('reserveBookingSER');
            reserveBooking = await this.$refs[slotRef].reserveBookingVFix()
          }else if(this.itmTran[i].tsptype.trim() != "SER" && this.getQTCHKP == 'Delivery'){
            // console.log('reserveBookingDLV');
            reserveBooking = await this.$refs[slotRef].reserveBookingDlv()
          }
          if(reserveBooking){
            if(reserveBooking.status == 2){
              packagPass =  false
              let slotN = i+1
              packageFull = packageFull == '' ? slotN : packageFull + ',' + slotN
            }else if(reserveBooking.status != 1){
              packagPass =  false
              let slotN = i+1
              packageNotAva = packageNotAva == '' ? slotN : packageNotAva + ',' + slotN
            }
          }
        }
      }
      return {
        packagPass : packagPass,
        packageFull : packageFull,
        packageNotAva : packageNotAva
      }
    },

    async confirmBookingV2() {
      let confirmBookingV2 = ''
      let verBookingV2 = ''
      let packageFull = ''
      let packageNotAva = ''
      let packagPass = true
      this.conPass = { conPass: true,
                      errorMsg: ''
      }
      if(this.getQTBOOKING){
        confirmBookingV2 = true
        verBookingV2 = true
        if(this.itmTran.length == 0){
          return { conPass: false,
                    errorMsg: 'ไม่สามารถยืนยันการจองรอบรถได้ กรุณารีโหลดหน้าเว็บไซต์'
            }
        }
        for (let i = 0; i < this.itmTran.length; i++) {
          let slotRef = this.itmTran[i].tsptype.trim() == "SER" ? 'SlotBookingVFixRef'+i : 'SlotBookingDlvRef'+i
          if(this.itmTran[i].tsptype.trim() == "SER"){
          }else if(this.itmTran[i].tsptype.trim() != "SER" && this.getQTCHKP == 'Delivery'){
            console.log('verBookingV2DLV');
            verBookingV2 = await this.$refs[slotRef].verBookingV2Dlv()
          }
          if(!verBookingV2){
            this.conPass = { conPass: false,
                      errorMsg: 'ไม่สามารถยืนยันการจองรอบรถได้'
            }
          }
        }
        for (let i = 0; i < this.itmTran.length; i++) {
          // console.log('confirmBookingV2');
          let slotRef = this.itmTran[i].tsptype.trim() == "SER" ? 'SlotBookingVFixRef'+i : 'SlotBookingDlvRef'+i
          if(this.itmTran[i].tsptype.trim() == "SER"){
            // console.log('confirmBookingV2SER');
            confirmBookingV2 = await this.$refs[slotRef].confirmBookingV2VFix()
          }else if(this.itmTran[i].tsptype.trim() != "SER" &&  this.getQTCHKP == 'Delivery'){
            // console.log('confirmBookingV2DLV');
            confirmBookingV2 = await this.$refs[slotRef].confirmBookingV2Dlv()
          }
          if(!confirmBookingV2){
            this.conPass = { conPass: false,
                      errorMsg: 'ไม่สามารถยืนยันการจองรอบรถได้'
            }
          }
        }
        if(this.getQTCHKP == 'Pickup' || this.getQTCHKP == 'PickupNow'){
          await this.confirmPickup();
        }
      }
      if(!this.getQTBOOKING){
        // console.log(this.listPackageSER.length);
        for (let i = 0; i < this.itmTran.length; i++) {
          if(this.itmTran[i].tsptype == 'SER'){
            this.conPass = { conPass: false,
                             errorMsg: 'ไม่สามารถสร้างใบเสนอราคาได้เนื่องจากมีสินค้าบริการ'
            }
            return this.conPass
          }
        }
        if(this.getQTCHKP == 'Delivery'){
          // if(this.dlvQTBOOKING) await this.conQTBOOKING()
          // else this.conPass = { conPass: false,
          //             errorMsg: 'กรุณาเลือกวิธีการจัดส่ง'
          // }
          // console.log(this.dlvQTBOOKING);
          // console.log(this.conPass);
          let listPickup = JSON.parse(this.itmTranPick[0].jsnskulist)
          for (let i = 0; i < listPickup.length; i++) {
            if(!this.listdlvTrType[i]){
              this.conPass = { conPass: false,
                              errorMsg: 'กรุณาเลือกวิธีการจัดส่ง'
              }
              return this.conPass
            }
            if(this.listdlvTrType[i] == 'PICK' || this.listdlvTrType[i] == 'PICKNOW'){
              // console.log(i,!(this.listPickDate[i].Date && this.listPickDate[i].Time));
              if((this.listPickDate[i])){
                if(!(this.listPickDate[i].Date && this.listPickDate[i].Time)){
                  this.conPass = { conPass: false,
                                  errorMsg: 'กรุณาเลือก วัน/เวลา รับสินค้า'
                  }
                  return this.conPass
                }
              }else{
                this.conPass = { conPass: false,
                                 errorMsg: 'กรุณาเลือก วัน/เวลา รับสินค้า'
                }
                return this.conPass
              }
            }
          }
          await this.conQTBOOKING()


        }
        if(this.getQTCHKP == 'Pickup' || this.getQTCHKP == 'PickupNow'){
          await this.conQTBOOKINGPickup();
        }
      }
      return this.conPass
    },

    async confirmPickup() {
      try{
        let trackingno = '';
        let pickupDate = '';
        let listPickup = JSON.parse(this.itmTranPick[0].jsnskulist)
        let deliveryByStore = !this.$store.getters['auth/getISTB'] ? this.trsendpickup : this.$store.getters["service/getStcShowStock"]
        let pickupnow = this.getQTCHKP == 'PickupNow'
        pickupDate = this.getDatePick.split('-').reverse().join('/') + ' ' + this.getTimePick
        trackingno = `[{"carrier":"PICK","trackingNo":"","pickupDate":"${pickupDate}"}]`
        if(listPickup.length == 0){
          this.conPass = { conPass: false,
                         errorMsg: 'ไม่สามารถยืนยันการจองสินค้าได้ กรุณารีโหลดหน้าเว็บไซต์'
          }
        }
        if(deliveryByStore.length != 5){
          this.conPass = { conPass: false,
                         errorMsg: 'ไม่สามารถยืนยันการจองสินค้าได้ กรุณารีโหลดหน้าเว็บไซต์'
          }
        }
        for (let i = 0; i < listPickup.length; i++) {
          let skcodelist = `[{"SEQ":"${listPickup[i].SEQ}","SKC":"${listPickup[i].SKC}","SHIPTYP":"99","SHIPNME":"PICK","deliveryByStore":"${deliveryByStore}","deliveryStore":"${deliveryByStore}","pickupstore":"${this.trsendpickup}","usestockstore":"${deliveryByStore}"}]`
          let data = {
            REF: this.getQTCUSTREF,
            bookingId: '',
            trackingNo: trackingno,
            skcodelist: skcodelist,
            pickupnow: pickupnow
          }
          // console.log(data);
          let csconfirmBookingV2 = await serviceAPI.call_API('post', 'checkout/csconfirmBookingV2', data,'auth');
          if(csconfirmBookingV2.data.dbmessage != 'OK'){
            this.conPass = { conPass: false,
                             errorMsg: 'ไม่สามารถจองเลือกรับสินค้าที่สาขาได้'
            }
          }
        }
      } catch (error) {
        this.conPass = { conPass: false,
                         errorMsg: 'ไม่สามารถจองเลือกรับสินค้าที่สาขาได้'
        }
        // serviceMain.showErrorAlert(this, error)
      }
    },

    async conQTBOOKING() {
      try{
        console.log('conQTBOOKING');
        let listPickup = JSON.parse(this.itmTranPick[0].jsnskulist)
        if(listPickup.length == 0){
          this.conPass = { conPass: false,
                         errorMsg: 'ไม่สามารถยืนยันการจองสินค้าได้ กรุณารีโหลดหน้าเว็บไซต์'
          }
        }
        for (let i = 0; i < listPickup.length; i++) {
          let trackingno = '';
          let dlvTrTypeNum = ''
          let dlvTrTypeSend = ''
          let pickupDate = ''
          let pickupnow = false
          // let deliveryByStore = this.listPickDate[0].trsend
          let deliveryByStore = !this.$store.getters['auth/getISTB'] ? this.listPickDate[0].trsend : this.$store.getters["service/getStcShowStock"]
          // dlvTrTypeSend = this.listdlvTrType[i]
          dlvTrTypeSend = this.listdlvTrType[i]
          switch (this.listdlvTrType[i]) {
            case this.dlvTpeOpt.PICK :
              dlvTrTypeNum = 99
              dlvTrTypeSend = 'PICK'
              pickupDate = this.listPickDate[i].Date.split('-').reverse().join('/') + ' ' + this.listPickDate[i].Time
              trackingno = `[{"carrier":"PICK","trackingNo":"","pickupDate":"${pickupDate}"}]`
              break
            case this.dlvTpeOpt.PICKNOW :
              dlvTrTypeNum = 99
              dlvTrTypeSend = 'PICK'
              pickupnow = true
              pickupDate = this.listPickDate[i].Date.split('-').reverse().join('/') + ' ' + this.listPickDate[i].Time
              trackingno = `[{"carrier":"PICK","trackingNo":"","pickupDate":"${pickupDate}"}]`
              break
            default : 
            
          }
          // console.log(trackingno);
          let skcodelist = `[{"SEQ":"${listPickup[i].SEQ}","SKC":"${listPickup[i].SKC}","SHIPTYP":"${dlvTrTypeNum}","SHIPNME":"${dlvTrTypeSend}","deliveryByStore":"${deliveryByStore}","deliveryStore":"${deliveryByStore}","pickupstore":"Pickupstore","usestockstore":"usestockstore"}]`
          // console.log(skcodelist);
          let data = {
            REF: this.getQTCUSTREF,
            bookingId: '',
            trackingNo: trackingno,
            skcodelist: skcodelist,
            pickupnow: pickupnow

          }
          console.log(data);
          let csconfirmBookingV2 = await serviceAPI.call_API('post', 'checkout/csconfirmBookingV2', data,'auth');
          if(csconfirmBookingV2.data.dbmessage != 'OK'){
            this.conPass = { conPass: false,
                        errorMsg: 'ไม่สามารถยืนยันการจองสินค้าได้'
            }
          }
        }
      } catch (error) {
        this.conPass = { conPass: false,
                         errorMsg: 'ไม่สามารถยืนยันการจองสินค้าได้'
        }
        // serviceMain.showErrorAlert(this, error)
      }
    },

    async conQTBOOKINGPickup() {
      try{
        let trackingno = '';
        let pickupDate = '';
        let skcodelist = ''
        let pickupnow = false
        pickupDate = this.getDatePick.split('-').reverse().join('/') + ' ' + this.getTimePick
        // console.log(this.itmTranPick.length);
        if(this.getQTSALESOURCE == 'UO' && this.itmTranPick.length == 0){
          // console.log(this.orderItems);
          for (let i = 0; i < this.orderItems.length; i++) {
            let deliveryByStore = `60${this.getStore}`
            pickupnow = this.getQTCHKP == 'PickupNow'
            trackingno = `[{"carrier":"PICK","trackingNo":"","pickupDate":"${pickupDate}"}]`
            skcodelist = `[{"SEQ":"${this.orderItems[i].SEQ}","SKC":"${this.orderItems[i].SKC}","SHIPTYP":"99","SHIPNME":"PICK","deliveryByStore":"${deliveryByStore}","deliveryStore":"${deliveryByStore}","pickupstore":"Pickupstore","usestockstore":"usestockstore"}]`
            let data = {
              REF: this.getQTCUSTREF,
              bookingId: '',
              trackingNo: trackingno,
              skcodelist: skcodelist,
              pickupnow: pickupnow
            }
            console.log(data);
            let csconfirmBookingV2 = await serviceAPI.call_API('post', 'checkout/csconfirmBookingV2', data,'auth');
            if(csconfirmBookingV2.data.dbmessage != 'OK'){
              this.conPass = { conPass: false,
                          errorMsg: 'ไม่สามารถยืนยันจองเลือกรับสินค้าที่สาขาได้'
              }
            }
          }
          return
        }
        let listPickup = this.itmTranPick.length == 0 ? [] : JSON.parse(this.itmTranPick[0].jsnskulist)
        if(listPickup.length == 0){
          this.conPass = { conPass: false,
                         errorMsg: 'ไม่สามารถยืนยันการจองสินค้าได้ กรุณารีโหลดหน้าเว็บไซต์'
          }
        }
        for (let i = 0; i < listPickup.length; i++) {
          // let deliveryByStore = this.itmTran[0].trfrom
          let deliveryByStore = !this.$store.getters['auth/getISTB'] ? this.itmTran[0].trfrom : this.$store.getters["service/getStcShowStock"]
          pickupnow = this.getQTCHKP == 'PickupNow'
          trackingno = `[{"carrier":"PICK","trackingNo":"","pickupDate":"${pickupDate}"}]`
          skcodelist = `[{"SEQ":"${listPickup[i].SEQ}","SKC":"${listPickup[i].SKC}","SHIPTYP":"99","SHIPNME":"PICK","deliveryByStore":"${deliveryByStore}","deliveryStore":"${deliveryByStore}","pickupstore":"Pickupstore","usestockstore":"usestockstore"}]`
          let data = {
            REF: this.getQTCUSTREF,
            bookingId: '',
            trackingNo: trackingno,
            skcodelist: skcodelist,
            pickupnow: pickupnow
          }
          console.log(data);
          let csconfirmBookingV2 = await serviceAPI.call_API('post', 'checkout/csconfirmBookingV2', data,'auth');
          if(csconfirmBookingV2.data.dbmessage != 'OK'){
            this.conPass = { conPass: false,
                        errorMsg: 'ไม่สามารถยืนยันจองเลือกรับสินค้าที่สาขาได้'
            }
          }
        }
        for (let i = 0; i < this.listPackageSER.length; i++) {
          let deliveryByStore = this.itmTran[0].trfrom
          trackingno = `[{"carrier":"SER","trackingNo":"","deliveryDate":""}]`
          skcodelist = `[{"SEQ":"${this.listPackageSER[i].SEQ}","SKC":"${this.listPackageSER[i].SKC}","SHIPTYP":"5","SHIPNME":"SER","deliveryByStore":"${deliveryByStore}","deliveryStore":"${deliveryByStore}","pickupstore":"Pickupstore","usestockstore":"usestockstore"}]`
          let data = {
            REF: this.getQTCUSTREF,
            bookingId: '',
            trackingNo: trackingno,
            skcodelist: skcodelist,
            pickupnow: false
          }
          console.log(data);
          let csconfirmBookingV2 = await serviceAPI.call_API('post', 'checkout/csconfirmBookingV2', data,'auth');
          if(csconfirmBookingV2.data.dbmessage != 'OK'){
            this.conPass = { conPass: false,
                        errorMsg: 'ไม่สามารถยืนยันจองเลือกรับสินค้าที่สาขาได้'
            }
          }
        }
      } catch (error) {
        this.conPass = { conPass: false,
                         errorMsg: 'ไม่สามารถยืนยันจองเลือกรับสินค้าที่สาขาได้'
        }
        // serviceMain.showErrorAlert(this, error)
      }
    },

    async csvoidBooking() {
      let reserveBooking = ''
      let data ={
          ref: this.getQTCUSTREF
      }
      // console.log('csvoidBooking');
      for (let i = 0; i < this.itmTran.length; i++) {
        let slotRef = this.itmTran[i].tsptype.trim() == "SER" ? 'SlotBookingVFixRef'+i : 'SlotBookingDlvRef'+i
        if(this.itmTran[i].tsptype.trim() == "SER"){
          // console.log('csvoidBookingVFix');
          reserveBooking = await this.$refs[slotRef].csvoidBookingVFix()
        }else if(this.itmTran[i].tsptype.trim() != "SER" &&  this.getQTCHKP == 'Delivery'){
          // console.log('csvoidBookingDlv');
          reserveBooking = await this.$refs[slotRef].csvoidBookingDlv()
        }
      }
      // await serviceAPI.call_API('post', `history/csvoidAllBooking`, data, 'auth');
    },

    async csvoidAllBooking() {
      try{
        await this.getCurrentBooking();
        console.log('csvoidAllBooking');
        let data ={
            ref: this.getQTCUSTREF
        }
        for (let i = 0; i < this.bookingIDItm.length; i++) {
            // console.log(this.bookingIDItm[i]);
            let dataV ={
                ref: this.getQTCUSTREF,
                bookingid: this.bookingIDItm[i],
                salesource: this.itmTran[0].salesource
            }
            await serviceAPI.call_API('post', `history/voidBookingVFix`, dataV, 'auth');
        }
        // await serviceAPI.call_API('post', `history/csvoidAllBooking`, data, 'auth');
      } catch (error) {
        this.WaitBooking = false;
          if(error.message == "Invalid bookingId"){
            return
          }
          serviceMain.showErrorAlert(this, error)
      }
    },

    async chgPickPackageMain() {
      this.pickPackageAll = ""
      this.$emit("confirmCHKP", this.dlvTrTypeAll);
      if(this.dlvTrTypeAll == this.dlvTpeOpt.PICK){
        await this.EditDlvZero()
        this.$store.dispatch('service/setQTCHKP','Pickup')
        // this.$store.dispatch('service/setReloadOrderInfo')
      }else if(this.dlvTrTypeAll == this.dlvTpeOpt.PICKNOW){
        await this.EditDlvZero()
        this.$store.dispatch('service/setQTCHKP','PickupNow')
      }else{
        this.$store.dispatch('service/setQTCHKP','Delivery')
      }
    },

    chgPickPackageAll() {
      if(this.dlvTrTypeAll == this.dlvTpeOpt.PICK){
        this.$store.dispatch('service/setQTCHKP','Pickup')
      }else if(this.dlvTrTypeAll == this.dlvTpeOpt.PICKNOW){
        this.$store.dispatch('service/setQTCHKP','PickupNow')
      }else{
        if(!(this.pickPackageAll === '')){
          for (let i = 0; i < this.itmTran.length; i++) {
            if(this.itmTran[i].tsptype.trim() != "SER" && this.getQTCHKP == 'Delivery'){
              if(this.dlvTrTypeAll == this.dlvTpeOpt.SER){
                this.getDlvTrType[i] = this.dlvTpeOpt.SER,
                this.getPickPackage[i] = this.pickPackageAll
              }else{
                this.getDlvTrType[i] = i == this.pickPackageAll ? this.dlvTrTypeAll : this.dlvTpeOpt.COM,
                this.getPickPackage[i] = this.pickPackageAll
              }
            }
          }
          this.changePick = Date.now()
        }
      }
      // console.log(this.getDlvTrType);
      // console.log(this.getPickPackage);
    },
    async chgPickPackageAllQTBOOKING() {
      if(this.dlvTrTypeAll == this.dlvTpeOpt.PICK){
        await this.EditDlvZero()
        this.$store.dispatch('service/setQTCHKP','Pickup')
      }else if(this.dlvTrTypeAll == this.dlvTpeOpt.PICKNOW){
        await this.EditDlvZero()
        this.$store.dispatch('service/setQTCHKP','PickupNow')
      }else{
        if(!(this.dlvTrTypeAll === '')){
          for (let i = 0; i < this.itmTran.length; i++) {
            if(this.itmTran[i].tsptype.trim() != "SER" && this.getQTCHKP == 'Delivery'){
              this.getDlvTrType[i] = this.dlvTrTypeAll
            }
          }
          setTimeout(() => this.changePick = Date.now(), 500);
        }
        this.$store.dispatch('service/setQTCHKP','Delivery')
      }
      // console.log(this.getDlvTrType);
      // console.log(this.getPickPackage);
    },
    async EditDlvZero(){
      try {
            let data={
                REF: this.getQTCUSTREF, 
                TRAN_JSON:{
                  amount: (Math.round(0 * 100) / 100).toFixed(2),
                }
            }
            let getAPI = await serviceAPI.call_API('post',`checkout/SaveTransport`,data,'auth');
            // this.$store.dispatch('service/setReloadOrderInfo')
            this.getQTPDLV = ''
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async setTimeReload(){
      this.reloadSlot = false
      let _this = this;
      clearTimeout(this.reloadSlotTime);
      this.reloadSlotTime = setTimeout(function(){ _this.reloadSlot = true; console.log('finish reload'); }, 3000);
      // setTimeout(() => {
      //   this.reloadSlot = true
      //   console.log('finish reload');
      // }, 3000) 
    },
    async selectedStore(val){
      this.trsendpickup = val
    },
    
  },
  computed: {
    getQTCUSTREF: function () {
      return this.$store.getters["customer/getQTCUSTREF"];
    },
    getQTCUSTINFO: function () {
      return this.$store.getters["customer/getQTCUSTINFO"];
    },
    getQTBOOKING: function () {
      return this.$store.getters["auth/getQTBOOKING"];
    },
    getBU: function() {
      return this.$store.getters['customer/getQTBU'];
    },
    getBuColor:function(){
      let color = '';
      switch(this.getBU) {
        case 'bnb':
          color = '#006e63';
        break;
        case 'at1':
          color = '#3866af';
        break;
        default:
          color = '#55246c';
      }
      return color;
    },
    getStore: function () {
      return this.$store.getters["service/getQTDFSTORE"];
    },
    QTDLVTRTYPE: {
      get () {
        return this.$store.getters['customer/getQTDLVTRTYPE'] || ''
      },
      set (value) {
        this.$store.dispatch('customer/setQTDLVTRTYPE',value)
      }
    },
    WaitBooking: {
      get () {
        return this.$store.getters['service/getWaitBooking']
      }, 
      set (value) {
        this.$store.dispatch('service/setWaitBooking', value)
      }
    },
    getBookingItms: function () {
      return this.$store.getters["service/getBookingItms"];
    },
    getQTCHKP: function () {
      return this.$store.getters["service/getQTCHKP"];
    },
    pickData: {
      get () {
        this.$store.getters["service/getQTCHKP"];
      },
      set (value) {
        this.$store.dispatch('service/setQTCHKP',value)
      }
    },
    getQTSALESOURCE: function () {
      return this.$store.getters["auth/getQTSALESOURCE"];
    },
    dlvAll: function () {
      for (let i = 0; i < this.itmTran.length; i++) {
        if(!this.allDlv[i]){
          return false
        }
      }
      return true
    },
    dlvQTBOOKING: function () {
      for (let i = 0; i < this.itmTran.length; i++) {
        // console.log(this.itmTran[i].tsptype);
        if(this.itmTran[i].tsptype != 'SER'){
          if(!this.allDlv[i]){
            this.conPass = { conPass: false,
                             errorMsg: 'กรุณาเลือกวิธีการจัดส่ง'
            }
            return false
          }
          if(this.listdlvTrType[i] == 'PICK' || this.listdlvTrType[i] == 'PICKNOW'){
            if(!(this.listPickDate[i].Date && this.listPickDate[i].Time)){
              this.conPass = { conPass: false,
                               errorMsg: 'กรุณาเลือก วัน/เวลา รับสินค้า'
              }
              return false
            }
          }
        //   if(['KER','DIY','CON','CRI','VEN','SER'].includes(this.listdlvTrType[i])){
        //       this.conPass = { conPass: false,
        //                        errorMsg: 'ไม่สามารถเลือกการจัดส่งถึงบ้านได้'
        //       }
        //       return false
        //   }
        }else{
          this.conPass = { conPass: false,
                           errorMsg: 'ไม่สามารถสร้างใบเสนอราคาได้เนื่องจากมีสินค้าบริการ'
          }
          return false
        }
      }
      return true
    },
    getdlvSER: function () {
      for (let i = 0; i < this.itmTran.length; i++) {
        if(this.itmTran[i].tsptype == 'SER'){
          if(!this.allDlv[i]){
            return false
          }
        }
      }
      return true
    },
    checkIsXmasItem: function () {
      return this.orderItems.findIndex(itm => this.skuChrismas.includes(itm.SKC)) > -1
    },
    getTimePick:function(){
      return this.$store.getters['service/getTimePick']
    },
    getDatePick:function(){
      return this.$store.getters['service/getDatePick']
    },
    getQTPDLV: {
      get () {
        return this.$store.getters['service/getQTPDLV']
      },
      set (value) {
        this.$store.dispatch('service/setQTPDLV',value)
      }
    },
    getdlvTrTypeAll:function(){
      return this.dlvTrTypeAll
    },
    getQTSTORENAME:function(){
      return this.$store.getters['service/getQTSTORENAME']
    },
    getReloadOrderInfo:function(){
      return this.$store.getters['service/getReloadOrderInfo']
    },
    getReloadOrderInfoF101:function(){
      return this.$store.getters['service/getReloadOrderInfoF101']
    },
    ChangeAddress:function(){
      return this.$store.getters['service/getChangeAddress']
    },
    shipFlag: function () {
      for (let i = 0; i < this.orderItems.length; i++) {
        if(this.orderItems[i].deliveryFlag[0] != '1'){
          return false
        }
      }
      return true
    },
    pickFlag: function () {
      for (let i = 0; i < this.orderItems.length; i++) {
        if(this.orderItems[i].deliveryFlag[1] != '1'){
          return false
        }
      }
      return true
    },
  },
  watch: {
    getStore: function () {
      this.QTDLVTRTYPE = '';
      this.setTimeReload()
      // this.reloadSlotTimes();
    },
    getQTCUSTREF: function (){
      this.QTDLVTRTYPE = ''
    },
    getQTCHKP: function (){
      this.setTimeReload()
      if(this.getQTBOOKING){
        if(this.getQTCHKP == 'Delivery'){
          this.getTransportSpecv2();
        }
        if(this.getQTCHKP == 'Pickup' || this.getQTCHKP == 'PickupNow'){
          this.getPickupSpecV2()
        }
      }
      else{
        this.getPickupSpecV2()
      }
    },
    // getdlvTrTypeAll: function (){
    //   this.setTimeReload()
    //   if(this.getQTBOOKING){
    //     if(this.getdlvTrTypeAll == this.dlvTpeOpt.PICK || this.getdlvTrTypeAll == this.dlvTpeOpt.PICKNOW){
    //       this.getPickupSpecV2()
    //     }else{
    //       this.getTransportSpecv2();
    //     }
    //   }else{
    //     this.getPickupSpecV2()
    //   }
    // },
    getReloadOrderInfo:function(){
      this.setTimeReload()
    },
    getReloadOrderInfoF101:function(){
      this.setTimeReload()
    },
    ChangeAddress:function(){
      this.setTimeReload()
    },
  }
}
</script>

<style scoped>
  .fnt-header {
    font-family: 'kittithadabold75regular';
  }

  .cardWhite{
    margin: 0px;
    background-color: white;
    padding:20px 20px;
    border-radius: 7px;
    height: auto;
    margin-bottom: 0px;
    min-height: auto;
  }
  
  .form-control {
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    border-bottom: .5px solid #ccc;
    box-shadow: none;
    border-radius: 0px;
    font-weight: bolder;
  }
</style>