<template>
  <section>
    <div class='slotbooking-div' :key="itmTran">
      <div  class="row">
        <div class="col-12 p-0">
          <div class='cardWhite'>
            <div class="row pointer" @click="showDivDetail(itmTran,slotNum+1)">
              <div class="col-2 p-0">
                <div class="row">
                  <div class="col-12 p-0 d-flex justify-content-center"><span class='fnt-header' style="font-size:1.3rem;">แพคเกจที่</span></div>
                  <div class="col-12 p-0 d-flex justify-content-center"><span class='fnt-header' style="font-size:1.3rem;">{{ slotNum+1 }}</span></div>
                </div>
              </div>
              <div class="col-2 p-0">
                <div class="px-1 px-md-2 text-right text-md-center d-flex my-auto py-2 ml-auto ml-md-0">
                  <span class="mx-1 border" v-for="(e1, index) in JSON.parse(itmTran.jsnskulist)" :key="index"><ImageBySKU  style="width:100%;max-width:60px;" :sku="e1.SKC" /></span>
                </div>
              </div>
              <div class="col-8 p-0">
                <div class="row" v-for="(e1, index) in JSON.parse(itmTran.jsnskulist)" :key="index">
                  <div class="col-12 text-left">{{e1.PRNAME}} - ({{e1.SKC}})</div>
                  <div class="col-12">
                    <span class="text-left">{{e1.QNT}} {{e1.UNAME}}</span><span class="text-right pr-1">   รวม {{e1.AMTSHOW}}</span>
                    <span class="" v-if="PORQ == 'P'">PO:{{VENST}}</span>
                    <span class="" v-if="PORQ == 'R'">RQ:{{VENST}}</span>
                    <span class="pl-1" >รหัสพนักงาน:{{SLPRSN}}</span>
                  </div>
                </div>
              </div>
              <!-- <div class="px-1 px-md-2 text-right text-md-center d-inline-flex d-md-inline my-auto ml-auto ml-md-0" style="line-height: 1em;">
                <div>จำนวน<span class="d-none d-md-inline">สินค้า</span></div>
                <div class="f-kitti75 px-1" style="text-decoration: underline;width: 90px;">{{ itmTran.sumqnt }} รายการ</div>
              </div> -->
              <div class="px-1 px-md-2 text-right my-auto ml-auto d-none d-md-inline">
                <div style="font-size: 20px;">ได้รับสินค้าตามวันที่เลือก</div>
              </div>
            </div>
            <div v-if="false">
              <SelectStoreDLV ref="SelectStoreRef" :trfrom ="itmTran.trfrom" @selectedStore="selectedStore"/>
            </div>
            <div v-for="(e1, index) in JSON.parse(itmTran.jsnskulist)" :key="index">
              <PickupStore v-show="dlvTrType == dlvTpeOpt.PICK" ref="PickupStore" :trfrom ="itmTran.trfrom" :SKU="e1.SKC" @selectedStore="selectedStore"/>
            </div>
            <select class='form-control' @change='chgSelTrType();' :key='dlvTrType' v-model='dlvTrType'>
              <option value=''>กรุณาเลือกประเภทการจัดส่ง</option>
              <optgroup label="จับคู่แพคเกจ" v-show="getQTBOOKING && trsend != '60004'">
                <option :value='dlvTpeOpt.COM'>จับคู่แพคเกจ(ไม่สามารถเลือกแพคเกจที่เลือกจับคู่กับแพคเกจอื่นได้)</option>
              </optgroup>
              <optgroup v-if="pickFlag" label="รับสินค้า" v-show="trsend != '60004'">
                <!-- <option :value='dlvTpeOpt.PICKNOW' v-show="!isUnusePickupNow" >รับของทันที</option> -->
                <option  :value='dlvTpeOpt.PICK'>รับที่สาขา</option>
              </optgroup>
              <optgroup v-if="shipFlag" label="ส่งถึงบ้าน" v-show="getQTBOOKING">
                <!-- <option v-show="!isXmas && getQTBOOKING" :value='dlvTpeOpt.COM'>จับคู่แพคเกจ(ไม่สามารถเลือกแพคเกจที่เลือกจับคู่กับแพคเกจอื่นได้)</option> -->
                <option :value='dlvTpeOpt.KER'>3PL</option>
                <!-- <option v-show="!isXmas && (dcsAvailable || getQTSALESOURCE == 'UL') && trsend != '60004'" :value='dlvTpeOpt.DCS'>DC</option> -->
                <option :value='dlvTpeOpt.DIY'>OWF</option>
                <!-- <option v-show="!isXmas && trsend != '60004'" :value='dlvTpeOpt.CON'>CON</option>
                <option v-show="isXmas && trsend != '60004'" :value='dlvTpeOpt.CRI'>CRI</option>
                <option v-show="!isXmas && getQTBOOKING && trsend != '60004'" :value='dlvTpeOpt.VEN'>VEN</option> -->
                <option :value='dlvTpeOpt.SER'>SER</option>
              </optgroup>
                <!-- <option v-show="!isXmas && getQTBOOKING" :value='dlvTpeOpt.COM'>จับคู่แพคเกจ(ไม่สามารถเลือกแพคเกจที่เลือกจับคู่กับแพคเกจอื่นได้)</option>
                <option v-show="!isXmas" :value='dlvTpeOpt.KER'>Kerry</option>
                <option v-show="!isXmas" :value='dlvTpeOpt.PICK'>PickUp</option>
                <option v-show="!isXmas || (isXmas && getBU == 'bnb')" :value='dlvTpeOpt.DIY'>DIY</option>
                <option v-show="!isXmas" :value='dlvTpeOpt.CON'>CON</option>
                <option v-show="isXmas && getBU != 'bnb'" :value='dlvTpeOpt.CRI'>CRI</option>
                <option v-show="!isXmas && getQTBOOKING" :value='dlvTpeOpt.VEN'>VEN</option>
                <option v-show="!isXmas" :value='dlvTpeOpt.SER'>SER</option> -->
            </select><br>
            <div v-if='(getQTBOOKING || canShowSlot)'>
              <span v-if='dlvTrType == dlvTpeOpt.KER'>จัดส่งโดยผู้ให้บริการขนส่ง ใช้เวลาจัดส่ง 2 - 3 วันทำการสำหรับกรุงเทพและปริมณฑล และ 3 - 5 วันทำการสำหรับต่างจังหวัด</span>
              <div v-if='[dlvTpeOpt.DIY, dlvTpeOpt.CON, dlvTpeOpt.CRI, dlvTpeOpt.DCS].includes(dlvTrType)'>
                <span v-if="isShowDesc">เลือกช่วงจัดส่งได้ 14 วันล่วงหน้าเร็วที่สุดที่สามารถเลือกได้ 08:00 - 17:00 กรุณาเลือกวันและเวลารับสินค้า</span>
                <div>
                  <div class='row m-0' style='margin: 0' v-if="isShowNextPrev">
                    <div class="col-12">
                        <span style='float: right;' v-if='lastSlotDate'>
                            <button class="twdorbnb btnPrevNext" 
                                    :style="`background-color: ${getBuColor};`" 
                                    @click="getNewSlot($event, 'next', `'${lastSlotDate}'`)">
                                <strong style="font-family: 'kittithadamedium65regular';"> &gt;&gt; </strong>
                            </button>
                        </span>
                        <span style='float: right;' v-if='firstSlotDate && firstSlotDate != startDateBooking'>
                            <button class="twdorbnb btnPrevNext" 
                                    :style="`background-color: ${getBuColor};`" 
                                    @click="getNewSlot($event, 'prev', `'${firstSlotDate}'`)">
                                <strong style="font-family: 'kittithadamedium65regular';"> &lt;&lt; </strong>
                            </button>
                        </span>
                    </div>
                  </div><br>
                  <TimeSlot @completeSlotTime="completeSlotTime" @bookingItems="bookingItems" :key="isShowSlot" :bookingslots='bookingSlots' :masterTimeslots='masterTimeSlots' :startDateBooking='startDateBooking' :slotNum='`${slotNum}-${index}`'/>
                  <!-- <div v-for="(e, index) in getListSKU" :key="index">
                    <div class="col-12"><ImageBySKU  style="width:100%;max-width:60px;" :sku="e" /> <span>{{ getListSKUName[index] }}</span></div>
                    <TimeSlot @completeSlotTime="completeSlotTime" @bookingItems="bookingItems" :key="isShowSlot" :bookingslots='bookingSlots' :masterTimeslots='masterTimeSlots' :startDateBooking='startDateBooking' :slotNum='`${slotNum}-${index}`' :sku='e' :indexNum='index'/>
                    <hr>
                  </div> -->
                </div>
                <SlotBookingSkeleton v-if="isLoading && dlvTrType != dlvTpeOpt.KER && dlvTrType != dlvTpeOpt.VEN && dlvTrType != dlvTpeOpt.SER && !isShowDesc"/>
              </div>
              <div class="row" v-if='[dlvTpeOpt.VEN].includes(dlvTrType)'>
                <div class="col-12">
                  <!-- <input class="form-control" type="date" v-model="dateVEN" :min="minDateVen"> -->
                  <input placeholder="เลือกวันส่งสินค้าของท่าน" type='date'  id="dattxt" autocomplete="off" class="form-control" v-model="dateVEN" :min="minDateVen">
                </div>
                <!-- <div class="col-12 col-md-6">
                  <input placeholder="เลือกเวลารส่งสินค้าของท่าน" autocomplete="off" type='text' id="tmetxt" class="form-control timepicker" v-model="storeTimePick"/>
                </div> -->
              </div>
              <div v-if='[dlvTpeOpt.SER].includes(dlvTrType) && getQTBOOKING' >
                <select class='form-control' @change='chgPickPackage();' :key='pickPackage' v-model='pickPackage'>
                  <option value=''>กรุณาเลือกแพคเกจที่ไปด้วยกัน</option>
                  <option v-for="item in listPackageSER" v-bind:key="item.SLOTNUM" v-bind:value="item.SLOTNUM">แพคเกจ{{ item.SLOTNUM+1 }} - {{ item.PRNAME }} - {{ item.SKC }}</option>
                </select>
              </div>
              <!-- <div v-if='[dlvTpeOpt.COM].includes(dlvTrType)  && getQTBOOKING' >
                <select class='form-control' @change='chgPickPackage();' :key='pickPackage' v-model='pickPackage'>
                  <option value=''>กรุณาเลือกแพคเกจที่ไปด้วยกัน</option>
                  <option v-for="item in listPackageDLV" v-bind:key="item.SLOTNUM" v-bind:value="item.SLOTNUM">แพคเกจ{{ item.SLOTNUM+1 }} - {{ item.PRNAME }} - {{ item.SKC }}</option>
                </select>
              </div> -->
              <div v-if='[dlvTpeOpt.COM].includes(dlvTrType) && getQTBOOKING && pickFlag && shipFlag' :key='allList'>
                <select class='form-control' @change='chgPickPackage();' :key='pickPackage' v-model='pickPackage'>
                  <option value=''>กรุณาเลือกแพคเกจที่ไปด้วยกัน</option>
                  <option v-for="item in allList" v-bind:key="item.SLOTNUM" v-bind:value="item.SLOTNUM">แพคเกจ{{ item.SLOTNUM+1 }} - {{ item.skulist ? JSON.parse(item.skulist)[0].PRNAME : '' }} - {{ item.skulist ? JSON.parse(item.skulist)[0].SKC : '' }}</option>
                </select>
              </div>
              <div v-else-if='[dlvTpeOpt.COM].includes(dlvTrType) && getQTBOOKING && pickFlag' :key='pickList'>
                <select class='form-control' @change='chgPickPackage();' :key='pickPackage' v-model='pickPackage'>
                  <option value=''>กรุณาเลือกแพคเกจที่ไปด้วยกัน</option>
                  <option v-for="item in pickList" v-bind:key="item.SLOTNUM" v-bind:value="item.SLOTNUM">แพคเกจ{{ item.SLOTNUM+1 }} - {{ item.skulist ? JSON.parse(item.skulist)[0].PRNAME : '' }} - {{ item.skulist ? JSON.parse(item.skulist)[0].SKC : '' }}</option>
                </select>
              </div>
              <div v-else-if='[dlvTpeOpt.COM].includes(dlvTrType) && getQTBOOKING && shipFlag' :key='shipList'>
                <select class='form-control' @change='chgPickPackage();' :key='pickPackage' v-model='pickPackage'>
                  <option value=''>กรุณาเลือกแพคเกจที่ไปด้วยกัน</option>
                  <option v-for="item in shipList" v-bind:key="item.SLOTNUM" v-bind:value="item.SLOTNUM">แพคเกจ{{ item.SLOTNUM+1 }} - {{ item.skulist ? JSON.parse(item.skulist)[0].PRNAME : '' }} - {{ item.skulist ? JSON.parse(item.skulist)[0].SKC : '' }}</option>
                </select>
              </div>
              <div v-else-if='[dlvTpeOpt.COM].includes(dlvTrType) && getQTBOOKING' :key='nonList'>
                <select class='form-control' @change='chgPickPackage();' :key='pickPackage' v-model='pickPackage'>
                  <option value=''>กรุณาเลือกแพคเกจที่ไปด้วยกัน</option>
                  <option v-for="item in nonList" v-bind:key="item.SLOTNUM" v-bind:value="item.SLOTNUM">แพคเกจ{{ item.SLOTNUM+1 }} - {{ item.skulist ? JSON.parse(item.skulist)[0].PRNAME : '' }} - {{ item.skulist ? JSON.parse(item.skulist)[0].SKC : '' }}</option>
                </select>
              </div>
            </div>
            <div v-show="selectpickstc" class="row" v-if='[dlvTpeOpt.PICK].includes(dlvTrType)'>
                <!-- <div class="col-12">
                  <input placeholder="เลือกวันรับสินค้าของท่าน" type='date'  id="dattxt" autocomplete="off" class="form-control" v-model="dateVEN" :min="minDateVen">
                </div> -->
                <Pickupbyseq @pickupItems="pickupItems" :slotNum='`${slotNum}`' :trsend="trsend" :StoreData="StoreData" />
                <!-- <div class="col-12 col-md-6">
                  <input placeholder="เลือกเวลารส่งสินค้าของท่าน" autocomplete="off" type='text' id="tmetxt" class="form-control timepicker" v-model="storeTimePick"/>
                </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="DlvDetail" size="60">
      <template v-slot:header>
        <p class="text-left pl-2">แพคเกจที่ {{ slotNum+1 }} (สินค้า {{itmTran.sumqnt}} รายการ)</p>
      </template>
      <template v-slot:body >
        <div v-if="itmTran.length != 0" :key="itmTran">
          <div class="row" v-for="(e1, index) in JSON.parse(itmTran.jsnskulist)" :key="index">
            <div class="col-2"><ImageBySKU  style="width:100%;max-width:60px;" :sku="e1.SKC" /></div>
            <div class="col-8">
              <div class="row">
                <div class="col-12 text-left">{{e1.PRNAME}} - ({{e1.SKC}})</div>
                <div class="col-12 text-left">{{e1.QNT}} {{e1.UNAME}}</div>
              </div>
            </div>
            <div class="col-2"> รวม {{e1.AMTSHOW}}</div>
          </div>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import * as serviceAPI from "@/services/API.service";
import * as serviceMain from "@/services/main.service";
import TimeSlot from "@/components/CheckOut/SlotBooking/TimeSlot.vue";
import SlotBookingSkeleton from "@/components/CheckOut/SlotBooking/SlotBookingSkeleton.vue";
import ImageBySKU from '@/components/ImageBySKU.vue';
import Modal from '@/components/Modal.vue';
import Pickupbyseq from "@/components/CheckOut/SlotBooking/Pickupbyseq.vue";
import SelectStoreDLV from "@/components/CheckOut/SlotBooking/SelectStoreDLV.vue";
import PickupStore from "@/components/CheckOut/SlotBooking/PickupStore.vue";

export default {
  name: "SlotBookingDlv",
  components: {
    TimeSlot,
    SlotBookingSkeleton,
    ImageBySKU,
    Modal,
    Pickupbyseq,
    SelectStoreDLV,
    PickupStore
  },
  data: function() {
    return {
      dlvTpeOpt: {
        KER: 'KER',
        DIY: 'DIY',
        CON: 'CON',
        DCS: 'DCS',
        CRI: 'CRI',
        VEN: 'VEN',
        SER: 'SER',
        COM: 'COM',
        PICK:'PICK',
        PICKNOW:'PICKNOW',
      },
      dlvTrType: '',
      bookingSlots: [],
      masterTimeSlots: [],
      flee: 0,
      startDateBooking: '',
      errSaveTrans: false,
      errStoreBooking: false,
      deliDaysOfWeek: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
      deliMonthThai: ["ม.ค.","ก.พ.","มี.ค.","ม.ษ.","พ.ค.","มิ.ย.","ก.ค.","ส.ค.","ก.ย.","ต.ค.","พ.ย.","ธ.ค."],
      lastSlotDate: '',
      firstSlotDate: '',
      isShowSlot: false,
      isShowDesc: false,
      isShowNextPrev: false,
      isLoading: true,
      skuChrismas: ['60284074','60284075','60300086','60300087','60330707','60353328'],
      getBookingItms:{},
      bookingId:'',
      dateVEN: new Date().toISOString().substr(0, 10),
      minDateVen: new Date().toISOString().substr(0, 10),
      storeTimePick:'',
      pickPackage:'',
      getPickUpDate:{Date:'',
                     Time:''},
      isXmas: false,
      storeUnusePickupNow: ['UL', 'UC'],
      storeUnusePickup: [],
      isUnusePickupNow: false,
      trsend:'',
      selectpickstc:'',
      StoreData : '',
      sendInDay: ['UO','UL','UA'],
      deliveryByStore:'',
      dataSTOCK:'',
      USESTOCK:'',
      usestockstore:''
    }
  },
  props:[
    "orderItems",
    "itmTran",
    "slotNum",
    "listPackageSER",
    "listPackageSERPic",
    "listPackageDLV",
    "listPackageDLVPic",
    "listBookingID",
    "getPickPackage",
    "getDlvTrType",
    "changePick",
    "canShowSlot",
    "dcsAvailable",
    "dataAddress",
    "listdlvTrTypePWB",
    "listdata"
  ],
  mounted() {
    this.$emit("dlvType", [false,this.slotNum]);
    // this.dlvTrType = this.QTDLVTRTYPE;
    this.isUnusePickupNow = this.storeUnusePickupNow.includes(this.getQTSALESOURCE) || this.storeUnusePickupNow.includes(this.getStore);
    this.isXmas = JSON.parse(this.itmTran.jsnskulist).findIndex(itm => this.skuChrismas.includes(itm.SKC)) > -1
    // console.log(!this.dlvTrType);
    if(!this.dlvTrType) return;
          
    this.flee = this.DeliveryFee
    this.trsend = this.itmTran.trfrom
    this.reloadSlotTimes();
    
  },
  methods: {
    async checkIsXmas () {
      try {
        this.isXmas = JSON.parse(this.itmTran.jsnskulist).findIndex(itm => this.skuChrismas.includes(itm.SKC)) > -1
        if(this.isXmas && !(this.dlvTrType == 'CRI' || this.dlvTrType == 'COM' || this.dlvTrType == 'PICK' || this.dlvTrType == 'PICKNOW') || !this.isXmas && this.dlvTrType == 'CRI') {
          this.dlvTrType = '';
          // this.clearSlotTime()
          if(!this.dlvTrType) return;
          
          this.flee = this.DeliveryFee
          this.reloadSlotTimes();
        }
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    async chgSelTrType () {
      // this.dlvTrType = event.target.value;
      this.QTDLVTRTYPE = this.dlvTrType
      this.isShowSlot = false;
      this.isShowDesc = false;
      this.isShowNextPrev = false;
      if(!this.dlvTrType) return;
      this.$emit("fndlvTrType", [this.dlvTrType,this.slotNum,this.itmTran.jsnskulist]);
      this.bookingSlots = [];
      this.masterTimeSlots = [];
      this.DeliveryFee = 0;
      this.reloadSlotTimes();
    },
    async reloadSlotTimes() {
      try {
        this.isLoading = true
        let data = {
          saleSource: this.itmTran.salesource,
          deliveryFromStore: this.itmTran.trfrom,
          deliveryType: this.dlvTrType,
          shipToPostalCode: this.itmTran.topostalcode,
          shipToLatitude: this.itmTran.tolat,
          shipToLongitude: this.itmTran.tolong,
          packageWeight: this.itmTran.sumweight,
          totalprice: this.itmTran.samount,
          cubicom:this.itmTran.cubicom
        }
        // console.log(data);
          this.callDeliveryRate(data);
      } catch (error) {
        console.log('reloadSlotTimes'+(this.slotNum+1));
        this.isLoading = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async callDeliveryRate(data){
      try {
        let callDeliveryRate = await serviceAPI.call_API('post', 'checkout/callDeliveryRate', data,'auth');
        let deliveryRateData = callDeliveryRate.data.data;
        if(deliveryRateData.errorMessage != "Success") {
          serviceMain.showWarningAlert(this,"ขณะนี้ระบบคำนวนค่าขนส่งขัดข้องชั่วคราว กรณีมีค่าขนส่ง เจ้าหน้าที่จะติดต่อท่านเพื่อยืนยันการจัดส่ง");
          this.isLoading = false;
          return;
        } 
        this.deliveryByStore = deliveryRateData.data.deliveryFromStore
        if(!this.deliveryByStore) {
          serviceMain.showWarningAlert(this,"ไม่สามารถค้นหาสาขาส่งได้");
          this.isLoading = false;
          return;
        } 
        
        this.usestockstore = this.deliveryByStore

        let datastk = {
          SKU:this.SKCODE.SKC, 
          QNT:this.SKCODE.QNT, 
          TRSEND:this.getStore
        }
        let stockBackorder = await serviceAPI.call_API('post', 'checkout/getStockAvailBackOrder', datastk, 'auth');
        if((stockBackorder.data.dbcode == 0 && stockBackorder.data.dbrows > 0) ){
          this.dataSTOCK = stockBackorder.data.dbitems[0]
        }else{
          serviceMain.showWarningAlert(this,"ไม่สามารถตรวจสอบจำนวนStockได้");
          this.isLoading = false;
          return;
        }
        // Logic PWB
        // EXP ดู Stock 026 >> ไม่พอ ไม่ขาย
        // 3PL ดู Stock ที่ 026 ถ้าไม่มี หรือ ไม่พอ ให้ดูที่ DC 099 >> ไม่พอ ไม่ขาย
        // OWF Fleet ดู Stock ที่ DC 099 >> ไม่พอ ไม่ขาย
        // CAC ดู Stock 12 Store >> ไม่พอ ไม่ขาย
        if(this.dlvTrType == this.dlvTpeOpt.DIY){
          if(this.dataSTOCK.stockAviDC <= 0){
            serviceMain.showWarningAlert(this,"ไม่มีStockสินค้า");
            this.isLoading = false;
            return;
          }else{
            this.USESTOCK = 'D'
            this.usestockstore = '00099'
          }
        }
        else if(this.dlvTrType == this.dlvTpeOpt.KER){
          if(this.dataSTOCK.stockAviST <= 0){
            if(this.dataSTOCK.stockAviDC <= 0){
              serviceMain.showWarningAlert(this,"ไม่มีStockสินค้า");
              this.isLoading = false;
              return;
            }else{
              this.USESTOCK = 'D'
              this.usestockstore = '00099'
            }
          }else{
            this.USESTOCK = 'S'
          }
        }
        if(this.dlvTrType != this.dlvTpeOpt.PICK){
          let listdatasend = { dlvTrType : this.dlvTrType,
                          Pickupstore: this.trsend,
                          deliveryByStore: this.deliveryByStore,
                          USESTOCK: this.USESTOCK } 
          await this.$emit("fnliststcdeli", [listdatasend,this.slotNum]);
        }
        
        if(this.dlvTrType == this.dlvTpeOpt.COM && this.pickPackage){
          this.trsend = this.listdata[this.pickPackage].Pickupstore
          this.USESTOCK = this.listdata[this.pickPackage].USESTOCK
          this.deliveryByStore = this.listdata[this.pickPackage].deliveryByStore
          this.usestockstore = this.deliveryByStore
        
          if(this.listdata[this.pickPackage].dlvTrType == this.dlvTpeOpt.DIY){
            if(this.dataSTOCK.stockAviDC <= 0){
              serviceMain.showWarningAlert(this,"ไม่มีStockสินค้า");
              this.isLoading = false;
              return;
            }else{
              this.USESTOCK = 'D'
              this.usestockstore = '00099'
            }
          }
          else if(this.listdata[this.pickPackage].dlvTrType == this.dlvTpeOpt.KER){
            if(this.dataSTOCK.stockAviST <= 0){
              if(this.dataSTOCK.stockAviDC <= 0){
                serviceMain.showWarningAlert(this,"ไม่มีStockสินค้า");
                this.isLoading = false;
                return;
              }else{
                this.USESTOCK = 'D'
                this.usestockstore = '00099'
              }
            }else{
              this.USESTOCK = 'S'
            }
          }
        }
        
        // let trans = {
        //   amount: (Math.round(deliveryRateData.data.deliveryRate * 100) / 100).toFixed(2),
        // };
        
        // let saveTransportData = {
        //   REF: this.getQTCUSTREF,
        //   TRAN_JSON: trans
        // }
        // this.priceDlv = (Math.round(deliveryRateData.data.deliveryRate * 100) / 100).toFixed(2);
        // let saveTransport = await serviceAPI.call_API('post', 'checkout/SaveTransport', saveTransportData, 'auth');
        // if(saveTransport.data.dbcode > 0) {
        //   serviceMain.showWarningAlert(this,"ขณะนี้ระบบคำนวนค่าขนส่งขัดข้องชั่วคราว กรณีมีค่าขนส่ง เจ้าหน้าที่จะติดต่อท่านเพื่อยืนยันการจัดส่ง");
        //   this.isLoading = false;
        //   return;
        // }
        // this.reloadOrderInfo();
        if(this.dlvTrType == this.dlvTpeOpt.PICKNOW){
          let today = new Date();
          let dd = String(today.getDate()).padStart(2, '0');
          let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          let yyyy = today.getFullYear();
          let hours = today.getHours().toString(); // => 9
          let min = today.getMinutes().toString(); // =>  30
          hours = hours.length > 1 ? hours : '0'+hours;
          min = min.length > 1 ? min : '0'+min;
          // if(hours < 23){
          //   if(min <= 30){
          //     min = 30
          //   } else if (min > 30){
          //     min = 0
          //     hours = hours + 1
          //   }
          // }
          if(yyyy > 2500){
            yyyy = yyyy - 543
          }
          let timenow = hours + ':' + min;
          today = dd + '/' + mm + '/' + yyyy;
          this.getPickUpDate = {Date:today,
                                Time:timenow}
          // console.log(this.getPickUpDate);
          this.$emit("fnlistPickDate", [this.getPickUpDate,this.slotNum]);
        }
        if(!(this.getQTBOOKING || this.canShowSlot) || this.dlvTrType == this.dlvTpeOpt.KER || this.dlvTrType == this.dlvTpeOpt.VEN || this.dlvTrType == this.dlvTpeOpt.PICK || this.dlvTrType == this.dlvTpeOpt.PICKNOW || this.dlvTrType == this.dlvTpeOpt.SER || this.dlvTrType == this.dlvTpeOpt.COM) {
          return;
        }

        this.calculateStartDateBooking();
      } catch (error) {
        console.log('callDeliveryRate'+(this.slotNum+1));
        this.isLoading = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async calculateStartDateBooking() {
      try {
        let data = {
          REF: this.getQTCUSTREF
        }
        let leadtime = await serviceAPI.call_API('post', 'checkout/getPreOrderMsg4Payment', data, 'auth');

        let datenow = new Date();
        let datenowcompare = (datenow.getHours() < 10 ? '0' : '')+datenow.getHours()+":"+(datenow.getMinutes() < 10 ? '0' : '')+datenow.getMinutes();
        if(this.sendInDay.includes(this.getQTSALESOURCE) && this.dlvTrType != this.dlvTpeOpt.DCS){
          datenow.setDate(datenow.getDate())
        }else{
          datenow.setDate(datenow.getDate() + ((datenowcompare >= '12:00') ? 1 : 1))
        }
        // datenow.setDate(datenow.getDate() + ((datenowcompare >= '12:00') ? 2 : 1))
        let leadday = (leadtime.data.dbcode == 0 && leadtime.data.dbrows > 0) ? leadtime.data.dbitems[0]:null;
        if(leadday) datenow.setDate(datenow.getDate() + (leadday.LEADTIME));
        
        this.startDateBooking = datenow.getFullYear()+'-' + (String(datenow.getMonth() + 1).padStart(2, '0')) + '-'+(String(datenow.getDate()).padStart(2, '0'));
        this.callTimeSlots(this.startDateBooking);
      } catch (error) {
        console.log('calculateStartDateBooking'+(this.slotNum+1));
        this.isLoading = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async callTimeSlots(startDateSlot) {
      try {
        this.isShowSlot = true;
        let data = {
          saleSource: this.itmTran.salesource,
          saleByStore:this.itmTran.trfrom,
          deliveryByStore: this.deliveryByStore,
          deliveryDate:startDateSlot,
          provinceName:this.dataAddress.PVN,
          districtName:this.dataAddress.DST,
          subDistrictName:this.dataAddress.TBN,
          postalCode:this.itmTran.topostalcode,
          homeLat:this.itmTran.tolat,
          homeLon:this.itmTran.tolong,
          jobType:this.dlvTrType,
          truckType:"",
          qty:1,
          packageWeight:this.itmTran.sumweight,
          packageVolume:this.itmTran.cubiccm,
          totalPrice:this.itmTran.samount,
          serviceUpcCode:""
        }
        // console.log(data);
        let getBookingSlot = await serviceAPI.call_API('post', 'checkout/getBookingSlot', data,'auth');
        let bookingSlotData = getBookingSlot.data.data;
        switch(bookingSlotData.errorCode){
          case "":
              this.errSaveTrans = false;
              this.errStoreBooking = false;
              this.bookingSlots = bookingSlotData.data;
              this.masterTimeSlots = bookingSlotData.masterTimeslots;
              this.bookingSlots.forEach((days) => {
                  var new_date = new Date(days.deliveryDate);
                  days.delidays = this.deliDaysOfWeek[new_date.getDay()];
                  var getdelidate = new_date.getDate();
                  var getdelimonth = this.deliMonthThai[new_date.getMonth()];
                  days.delidaymonth = getdelidate+" "+ getdelimonth;
              });
          break;
          case "001":
              this.errSaveTrans = true;
              this.isShowSlot = false;
          case "002":
              this.errStoreBooking = true;
              this.isShowSlot = false;
          break;
        }

        if(this.errSaveTrans){
          serviceMain.showWarningAlert(this,"ขณะนี้ระบบคำนวณค่าขนส่งขัดข้องชั่วคราว หากต้องการให้จัดส่ง กรุณาลองใหม่อีกครั้ง หรือ ติดต่อสาขา");
          this.isLoading = false;
          return;
        }

        if(this.errStoreBooking) {
          this.$swal.fire({
              html: "<h2>สถานที่จัดส่งไม่รองรับบริการขนส่ง กรุณาเลือกที่อยู่จัดส่งใหม่</h2>",//"<h2>สถานที่จัดส่งไม่รองรับบริการขนส่ง กรุณาเลือกสาขาหรือที่อยู่จัดส่งใหม่</h2>",
              showCancelButton: false,//true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: this.getBuColor,
              // cancelButtonColor: '#B4B4B4',
              confirmButtonText: 'เลือกที่อยู่จัดส่ง'//'เลือกสาขา',
              //cancelButtonText: 'เลือกที่อยู่จัดส่ง'
          }).then((result) => {
              if (result.value) {
                  this.isLoading = false;
                  this.QTDLVTRTYPE = '';
                  this.$swal.close();
              }
          });
          return;
        }

  // console.log(this.bookingSlots);
        this.firstSlotDate = this.bookingSlots[0].deliveryDate;
        this.lastSlotDate = this.bookingSlots[this.bookingSlots.length - 1].deliveryDate;
        this.isShowNextPrev = true;
        this.calculateFlee();
      } catch (error) {
        console.log('callTimeSlots'+(this.slotNum+1));
        this.isLoading = false;
        serviceMain.showErrorAlert(this,error)
      }
    },
    async calculateFlee(){
      this.reloadFlee();
      this.isShowDesc = true;
    },
    async getNewSlot(event, nextOrPrev, firstOrLastSlotDate) {
        event.target.blur();
        let nextOrPrevSlotDate = new Date(firstOrLastSlotDate);
        let startSlotDate = new Date(nextOrPrevSlotDate.setDate(nextOrPrevSlotDate.getDate() + (nextOrPrev == 'next' ? 1 : -14)));
        let startSlot = startSlotDate.getFullYear()+'-' + (String(startSlotDate.getMonth() + 1).padStart(2, '0')) + '-'+(String(startSlotDate.getDate()).padStart(2, '0'));
        this.getBookingItms = {};
        this.isShowSlot = false;
        this.callTimeSlots(startSlot)
    },
    async clearSlotTime() {
      this.isShowSlot = false;
      this.dlvTrType = '';
      this.flee = 0;
    },
    async reserveBookingDlv() {
      console.log('reserveBookingDlv'+this.slotNum);
      if (!this.errSaveTrans && !this.errStoreBooking && this.bookingSlots && this.bookingSlots.length > 0 && this.dlvTrType != this.dlvTpeOpt.VEN && this.dlvTrType != this.dlvTpeOpt.PICK && this.dlvTrType != this.dlvTpeOpt.PICKNOW && this.dlvTrType != this.dlvTpeOpt.KER && this.dlvTrType != this.dlvTpeOpt.SER && this.dlvTrType != this.dlvTpeOpt.COM ) {
        if(!(this.getBookingItms.code && this.getBookingItms.code != "" && this.getBookingItms.date && this.getBookingItms.date != "" && this.getBookingItms.name && this.getBookingItms.name != "")){
          await this.$emit("csvoidBooking");
          throw new Error('ไม่สามารถเลือกช่วงเวลาจัดส่งนี้ได้กรุณาลองใหม่อีกครั้ง');
        } 
        try {
          let data = {
            REF: this.getQTCUSTREF,
            bookingSlotCode: this.getBookingItms.code,
            bookingSlotDate: this.getBookingItms.date,
            bookingSlotName: this.getBookingItms.name,
            saleSource: this.getQTSALESOURCE,
            getTranV2: this.itmTran,
            dlvTrType: this.dlvTrType,
            listSku: this.getListSKU,
            dlvtype:this.getQTCHKP,
            trsend:this.trsend,
            provinceName:this.dataAddress.PVN,
            districtName:this.dataAddress.DST,
            subDistrictName:this.dataAddress.TBN,
            deliveryByStore: this.deliveryByStore,
          }
          // console.log(data);
          let reserveBookingSLot = await serviceAPI.call_API('post', 'checkout/reserveBookingV2', data,'auth');
          this.bookingId = reserveBookingSLot.data.data.bookingId
          let tempbooking = { bookingId : this.bookingId,
                              bookingSlotCode : this.getBookingItms.code ? this.getBookingItms.code : '',
                              bookingSlotDate : this.getBookingItms.date ? this.getBookingItms.date : (this.dlvTrType == this.dlvTpeOpt.VEN ? this.dateVEN : ''),
                              bookingSlotName : this.getBookingItms.name ? this.getBookingItms.name : '',
                              dlvTrType : this.dlvTrType,
                              trsend: this.trsend,
                              deliveryByStore: this.deliveryByStore,
                              USESTOCK: this.USESTOCK
          }
          await this.$emit("fnlistBookingID", [tempbooking,this.slotNum]);
          // console.log(this.bookingId);
          // console.log(data);
          // console.log(reserveBookingSLot);
          if(!this.bookingId){
            throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
          }
          return {
            slotnum : this.slotnum,
            status : reserveBookingSLot.data.data.status
          }  
        } catch(error) {
          this.WaitBooking = false;
          await this.$emit("csvoidBooking");
          throw new Error(error)
        }
      }
      else {
        if([this.dlvTpeOpt.DIY, this.dlvTpeOpt.CON, this.dlvTpeOpt.CRI, this.dlvTpeOpt.DCS].includes(this.dlvTrType)){
          return {
          slotnum : this.slotnum,
          status : 99 }
        }
        let tempbooking = { bookingId : '',
                            bookingSlotCode : this.getBookingItms.code ? this.getBookingItms.code : '',
                            bookingSlotDate : this.getBookingItms.date ? this.getBookingItms.date : 
                            (this.dlvTrType == this.dlvTpeOpt.VEN  ? this.dateVEN : 
                            (this.dlvTrType == this.dlvTpeOpt.PICK || this.dlvTrType == this.dlvTpeOpt.PICKNOW ? this.getPickUpDate : '')),
                            bookingSlotName : this.getBookingItms.name ? this.getBookingItms.name : '',
                            dlvTrType : this.dlvTrType,
                            trsend: this.trsend,
                            deliveryByStore: this.deliveryByStore,
                            USESTOCK: this.USESTOCK,
                            selectpickstc: this.trsend,
          }
          await this.$emit("fnlistBookingID", [tempbooking,this.slotNum]);
      }
      // console.log(this.slotNum,this.getPickUpDate);
      // console.log(this.slotNum+1);
      if(this.dlvTrType == ''){
        throw new Error(`แพคเกจ ${this.slotNum+1} กรุณาเลือกวิธีการจัดส่ง`);
      }
      if(this.dlvTrType == this.dlvTpeOpt.SER && this.pickPackage === ''){
        throw new Error(`แพคเกจ ${this.slotNum+1} กรุณาเลือกแพคเกจServiceที่ต้องการ`);
      }
      if(this.dlvTrType == this.dlvTpeOpt.VEN && this.dateVEN == ''){
        throw new Error(`แพคเกจ ${this.slotNum+1} กรุณาเลือกวันที่`);
      }
      if(this.dlvTrType == this.dlvTpeOpt.COM && this.pickPackage === ''){
        throw new Error(`แพคเกจ ${this.slotNum+1} กรุณาเลือกแพคเกจที่ต้องการ`);
      }
      if(this.dlvTrType == this.dlvTpeOpt.PICK && (this.getPickUpDate.Date === '' || this.getPickUpDate.Time === '')){
        throw new Error(`แพคเกจ ${this.slotNum+1} กรุณาเลือกวันที่รับสินค้า`);
      }
      // if(this.dlvTrType == this.dlvTpeOpt.PICKNOW && (this.getPickUpDate.Date === '' || this.getPickUpDate.Time === '')){
      //   throw new Error(`แพคเกจ ${this.slotNum+1} กรุณาเลือกวันที่`);
      // }
      return {
        slotnum : this.slotnum,
        status : 1
      }  
    },
    async csvoidBookingDlv() {
      try {
        if(this.bookingId){
          let dataV ={
              ref: this.getQTCUSTREF,
              bookingid: this.bookingId,
              salesource: this.itmTran.salesource
          }
          await serviceAPI.call_API('post', `history/voidBookingVFix`, dataV, 'auth');
          console.log('void '+this.bookingId);
        }
      } catch(error) {
        this.WaitBooking = false;
            // throw new Error(error)
      }
    },
    async verBookingV2Dlv() {
      try {
        if( this.dlvTrType == this.dlvTpeOpt.COM && (this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.COM || this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.SER)){
          await this.$emit("csvoidBooking");
          console.log('ver');
          // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกแพคเกจที่ต้องการได้`);
          return false
        }
        switch (this.dlvTrType ) {
          case this.dlvTpeOpt.KER :
            console.log('ver'+(this.slotNum+1)+ 'KER' );
            break
          case this.dlvTpeOpt.DIY :
            console.log('ver'+(this.slotNum+1)+ 'DIY'+ this.bookingId);
            if(!this.bookingId){
              // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
              return false
            }
            break
          case this.dlvTpeOpt.CON :
            console.log('ver'+(this.slotNum+1)+ 'CON'+ this.bookingId);
            if(!this.bookingId){
              // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
              return false
            }
            break
          case this.dlvTpeOpt.DCS :
            console.log('ver'+(this.slotNum+1)+ 'DCS'+ this.bookingId);
            if(!this.bookingId){
              // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
              return false
            }
            break
          case this.dlvTpeOpt.VEN :
            console.log('ver'+(this.slotNum+1)+ 'VEN'+ this.dateVEN);
            if(!this.dateVEN){
              // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
              return false
            }
            break
          case this.dlvTpeOpt.SER :
            console.log('ver'+(this.slotNum+1)+ 'SER'+ this.listBookingID[this.pickPackage].bookingId);
            if(!this.listBookingID[this.pickPackage].bookingId){
              // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
              return false
            }
            break
          case this.dlvTpeOpt.CRI :
            console.log('ver'+(this.slotNum+1)+ 'CRI'+ this.bookingId);
            if(!this.bookingId){
              // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
              return false
            }
            break
          case this.dlvTpeOpt.COM :
            if(this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.DIY || this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.CON || this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.DCS || this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.CRI){
              console.log('ver'+(this.slotNum+1)+ 'COMB'+ this.listBookingID[this.pickPackage].bookingId);
              if(!this.listBookingID[this.pickPackage].bookingId){
                // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
                return false
              }
            }else if(this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.VEN){
              console.log('ver'+(this.slotNum+1)+ 'COMV'+ this.listBookingID[this.pickPackage].bookingSlotDate);
              if(!this.listBookingID[this.pickPackage].bookingSlotDate){
                // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
                return false
              }
            }else if(this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.PICK || this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.PICKNOW){
              console.log('ver'+(this.slotNum+1)+ 'COMP'+ this.listBookingID[this.pickPackage].bookingSlotDate);
              if(!this.listBookingID[this.pickPackage].bookingSlotDate){
                // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
                return false
              }
            }
            break
          case this.dlvTpeOpt.PICK :
            console.log('ver'+(this.slotNum+1)+ 'PICK', this.getPickUpDate);
            if((this.getPickUpDate.Date === '' || this.getPickUpDate.Time === '')){
              // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
              return false
            }
            break
          // case this.dlvTpeOpt.PICKNOW :
          //   console.log('ver'+(this.slotNum+1)+ 'PICKNOW', this.getPickUpDate);
          //   if((this.getPickUpDate.Date === '' || this.getPickUpDate.Time === '')){
          //     // throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกช่วงเวลาจัดส่งได้กรุณาลองใหม่อีกครั้ง`);
          //     return false
          //   }
          //   break
          default : 
        }
        return true
      } catch(error) {
        this.WaitBooking = false;
        return false
        // throw new Error(error)
      }
    },
    async confirmBookingV2Dlv() {
      try {
        console.log('confirmBookingV2Dlv'+this.slotNum);
        let trackingno = '';
        let dlvTrTypeNum = ''
        let dlvTrTypeSend = ''
        let pickupDate = ''
        let deliveryByStore = ''
        let pickupnow = false
        dlvTrTypeSend = this.dlvTrType
        switch (this.dlvTrType ) {
          case this.dlvTpeOpt.KER :
            dlvTrTypeNum = 1
            // deliveryByStore = this.trsend || this.itmTran.trfrom
            deliveryByStore = this.deliveryByStore
            trackingno = `[{"carrier":"KER","trackingNo":"","deliveryDate":""}]`
            // สาขา 60963 KER => DHL
            if(this.trsend == '60963'){
              trackingno = `[{"carrier":"DHL","trackingNo":"","deliveryDate":""}]`
            }
            break
          case this.dlvTpeOpt.DIY :
            dlvTrTypeNum = 2
            deliveryByStore = this.deliveryByStore
            if(this.getBookingItms.date.split('-').length == 3){
              trackingno = `[{"carrier":"${this.dlvTrType}","trackingNo":"","deliveryDate":"${this.getBookingItms.date.split('-')[2]}/${this.getBookingItms.date.split('-')[1]}/${this.getBookingItms.date.split('-')[0]} ${this.getBookingItms.name}"}]`
            }
            break
          case this.dlvTpeOpt.CON :
            dlvTrTypeNum = 3
            deliveryByStore = this.deliveryByStore
            if(this.getBookingItms.date.split('-').length == 3){
              trackingno = `[{"carrier":"${this.dlvTrType}","trackingNo":"","deliveryDate":"${this.getBookingItms.date.split('-')[2]}/${this.getBookingItms.date.split('-')[1]}/${this.getBookingItms.date.split('-')[0]} ${this.getBookingItms.name}"}]`
            }
            break
          case this.dlvTpeOpt.DCS :
            dlvTrTypeNum = 6
            deliveryByStore = '60004'
            if(this.getBookingItms.date.split('-').length == 3){
              trackingno = `[{"carrier":"${this.dlvTrType}","trackingNo":"","deliveryDate":"${this.getBookingItms.date.split('-')[2]}/${this.getBookingItms.date.split('-')[1]}/${this.getBookingItms.date.split('-')[0]} ${this.getBookingItms.name}"}]`
            }
            break
          case this.dlvTpeOpt.VEN :
            dlvTrTypeNum = 4
            deliveryByStore = this.deliveryByStore
            if(this.dateVEN.split('-').length == 3){
              trackingno = `[{"carrier":"VEN","trackingNo":"","deliveryDate":"${this.dateVEN.split('-')[2]}/${this.dateVEN.split('-')[1]}/${this.dateVEN.split('-')[0]}"}]`
            }
            break
          case this.dlvTpeOpt.SER :
            dlvTrTypeNum = 5
            // this.itmTran.trfromthis.listBookingID[this.pickPackage].trsend
            deliveryByStore = this.deliveryByStore
            this.bookingId = this.listBookingID[this.pickPackage].bookingId
            if(this.listBookingID[this.pickPackage].bookingSlotDate.split('-').length == 3){
              trackingno = `[{"carrier":"SER","trackingNo":"","deliveryDate":"${this.listBookingID[this.pickPackage].bookingSlotDate.split('-')[2]}/${this.listBookingID[this.pickPackage].bookingSlotDate.split('-')[1]}/${this.listBookingID[this.pickPackage].bookingSlotDate.split('-')[0]} ${this.listBookingID[this.pickPackage].bookingSlotName}"}]`
            }
            break
          case this.dlvTpeOpt.CRI :
            dlvTrTypeNum = 2
            deliveryByStore = this.deliveryByStore
            if(this.getBookingItms.date.split('-').length == 3){
              trackingno = `[{"carrier":"${this.dlvTrType}","trackingNo":"","deliveryDate":"${this.getBookingItms.date.split('-')[2]}/${this.getBookingItms.date.split('-')[1]}/${this.getBookingItms.date.split('-')[0]} ${this.getBookingItms.name}"}]`
            }
            break
          case this.dlvTpeOpt.COM :
            if(this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.PICK || this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.PICKNOW){
              dlvTrTypeNum = 99
              dlvTrTypeSend = 'PICK'
              pickupnow = this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.PICKNOW
              deliveryByStore = this.listBookingID[this.pickPackage].deliveryByStore
              this.trsend = this.listBookingID[this.pickPackage].selectpickstc
              this.USESTOCK = 'S'
              pickupDate = this.listBookingID[this.pickPackage].bookingSlotDate.Date.split('-').reverse().join('/') + ' ' + this.listBookingID[this.pickPackage].bookingSlotDate.Time
              trackingno = `[{"carrier":"PICK","trackingNo":"","pickupDate":"${pickupDate}"}]`
            }else{
              dlvTrTypeSend = this.listBookingID[this.pickPackage].dlvTrType
              dlvTrTypeNum = this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.KER ? 1 :
                            this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.DIY ? 2 :
                            this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.CRI ? 2 :
                            this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.CON ? 3 :
                            this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.DCS ? 6 :
                            this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.VEN ? 4 :
                            this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.SER ? 5 : ''
              this.bookingId = this.listBookingID[this.pickPackage].bookingId
              deliveryByStore = this.listBookingID[this.pickPackage].deliveryByStore
              this.USESTOCK = this.listBookingID[this.pickPackage].USESTOCK
              if(this.listBookingID[this.pickPackage].bookingSlotDate.split('-').length == 3){
                trackingno = `[{"carrier":"${this.listBookingID[this.pickPackage].dlvTrType}","trackingNo":"","deliveryDate":"${this.listBookingID[this.pickPackage].bookingSlotDate.split('-')[2]}/${this.listBookingID[this.pickPackage].bookingSlotDate.split('-')[1]}/${this.listBookingID[this.pickPackage].bookingSlotDate.split('-')[0]} ${this.listBookingID[this.pickPackage].bookingSlotName}"}]`
              }else{
                trackingno = `[{"carrier":"${this.listBookingID[this.pickPackage].dlvTrType}","trackingNo":"","deliveryDate":""}]`
              }
              // สาขา 60963 KER => DHL
              if(this.listBookingID[this.pickPackage].dlvTrType == this.dlvTpeOpt.KER && this.listBookingID[this.pickPackage].trsend == '60963'){
                trackingno = `[{"carrier":"DHL","trackingNo":"","deliveryDate":""}]`
              }
            }
            break
          case this.dlvTpeOpt.PICK :
            dlvTrTypeNum = 99
            dlvTrTypeSend = "PICK"
            deliveryByStore = ''
            this.USESTOCK = 'S'
            this.usestockstore = this.trsend
            pickupDate = this.getPickUpDate.Date.split('-').reverse().join('/') + ' ' + this.getPickUpDate.Time
            trackingno = `[{"carrier":"PICK","trackingNo":"","pickupDate":"${pickupDate}"}]`
            break
          case this.dlvTpeOpt.PICKNOW :
            dlvTrTypeNum = 99
            dlvTrTypeSend = "PICK"
            deliveryByStore = ''
            pickupnow = true
            this.USESTOCK = 'S'
            this.usestockstore = this.trsend
            pickupDate = this.getPickUpDate.Date.split('-').reverse().join('/') + ' ' + this.getPickUpDate.Time
            trackingno = `[{"carrier":"PICK","trackingNo":"","pickupDate":"${pickupDate}"}]`
            break
          default : 
          
        }
        // if( this.dlvTrType == this.dlvTpeOpt.COM && (dlvTrTypeSend == this.dlvTpeOpt.COM || dlvTrTypeSend == this.dlvTpeOpt.SER)){
        //   throw new Error(`แพคเกจ ${this.slotNum+1} ไม่สามารถเลือกแพคเกจที่ต้องการได้`);
        // }
        let skcodelist = `[{"SEQ":"${this.itmTran.seq}","SKC":"${JSON.parse(this.itmTran.jsnskulist)[0].SKC}","SHIPTYP":"${dlvTrTypeNum}","SHIPNME":"${dlvTrTypeSend}","deliveryByStore":"${deliveryByStore}","deliveryStore":"${deliveryByStore}","pickupstore":"${this.trsend}","usestockstore":"${this.usestockstore}"${this.USESTOCK ? `,"USESTOCK":"${this.USESTOCK}"` : ''}}]`
        let data = {
          REF: this.getQTCUSTREF,
          bookingId: this.bookingId ? this.bookingId : '',
          trackingNo: trackingno,
          skcodelist: skcodelist,
          pickupnow: pickupnow
        }
        // console.log(data);
        let csconfirmBookingV2 = await serviceAPI.call_API('post', 'checkout/csconfirmBookingV2', data,'auth');
        if(csconfirmBookingV2.data.dbmessage == 'OK'){
          return true
        } else {
          return false
        }
        // var trackingno = '';
        // if(bookingSlotDate.split('-').length = 3){
        //     trackingno = `[{"carrier":"${dlvTrType?dlvTrType:getTranV2.trtype}","trackingNo":"","deliveryDate":"${bookingSlotDate.split('-')[2]}/${bookingSlotDate.split('-')[1]}/${bookingSlotDate.split('-')[0]}${bookingSlotName ? ' '+bookingSlotName : '' }"}]`
        // }
        // console.log(trackingno);
        // console.log(createbooking);
        // var confirmbooking = await this.confirmBookingV2(ref, createbooking.bookingId, trackingno, dlvTrType, skuservice ? skuservice.skuCode : listSku);
      } catch(error) {
        this.WaitBooking = false;
        return false
        throw new Error(error)
      }
    },
    async reloadOrderInfo() {
        this.$store.dispatch('service/setReloadOrderInfo')
    },
    async reloadFlee() {
        this.$store.dispatch('service/setReloadFlee')
    },
    async completeSlotTime(isComplate) {
      this.isLoading = !isComplate
    },
    async openModal() {
      try {
        this.$refs.DlvDetail.showmodal()
      } catch (error) {
        serviceMain.showErrorAlert(this, error.message)
      }
    },
    async hideModal() {
      try {
        this.$refs.DlvDetail.hidemodal()
      } catch (error) {
        serviceMain.showErrorAlert(this, error.message)
      }
    },

    showDivDetail(e,index){
      this.dlvDetail = e;
      this.dlvDetailIndex = index+1;
      this.openModal()
    },

    async bookingItems(val) {
      this.getBookingItms = val[0];
      // this.getBookingItms[val[1]] = val[0];
      // this.$refs.DetailCartRef.showListCart()
    },

    async pickupItems(val) {
      console.log('pickmain');
      this.getPickUpDate = val[0];
      // console.log(this.getPickUpDate);
      this.$emit("fnlistPickDate", [this.getPickUpDate,this.slotNum]);
      // this.getBookingItms[val[1]] = val[0];
      // this.$refs.DetailCartRef.showListCart()
    },

    async chgPickPackage() {
      try{
        // for (let i = 0; i < this.listPackageSERPic.length; i++) {
        //   if(this.listPackageSERPic[i]){
        //     if(this.listPackageSERPic[i].SLOTNUM == this.pickPackage){
        //       this.pickPackage = ''
        //     }
        //   }
        // }
        if(this.dlvTrType == this.dlvTpeOpt.SER){
          await this.$emit("fnlistPackageSERPic", [this.pickPackage,this.slotNum]);
        }
        else{
          await this.$emit("fnlistPackageDLVPic", [this.pickPackage,this.slotNum]);
        }
        this.chgSelTrType ()
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },

    async selectedStore(val){
      this.trsend = val
      this.selectpickstc = val
      let listdata = { dlvTrType : this.dlvTrType,
                        Pickupstore: this.trsend,
                        deliveryByStore: this.deliveryByStore,
                        USESTOCK: this.USESTOCK } 
      await this.$emit("fnliststcdeli", [listdata,this.slotNum]);
      // this.dlvTrType = '';
      // this.StoreData = await this.$refs.PickupStore.selectStoreData()
      // this.chgSelTrType();
    },
  },
  computed: {
    getListSKU: function () {
      let list = JSON.parse(this.itmTran.jsnskulist).map(rl => rl.SKC)
      return list
    },
    getListSKUName: function () {
      let list = JSON.parse(this.itmTran.jsnskulist).map(rl => rl.PRNAME)
      return list
    },
    getQTCUSTREF: function () {
      return this.$store.getters["customer/getQTCUSTREF"];
    },
    getQTCUSTINFO: function () {
      return this.$store.getters["customer/getQTCUSTINFO"];
    },
    getQTBOOKING: function () {
      return this.$store.getters["auth/getQTBOOKING"];
    },
    getBU: function() {
      return this.$store.getters['customer/getQTBU'];
    },
    getBuColor:function(){
      let color = '';
      switch(this.getBU) {
        case 'bnb':
          color = '#006e63';
        break;
        case 'at1':
          color = '#3866af';
        break;
        default:
          color = '#55246c';
      }
      return color;
    },
    getStore: function () {
      return this.$store.getters["service/getQTDFSTORE"];
    },
    QTDLVTRTYPE: {
      get () {
        return this.$store.getters['customer/getQTDLVTRTYPE'] || ''
      },
      set (value) {
        this.$store.dispatch('customer/setQTDLVTRTYPE',value)
      }
    },
    // getBookingItms: function () {
    //   return this.$store.getters["service/getBookingItms"];
    // },
    getQTCHKP: function () {
      return this.$store.getters["service/getQTCHKP"];
    },
    getQTSALESOURCE: function () {
      return this.$store.getters["auth/getQTSALESOURCE"];
    },
    DeliveryFee: {
      get () {
        return this.$store.getters['service/getDeliveryFee']
      }, 
      set (value) {
        this.$store.dispatch('service/setDeliveryFee', value)
      }
    },
    WaitBooking: {
      get () {
        return this.$store.getters['service/getWaitBooking']
      }, 
      set (value) {
        this.$store.dispatch('service/setWaitBooking', value)
      }
    },
    dlvType: function() {
      if(this.dlvTrType){
        return true
      }
      return false
    },
    priceDlv: {
      get () {
        return this.$store.getters['service/getQTPDLV']
      },
      set (value) {
        this.$store.dispatch('service/setQTPDLV',value)
      }
    },
    getQTSTORENAME:function(){
      return this.$store.getters['service/getQTSTORENAME']
    },
    PORQ: function() {
      if(this.itmTran.tsptype == 'PICK'){
        return ''
      }
      let ob = this.orderItems.find(element => element.SKUSEQ == this.itmTran.seq);
      return ob.PORQ
    },
    VENST: function() {
      if(this.itmTran.tsptype == 'PICK'){
        return ''
      }
      let ob = this.orderItems.find(element => element.SKUSEQ == this.itmTran.seq);
      return ob.VENST
    },
    SLPRSN: function() {
      if(this.itmTran.tsptype == 'PICK'){
        return ''
      }
      let ob = this.orderItems.find(element => element.SKUSEQ == this.itmTran.seq);
      return ob.SLPRSN
    },
    shipFlag: function () {
      let items = JSON.parse(this.itmTran.jsnskulist) || ''
      for (let i = 0; i < items.length; i++) {
        if(items[i].deliveryFlag[0] != '1'){
          return false
        }
      }
      return true
    },
    pickFlag: function () {
      let items = JSON.parse(this.itmTran.jsnskulist) || ''
      for (let i = 0; i < items.length; i++) {
        if(items[i].deliveryFlag[1] != '1'){
          return false
        }
      }
      return true
    },
    pickList: function () {
      let items = []
      for (let i = 0; i < this.listdlvTrTypePWB.length; i++) {
        if(this.listdlvTrTypePWB[i]){
        if(this.listdlvTrTypePWB[i].type != 'KER' && this.listdlvTrTypePWB[i].type != 'DIY' && this.listdlvTrTypePWB[i].type != 'COM' && this.listdlvTrTypePWB[i].type != 'SER'){
          items.push({SLOTNUM:i,skulist:this.listdlvTrTypePWB[i].skulist})
        }}
      }
      return items
    },
    shipList: function () {
      let items = []
      for (let i = 0; i < this.listdlvTrTypePWB.length; i++) {
        if(this.listdlvTrTypePWB[i]){
        if(this.listdlvTrTypePWB[i].type != 'PICK' && this.listdlvTrTypePWB[i].type != 'COM' && this.listdlvTrTypePWB[i].type != 'SER'){
          items.push({SLOTNUM:i,skulist:this.listdlvTrTypePWB[i].skulist})
        }}
      }
      return items
    },
    nonList: function () {
      let items = []
      for (let i = 0; i < this.listdlvTrTypePWB.length; i++) {
        if(this.listdlvTrTypePWB[i]){
        if(this.listdlvTrTypePWB[i].type != 'PICK' && this.listdlvTrTypePWB[i].type != 'KER' && this.listdlvTrTypePWB[i].type != 'DIY' && this.listdlvTrTypePWB[i].type != 'COM' && this.listdlvTrTypePWB[i].type != 'SER'){
          items.push({SLOTNUM:i,skulist:this.listdlvTrTypePWB[i].skulist})
        }}
      }
      return items
    },
    allList: function () {
      let items = []
      for (let i = 0; i < this.listdlvTrTypePWB.length; i++) {
        if(this.listdlvTrTypePWB[i]){
        if(this.listdlvTrTypePWB[i].type != 'COM' && this.listdlvTrTypePWB[i].type != 'SER'){
          items.push({SLOTNUM:i,skulist:this.listdlvTrTypePWB[i].skulist})
        }}
      }
      return items
    },
    SKCODE: function () {
      let list = this.itmTran ? JSON.parse(this.itmTran.jsnskulist)[0] : ''
      return {  SKC: list.SKC || '',
                QNT: list.QNT || ''}
    },
    
  },
  watch: {
    getStore: function () {
      this.QTDLVTRTYPE = '';
      this.clearSlotTime();
      // this.reloadSlotTimes();
    },
    getQTCUSTREF: function (){
      this.QTDLVTRTYPE = ''
    },
    DeliveryFee: function() {
      this.isShowDesc = false
      this.flee = this.DeliveryFee
      this.isShowDesc = this.flee > 0
    },
    orderItems: function (newValue, oldValue) {
      if(newValue != oldValue) {
        setTimeout(() => {
          this.checkIsXmas();
        }, 1000)
      }
    },
    dlvType: function() {
      this.$emit("dlvType", [this.dlvType,this.slotNum]);
    },
    getQTCHKP: function() {
      if(this.getQTCHKP == 'Pickup')
        this.$emit("dlvType", [false,this.slotNum]);
    },
    changePick: function() {
      // if(this.getDlvTrType == this.dlvTpeOpt.COM || this.getDlvTrType == this.dlvTpeOpt.SER){
      //   this.dlvTrType = this.dlvTpeOpt.COM;
      //   this.pickPackage = this.getPickPackage

      // } else if (this.getDlvTrType == this.dlvTpeOpt.DIY || this.getDlvTrType == this.dlvTpeOpt.CON ){
      //   this.dlvTrType = this.getDlvTrType;
      //   this.pickPackage = this.getPickPackage
      //   this.chgSelTrType();
      // }
      this.dlvTrType = this.getDlvTrType;
      if (!(this.getDlvTrType == this.dlvTpeOpt.DIY || this.getDlvTrType == this.dlvTpeOpt.CON || this.getDlvTrType == this.dlvTpeOpt.DCS || this.getDlvTrType == this.dlvTpeOpt.CRI)){
        this.pickPackage = this.getPickPackage
      }
      this.chgSelTrType();
    },
    changePickQTBOOKING: function() {
      this.dlvTrType = this.getDlvTrType;
      this.pickPackage = ''
      this.chgSelTrType();
    }
  }
}
</script>

<style scoped>
  .fnt-header {
    font-family: 'kittithadabold75regular';
  }

  .cardWhite{
    margin: 0px;
    background-color: white;
    padding:20px 20px;
    border-radius: 7px;
    height: auto;
    margin-bottom: 0px;
    min-height: auto;
  }
  
  .form-control {
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    border-bottom: .5px solid #ccc;
    box-shadow: none;
    border-radius: 0px;
    font-weight: bolder;
  }
</style>