
<template>
   
    <section class="p-0">
      <div class="row mb-2" style="border-radius: 7px; background-color: white; height: auto; padding: 20px 20px;">
        <div class="col-7">
          <input v-model="editPriceData" placeholder="กรอกค่าขนส่งที่ต้องการ" class="w-100" type="number" name="pizdeli" >
        </div>
        <div class="col-5">
          <button :class="`btn-visible-PWB w-100 bg-${qtBu}`"  @click="startEdit"> <strong style="font-family: 'kittithadamedium65regular';">แก้ไขค่าขนส่ง</strong></button>
        </div>
      </div>
    </section>

</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
export default {
  name: 'EditPriceDlv',
  data: function () {
    return {
        editPriceData:'',
      }
  },
  mounted() {
  },
  methods: {
    async startEdit(){
      try {
            if(this.editPriceData==''){
              throw new Error('กรุณากรอกค่าจัดส่ง')
            }
            if(this.editPriceData<0){
              throw new Error('ไม่สามารถกรอกค่าจัดส่งติดลบได้')
            }
            let data={
                REF: this.getRef, 
                TRAN_JSON:{
                  amount: (Math.round(this.editPriceData * 100) / 100).toFixed(2),
                }
            }
            console.log('DLV');
            this.priceDlv = (Math.round(this.editPriceData * 100) / 100).toFixed(2);
            let getAPI = await serviceAPI.call_API('post',`checkout/SaveTransport`,data,'auth');
            this.$store.dispatch('service/setReloadOrderInfo')
            serviceMain.showSuccessAlertWithTime(this,`บันทึกค่าขนส่ง ${this.editPriceData} บาทแล้ว`,2000)
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
   

  },
  computed: {
      getRef:function(){
        return this.$store.getters['customer/getQTCUSTREF']
      },
      qtBu: function (){
        return this.$store.getters['customer/getQTBU']
      },
      priceDlv: {
        get () {
          return this.$store.getters['service/getQTPDLV']
        },
        set (value) {
          this.$store.dispatch('service/setQTPDLV',value)
        }
      },
    },
    watch:{

    }

}

</script>

<style scoped>
  .switchter {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switchter input {
  opacity: 0;
  width: 0;
  height: 0;
}
.sliderter {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderter:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.sliderter {
  background-color: #55246c;
}

input:focus+.sliderter {
  box-shadow: 0 0 1px #55246c;
}

input:checked+.sliderter:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sliderter.roundter {
  border-radius: 34px;
}

.sliderter.roundter:before {
  border-radius: 50%;
}
.checkout-card {
  margin-top: 10px;
  border-radius: 7px;
  background-color: white;
  height: auto;
  padding: 10px 20px;
}
</style>



// ALC: 0
// ALOCATE: 0
// AMT: "96.00"
// ATBCODE: "  "
// AUTOPLPP: 0
// COST: "82.80"
// CTLID: 8894900
// CanEditUPC: "N"
// EDT: ""
// FREENOSTOCK: false
// OFflag: 0
// PCD: "0000041781616"
// PREORDER: 0
// PRN: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
// PRSTATUS: "A"
// QNT: 1
// QNTAVAIL: 79
// RECID: 4317
// REF: "QT2105000014"
// REM: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// SEQ: 1
// SERVICEFLAG: false
// SERVICEGROUP: " "
// SERVICENAME: ""
// SKCODE: 60180854
// STAT: "ยังไม่ยืนยัน\t"
// STATUS: " "
// STCODE: 920
// STOCKAVAIL: 79
// TOT: 96
// TSPGROUP: 2
// TTM: "2021-05-17T09:44:20.983Z"
// UPC: "96.00"
// WEIGHT: 1.25
// pckloc: 2
// remark: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// slugname: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
