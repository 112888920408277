
<template>
   
    <section class="p-0">
      <div class="row mt-2">
        <div class="col-12 checkout-card">
          <div class="row">
            <div class="col-12 col-xl-4 mb-2">
              <span style="font-family: 'kittithadabold75regular'; margin-right:15px;">โปรโมชัน 10101</span>
              <label class="switchter m-0">
                  <input @change="changePromootion('S')" v-model="getIs10101"  type="checkbox">
                  <span class="sliderter roundter"></span>
              </label>
            </div>
            <div class="col-12 col-xl-6 mb-2">
              <div class="row">
                <div class="col-12 col-md-4">
                  <span style="font-family: 'kittithadabold75regular'; margin-right:15px;">รหัสเมมเบอร์</span>
                </div>
                <div class="col-12 col-md-8">
                  <select id="txtcodedis" v-model="selectedData" for="seladd" class="form-control select-address" style="height:1.5rem;">
                    <option disabled value="">เลือกส่วนลด</option>
                    <option v-for="(e,index) in listData" :key="index" :value="e">{{e.MemberCode.trim()}} - {{e.MemberName.trim()}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-2">
              <button :class="`btn-visible-PWB w-100 bg-${qtBu}`"  @click="changePromootion('S')"> <strong style="font-family: 'kittithadamedium65regular';">ยืนยัน</strong></button>
            </div>
          </div>
        </div>
      </div>
    </section>

</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
export default {
  name: 'Promotion101',
  data: function () {
    return {
        listData:[],
        selectedData:''
      }
  },
  created() {
      this.$store.dispatch('service/setIs10101',false)
  },
  mounted() {
    this.start();
  },
  methods: {
    async start(){
      try {
       
        let data={}
        let getAPI = await serviceAPI.call_API('post',`checkout/getMEMBERTYPE`,data,'auth');
        this.listData = getAPI.data.dbitems;
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async changePromootion(M){
      try {
        if((M == 'R' && this.selectedData.MemberCode != 0) || this.getIs10101 || M == 'S'){
            if(this.getIs10101 || this.selectedData.MemberCode || this.DisMemberCode){
                let data={
                    REF: this.getRef, 
                    memberCode: this.selectedData.MemberCode ? this.selectedData.MemberCode : '0',
                    STORE101: this.getIs10101 ? '101' : ''
                }
                let getAPI = await serviceAPI.call_API('post',`checkout/getplppbysalesource`,data,'auth');
                console.log('101MEM');
                // this.$store.dispatch('service/setReloadPlpp')
                // if(this.getDisMKT){
                //   this.$store.dispatch('service/setReloadMKT')
                // }
                if(this.selectedData){
                  this.DisMemberCode = this.selectedData.MemberCode
                  this.DisMemberName = this.selectedData.MemberName
                }
                if(M != 'R')
                this.$store.dispatch('service/setReloadOrderInfoF101')
                if((this.getIs10101 || this.selectedData.MemberCode) && M == 'S'){
                  let pMessage = this.selectedData.MemberCode ?  `เลือก โปรโมชันรหัสเมมเบอร์ ${this.selectedData.MemberCode} แล้ว` : 'เลือก โปรโมชัน 10101 แล้ว'
                  serviceMain.showSuccessAlertWithTime(this,pMessage,1200)
                }
                // if(!this.getIs10101 && this.selectedData.MemberCode == 0){
                //   this.$store.dispatch('service/setReloadOrderInfo')
                // }
                
            }else{
                this.$store.dispatch('service/setReloadOrderInfo')
            }
            
        }
        // let data={
        //   REF: this.getRef, 
        // }
        // let getAPI = await serviceAPI.call_API('post',`checkout/getplppbysalesource`,data,'auth');
        // console.log(getAPI)
        // this.isHavePlpp = getAPI.data.dbrows == 0 ? false:true;
        // this.listData = getAPI.data.dbitems;
        // this.sumDis()
        
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
  },
  computed: {
      getRef:function(){
        return this.$store.getters['customer/getQTCUSTREF']
      },
      getStore:function(){
        return this.$store.getters['service/getQTDFSTORE']
      },
      getReloadPlpp:function(){
        return this.$store.getters['service/getReloadPlpp']
      },
      getReloadIs10101:function(){
        return this.$store.getters['service/getReloadIs10101']
      },
      getIs10101: {
            get () {
                return this.$store.getters['service/getIs10101']
            },
            set (value) {
                this.$store.dispatch('service/setIs10101',value)
            }
      },
      getDisMKT:function(){
        return this.$store.getters['service/getDisMKT']
      },
      DisMemberCode: {
            get () {
                return this.$store.getters['service/getDisMemberCode']
            },
            set (value) {
                this.$store.dispatch('service/setDisMemberCode',value)
            }
      },
      DisMemberName: {
            get () {
                return this.$store.getters['service/getDisMemberName']
            },
            set (value) {
                this.$store.dispatch('service/setDisMemberName',value)
            }
      },
      getReloadMem:function(){
        return this.$store.getters['service/getReloadMem']
      },
      qtBu: function (){
        return this.$store.getters['customer/getQTBU']
      }
      
    },
    watch:{
        getReloadIs10101:function(){
            this.changePromootion('R')
        },
        getReloadMem:function(){
            this.changePromootion()
        },
    }

}

</script>

<style scoped>
  .switchter {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switchter input {
  opacity: 0;
  width: 0;
  height: 0;
}
.sliderter {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderter:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.sliderter {
  background-color: #55246c;
}

input:focus+.sliderter {
  box-shadow: 0 0 1px #55246c;
}

input:checked+.sliderter:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sliderter.roundter {
  border-radius: 34px;
}

.sliderter.roundter:before {
  border-radius: 50%;
}
.checkout-card {
  margin-top: 10px;
  border-radius: 7px;
  background-color: white;
  height: auto;
  padding: 10px 20px;
}
.select-address{
  padding:0px 15px; 
  font-family: 'kittithadabold75regular'; 
  color:black; 
  background-color: rgb(241, 241, 241);
  border-radius: 25px; 
}
</style>



// ALC: 0
// ALOCATE: 0
// AMT: "96.00"
// ATBCODE: "  "
// AUTOPLPP: 0
// COST: "82.80"
// CTLID: 8894900
// CanEditUPC: "N"
// EDT: ""
// FREENOSTOCK: false
// OFflag: 0
// PCD: "0000041781616"
// PREORDER: 0
// PRN: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
// PRSTATUS: "A"
// QNT: 1
// QNTAVAIL: 79
// RECID: 4317
// REF: "QT2105000014"
// REM: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// SEQ: 1
// SERVICEFLAG: false
// SERVICEGROUP: " "
// SERVICENAME: ""
// SKCODE: 60180854
// STAT: "ยังไม่ยืนยัน\t"
// STATUS: " "
// STCODE: 920
// STOCKAVAIL: 79
// TOT: 96
// TSPGROUP: 2
// TTM: "2021-05-17T09:44:20.983Z"
// UPC: "96.00"
// WEIGHT: 1.25
// pckloc: 2
// remark: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// slugname: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
