
<template>
   
    <div class="row mt-3" style="    border-radius: 7px; background-color: white;padding: 10px 15px;">
      <div class="col-12">
        <p style="font-family: 'kittithadabold75regular'; font-size:1.3em; ">ส่วนลด MKT</p>
 
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-9">
            <select id="txtcodedis" v-model="selectedData" @change="selectOnChange" for="seladd" class="form-control select-address" style="height:1.5rem;">
              <option disabled value="">เลือกส่วนลด</option>
              <option v-for="(e,index) in listData" :key="index" :value="e">{{e.DISCCODE.trim()}} {{e.DISCNAME.trim()}} = {{e.DISCRATE.trim()}} {{e.DISCTYPE.trim()}}</option>
            </select>
          </div>
          <div class="col-3">
            <button :class="`btn-visible-PWB w-100 bg-${qtBu} mb-1`"  @click="selectDis('S')"> <strong style="font-family: 'kittithadamedium65regular';">เลือก</strong></button>
            <button :class="`btn-visible-PWB w-100 bg-${qtBu}`"  @click="cancledis()"> <strong style="font-family: 'kittithadamedium65regular';">ยกเลิก</strong></button>
          </div>
        </div>
      </div>
      
      <div class="col-12 mt-3">
        <div class="row" style="padding:0px 20px;">
          <div class="col-12 col-md-6" style="margin-bottom: 10px;">
            <span style="font-family: 'kittithadabold75regular'; ">รหัสส่วนลด</span>
            <span style=" padding-left:20px;">{{selectedData.DISCCODE}}</span>
          </div>
          <div class="col-12 col-md-6" style="margin-bottom: 10px;">
            <span style="font-family: 'kittithadabold75regular'; ">ชื่อ</span>
            <span style=" padding-left:20px;">{{selectedData.DISCNAME}}</span>
          </div>
          <div class="col-12 col-md-6" style="margin-bottom: 10px;">
            <span style="font-family: 'kittithadabold75regular'; ">ส่วนลด</span>
             <input v-if="selectedData.DISCRATE ==0" v-model="disRateData" class="inputdis" type="number" style=" padding-left:20px;" name="DisInput">
            <span v-else style=" padding-left:20px;">{{disRateData}} {{selectedData.DISCTYPE}}</span>
            <!-- <input class="inputdis" type="number" onfocus="this.select();" disabled="" id="DisInput" style=" padding-left:20px;" name="DisInput"> -->
            <!-- <input type="text" disabled="" id="DisId" style=" padding-left:20px;" name="DisId" hidden=""> -->
          </div>
          <div class="col-12 col-md-6" style="margin-bottom: 10px;">
            <span style="font-family: 'kittithadabold75regular'; ">หน่วย</span>
            <span style=" padding-left:20px;">{{selectedData.DISCTYPE}}</span>
          </div>
        </div>
      </div>
    </div>

</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
export default {
  name: 'DisMktCode',
  data: function () {
    return {
        listData:[],
        selectedData:'',
        disRateData:''
      }
  },
  mounted() {
    this.start();
  },
  methods: {
    async start(){
      try {
       
        let data={
          STCODE:this.getStore
        }
        let getAPI = await serviceAPI.call_API('post',`checkout/ListMKTDiscount`,data,'auth');
        this.listData = getAPI.data.dbitems;
        // $("#txtcodedis").select2({
        //   placeholder: 'เลือกส่วนลด'
        // });
        // DISCCODE: "AIR1"
        // DISCNAME: "ส่วนลด 1,800 B                "
        // DISCRATE: "0  "
        // DISCTYPE: "บาท"
        // DISID: 206
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async selectDis(M){
      try {
        if(!this.disRateData.toString()){
          throw new Error('กรุณากรอกส่วนลด')
        }
        let data= {
          REF:this.getRef ,
          STCODE:this.getStore ,
          DIS_ID:this.selectedData.DISID ,
          DIS_CODE:this.selectedData.DISCCODE , 
          DIS_RATE:this.disRateData
        }
        let getAPI = await serviceAPI.call_API('post',`checkout/calMKTDiscount`,data,'auth');
        let getData = getAPI.data.dbitems[0];
        console.log('MKT');
        this.$store.dispatch('service/setDisMKT',getData.MKTDisc)
        this.$store.dispatch('service/setNameMKT',this.selectedData.DISCCODE)
        if(M == 'S'){
          serviceMain.showSuccessAlertWithTime(this,`เพิ่ม ${getData.MKTDisc} สำเร็จ`,1000)
        }
        // this.listData = getAPI.data.dbitems;
        // DISCCODE: "AIR1"
        // DISCNAME: "ส่วนลด 1,800 B "
        // DISCRATE: "0  "
        // DISCTYPE: "บาท"
        // DISID: 206
      } catch (error) {
        this.$store.dispatch('service/setDisMKT',0)
        this.$store.dispatch('service/setNameMKT','')
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async selectOnChange(){
      this.disRateData = parseFloat(this.selectedData.DISCRATE);
    },
    async cancledis(){
      location.reload(true);
    }
   

  },
  computed: {
      getRef:function(){
        return this.$store.getters['customer/getQTCUSTREF']
      },
      getStore:function(){
        return this.$store.getters['service/getQTDFSTORE']
      },
      getReloadMKT:function(){
        return this.$store.getters['service/getReloadMKT']
      },
      qtBu: function (){
        return this.$store.getters['customer/getQTBU']
      }
    },
    watch:{
      getReloadMKT:function(){
        setTimeout(() => {
          this.selectDis()
        }, 500) 
      }
    }

}

</script>

<style scoped>
.select-address{
  padding:0px 15px; 
  font-family: 'kittithadabold75regular'; 
  color:black; 
  background-color: rgb(241, 241, 241);
  border-radius: 25px; 
}
</style>
